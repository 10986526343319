import "./home.css";
import Banner from "./Banner";
import GameBlock from "./GameBlock";
import PromotionBlock from "./PromotionBlock";
import Jackpot from "./Jackpot";
import LatestWins from "./LatestWins";
import ProvidersBlock from "./ProvidersBlock";
import BuyCrypto from "./BuyCrypto";
import ContentsExpandable from "./ContentsExpandable";
import LiveCasinoBlock from "./LiveCasinoBlock";
import ProvablyFairBlock from "./ProvablyFairBlock";
import TryGames from "./TryGames";
import WorthTryingBlock from "./WorthTryingBlock";
import BestSlotsBlock from "./BestSlotsBlock";

const Home = (props) => {
  return (
    <main style={{ paddingTop: "86px" }} className="svelte-fv531g">
      <Banner images={props.images} />
      <GameBlock images={props.images} />
      <PromotionBlock images={props.images} />

      {/* Best Slots Block */}
      <BestSlotsBlock images={props.images} />

      {/* Live Casino Block */}
      <LiveCasinoBlock images={props.images} />

      {/* Provably Fair Games */}
      <ProvablyFairBlock images={props.images} />

      {/* Try Games */}
      <TryGames images={props.images} />

      {/* Buy Crypto */}
      <BuyCrypto images={props.images} />

      {/* Casino Games Provider */}
      <ProvidersBlock images={props.images} />

      {/* Worth Trying Block */}
      <WorthTryingBlock images={props.images} />

      {/* Jackpot */}
      <Jackpot images={props.images} />

      {/* Latest Wins */}
      <LatestWins images={props.images} />

      {/* Read More About */}
      <ContentsExpandable images={props.images} />
    </main>
  );
};
export default Home;
