const TryGames = (props) => {
  return (
    <div className="wrap container ms-auto me-auto svelte-1v7lbx9">
      <div
        className="banner svelte-bi7b7n"
        style={{
          background:
            "linear-gradient(90deg, #6F2BFF 0%, rgba(22, 22, 23, 0.00) 70%)",
        }}
      >
        <div className="left-side d-flex gap-16 svelte-bi7b7n">
          <div
            className="wrapper svelte-l3byt6"
            style={{ width: "80px", height: "66px" }}
          >
            <img
              src={props.images['16f585cc18e94863af48037358ad6141.webp']}
              alt="deposit to receive reward"
              className="image svelte-l3byt6"
              style={{}}
              title="deposit to receive reward"
              loading="lazy"
            />
          </div>
          <div className="middle svelte-bi7b7n">
            <div className="title svelte-bi7b7n">Try Stellar - Crash Game</div>
            <div className="description svelte-bi7b7n">
              Experience the Real Space
            </div>
          </div>
        </div>
        <div>
          <button
            className="primary standart small svelte-1tio7sv"
            type="button"
            style={{}}
          >
            {" "}
            Play Now{" "}
          </button>
        </div>
      </div>
      <div
        className="banner svelte-bi7b7n"
        style={{
          background:
            "linear-gradient(90deg, #1B5BFF 0%, rgba(22, 22, 23, 0.00) 70%)",
        }}
      >
        <div className="left-side d-flex gap-16 svelte-bi7b7n">
          <div
            className="wrapper svelte-l3byt6"
            style={{ width: "80px", height: "66px" }}
          >
            <img
              src={props.images['d3cfc48eef824e7da71cf592f6aa94ee.webp']}
              alt="deposit to receive reward"
              className="image svelte-l3byt6"
              style={{}}
              title="deposit to receive reward"
              loading="lazy"
            />
          </div>
          <div className="middle svelte-bi7b7n">
            <div className="title svelte-bi7b7n">Take a Chance on Dice</div>
            <div className="description svelte-bi7b7n">
              Make your Winning Roll
            </div>
          </div>
        </div>
        <div>
          <button
            className="primary standart small svelte-1tio7sv"
            type="button"
            style={{}}
          >
            {" "}
            Play Now{" "}
          </button>
        </div>
      </div>
    </div>
  );
};
export default TryGames;
