const TermsAndConditions = (props) => {
  return (
    <main className="svelte-ulvgrp" style={{ paddingTop: "86px" }}>
      <div className="container mx-auto">
        <div className="custom-faq-heading svelte-rkbwo5">
          <div className="faq-list svelte-rkbwo5">
            <ul className="svelte-rkbwo5">
              <li className=" svelte-rkbwo5">Terms and Conditions</li>
              <div className="border svelte-rkbwo5"></div>
              <li className="active svelte-rkbwo5">Privacy Policy</li>
              <div className="border svelte-rkbwo5"></div>
              <li className=" svelte-rkbwo5">Sports T&amp;C</li>
              <div className="border svelte-rkbwo5"></div>
              <li className=" svelte-rkbwo5">Prohibited Territories</li>
              <div className="border svelte-rkbwo5"></div>
              <li className=" svelte-rkbwo5">Bonus T&amp;C</li>
              <div className="border svelte-rkbwo5"></div>
              <li className=" svelte-rkbwo5">Games excluded from Bonus</li>
              <div className="border svelte-rkbwo5"></div>
              <li className=" svelte-rkbwo5">FAQ</li>
              <div className="border svelte-rkbwo5"></div>
              <li className=" svelte-rkbwo5">Affiliates</li>
              <div className="border svelte-rkbwo5"></div>
              <li className=" svelte-rkbwo5">About Us</li>
              <div className="border svelte-rkbwo5"></div>
            </ul>
          </div>
          <div style={{ position: "relative", flex: "1 0 0px" }}>
            <div className="wrap svelte-rkbwo5">
              <div className="gradient svelte-rkbwo5"></div>
              <ul className="faq-content svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                    Download{" "}
                  </button>
                </div>
                <li className="svelte-rkbwo5">
                  <h2>Privacy Policy</h2>
                  <p>
                    <i>
                      This Privacy Policy is last updated on February 25, 2023
                    </i>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>
                      This Privacy Policy describes the way in which UP-10X
                      deals with the information and data you provide to us to
                      enable us to manage your relationship with UP-10X.
                    </strong>
                  </p>
                  <p>
                    We will process any personal information provided to us or
                    otherwise held by us relating to you in the manner set out
                    in this Privacy Policy. Information may be provided via the
                    UP-10X Website (the "Website"), telephone calls or any other
                    means.
                  </p>
                  <p>
                    By accepting this Privacy Policy you agree that you
                    understand and accept the use of your personal information
                    as set out in this policy.&nbsp;
                  </p>
                  <p>
                    <strong>
                      If you do not agree with the terms of this Privacy Policy
                      please do not use the Website or otherwise provide us with
                      your personal information.
                    </strong>
                  </p>
                  <p>&nbsp;</p>
                  <h4>WHO WE ARE</h4>
                  <p>
                    References in this Privacy Policy to “UP-10X” (hereinafter
                    referred as “We”, “us” or “our”) shall mean UP-10X.com (the
                    “Website” or “Site”) operated by Nexus Group Enterprises
                    N.V., a limited liability company registered and established
                    under the laws of Curaçao with registration number 139781.
                    Nexus Group Enterprises N.V. is licensed by AntillePhone
                    N.V. We offer and provide Internet-based and/or web-based
                    mobile version interactive gaming services (hereinafter
                    referred to as the “Gambling Service” or “Services”.) We
                    control the ways your Personal Data is collected and the
                    purposes for which your Personal Data is used by UP-10X,
                    acting as the “data controller” for the purposes of
                    applicable data protection legislation.
                  </p>
                  <p>&nbsp;</p>
                  <h4>OUR PRIVACY PRINCIPLES</h4>
                  <p>
                    We protect, according to strict standards of security and
                    confidentiality, all non-public personal information we
                    collect about our customers;
                  </p>
                  <p>
                    We maintain physical, electronic and organizational
                    safeguards to protect this information;
                  </p>
                  <p>
                    We require any party providing products to us or our
                    customers on our behalf to protect the confidentiality of
                    this information;
                  </p>
                  <p>
                    We permit only authorized employees and contractors,
                    including but not limited to Support and Fraud team to have
                    access to non-public information of yours;
                  </p>
                  <p>
                    We afford former customers the same protections as existing
                    customers with respect to the use and sharing of their
                    information;
                  </p>
                  <h4>&nbsp;</h4>
                  <h4>PROTECTING YOUR PERSONAL DATA</h4>
                  <p>
                    Your Personal Data isn’t just protected by the quality,
                    commitment and high standards of UP-10X, it’s also protected
                    by law. We can only process your Personal Data when there is
                    a genuine reason to do so and it must meet one of the
                    following:
                  </p>
                  <p>• &nbsp;Contractual relationship;</p>
                  <p>• &nbsp;Legal obligation;</p>
                  <p>• &nbsp;Your consent to process;</p>
                  <p>• &nbsp;Our legitimate interest;</p>
                  <p>• &nbsp;Public interest;</p>
                  <p>• &nbsp;Your vital interests;</p>
                  <p>&nbsp;</p>
                  <h4>OUR INTERESTS</h4>
                  <p>
                    When We have a business or commercial reason to process your
                    Personal Data this is referred to as a legitimate interest.
                    Your Personal Data is still protected and We must not
                    process it in a way that would be unfair to you or your
                    interests.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    If We do use legitimate interests as a reason to process
                    your Personal Data We will tell you that We are doing so,
                    what our legitimate interests are and provide you with a
                    method to raise any questions or objections you may have.
                    However, compelling grounds for processing such information
                    may over-ride your right to object.
                  </p>
                  <p>&nbsp;</p>
                  <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                  <p>
                    Considering legal obligations undertook to fulfill the
                    contract signed between us and the customers, including and
                    not limited to the legal obligations undertook to comply
                    with laws or regulations that apply to us, and for to be
                    efficient about how We fulfil our legal and contractual
                    duties, We process your Personal Data in order to:
                  </p>
                  <p>• &nbsp;Manage customer relationship;</p>
                  <p>
                    • &nbsp;Develop and manage our brands, new and existing
                    products and Services for our customers, grow our business;
                  </p>
                  <p>• &nbsp;Create, develop, send and review marketing;</p>
                  <p>
                    • &nbsp;Learn from our customers’ behavior in relation to
                    our product and Services;
                  </p>
                  <p>
                    • &nbsp;To provide support for our products and Services;
                  </p>
                  <p>• &nbsp;To test new products, systems or Services;</p>
                  <p>
                    • &nbsp;To compare and manage our relationship with and the
                    performance of other companies that provide Services to us
                    and our customers;
                  </p>
                  <p>• &nbsp;Deliver quality products and Services to you;</p>
                  <p>
                    • &nbsp;Manage customer accounts, winnings, losses, payments
                    and withdrawals;
                  </p>
                  <p>
                    • &nbsp;Investigate, resolve and respond to any customer
                    complaints;
                  </p>
                  <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                  <p>• &nbsp;Prevent crime happening;</p>
                  <p>• &nbsp;Detect, investigate and report crime;</p>
                  <p>• &nbsp;Managing risk for us and our customers;</p>
                  <p>
                    • &nbsp;Complying with any laws and regulations that apply
                    to us;
                  </p>
                  <p>
                    • &nbsp;Ensure corporate governance and compliance to all
                    legal and regulatory obligations;
                  </p>
                  <p>
                    • &nbsp;Fulfil our obligations as an accountable and
                    responsible organization;
                  </p>
                  <p>
                    • &nbsp;To exercise our rights set out in agreements or
                    contracts;
                  </p>
                  <p>&nbsp;</p>
                  <h4>INTERNAL REPORTING</h4>
                  <p>
                    If you choose not to provide your Personal Data it may
                    prevent us from meeting legal obligations, fulfilling a
                    contract, or performing Services required to run your
                    account. Not providing your Personal Data may mean We are
                    unable to provide you with products or Services.
                  </p>
                  <p>&nbsp;</p>
                  <h4>INFORMATION COLLECTED</h4>
                  <p>
                    The information and data about you which We may (Please,
                    note that the scope of the information provided below is not
                    mandatorily collected/stored/used/processed by us, UP-10X
                    reserves a right to collect/store/use/process data in
                    certain cases when necessary for the various purposes stated
                    in this Privacy Policy) collect, store, use and process may
                    include but not exceed the following:
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>1. Personal Information:</strong>
                  </p>
                  <p>
                    1.1.&nbsp;&nbsp;Account details: when you open an account
                    and register to use the Services, you may be asked to
                    provide us certain details about yourself, such as: first
                    and last name, email address, gender, birth data, physical
                    address, ID number, phone number, occupation, etc. In
                    addition, We may collect certain information as required
                    under various KYC and Anti-Money Laundering (AML) regulatory
                    requirements, such as your source of funds, identity,
                    connection to criminal authorities, utility bill copies and
                    etc.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in
                    certain instances, you will be required to provide a copy of
                    a governmental-issued ID or Passport, for identity
                    verification process. Please, note that this information is
                    required for certain features of our Services.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                    Services, We may automatically record different information
                    related to your account and use of our Services, including,
                    with respect to your transactions, wallets, deposits,
                    balances, bets, winnings, bonuses and withdraws.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    1.4.&nbsp;&nbsp;Voluntary information: We also collect
                    information which you provide us voluntarily. For example,
                    when you respond to communications from us, communicate with
                    us via email or share additional information about yourself
                    through your use of the Services, such as on the live chat
                    with the support team or the game chat (e.g. Dice game).
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>2. Non-personal Information:</strong>
                  </p>
                  <p>
                    2.1.&nbsp;&nbsp;Log-in history and technical information: In
                    order to enhance the functionality of the Services and to
                    provide you with a better user experience, We collect
                    technical information transmitted by your device, including
                    certain software and hardware information (e.g. the type of
                    browser and operating system your device uses, language
                    preference, access time and the domain name of the Website
                    from which you linked to the Services; etc.).
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                    information including, which, amongst other things, may
                    include information regarding your deposits, crypto-wallet
                    balances, bets, bonuses, game session duration and your high
                    scores. We may also share and publish such information
                    through any of our Platforms using your nickname or initials
                    in cases if the user wins a sum worthy of publicity.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    2.3.&nbsp;&nbsp;Device and connection information: If you
                    download the Software to your device, We may collect
                    information from the particular device you are using, for
                    security and fraud detection and prevention purposes. For
                    example, We may gather information with regard to other
                    software which is running simultaneously with the Software
                    for detecting if you are using software which is associated
                    with fraudulent activity (e.g. robots, malware, AI, etc.) or
                    checking if the connection you are using is via a VPN or
                    proxy.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    2.4.&nbsp;&nbsp;Analytic information: We may collect
                    information about your use of the Services, such as
                    applications' usage, log files, user activity (e.g. pages
                    viewed, the amount of time spent on particular pages, online
                    browsing, clicks, actions, etc.), time stamps, alerts, etc.
                    This information is collected for amongst other things
                    troubleshooting errors and bugs as well as for research and
                    analytics purposes about your use of the Services.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                    de-identify the information collected by the Services or via
                    other means so that the information cannot, on its own,
                    personally identify you. Our use and disclosure of such
                    aggregated or de-identified information is not subject to
                    any restrictions under this Privacy Policy, and We may
                    disclose it to others without limitation and for any
                    purpose, such as for advertising or marketing purposes.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    Where it is reasonable for us to do so and not detrimental
                    to your rights and freedoms, We may also collect Personal
                    Data from publicly available sources such as internet
                    searches, social media or broadcast media.
                  </p>
                  <p>&nbsp;</p>
                  <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                  <p>
                    Whenever your data is kept by UP-10X We will ensure that it
                    is appropriately protected and only for acceptable purposes
                  </p>
                  <p>
                    We will keep your data for the period that you are a
                    customer of UP-10X. If you are no longer a customer of
                    UP-10X, We will keep your data for 10 years as required to
                    comply with the purposes set out in this policy. Your
                    personal records will be kept even if the customer account
                    is blocked/deactivated/deleted/otherwise no longer active or
                    cannot be deleted for technical reasons.
                  </p>
                  <p>&nbsp;</p>
                  <h4>DATA SHARED WITH THIRD PARTIES</h4>
                  <p>
                    When necessary for the following purposes, We may share your
                    Personal Data within the UP-10X group and with other
                    organizations stated below:
                  </p>
                  <p>
                    • &nbsp;Law enforcement agencies, regulators and other
                    authorities;
                  </p>
                  <p>• &nbsp;Credit reference agencies;</p>
                  <p>• &nbsp;Fraud prevention agencies;</p>
                  <p>• &nbsp;Identity verification agencies;</p>
                  <p>• &nbsp;Organizations that introduce you to us;</p>
                  <p>
                    • &nbsp;Third parties you ask us (or permit us) to share
                    your data with;
                  </p>
                  <p>
                    • &nbsp;Third parties necessary to provide products or
                    Services which you have requested;
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    The Site may use your Personal Information, including, but
                    not limited to your name, email address or telephone number,
                    ourselves or by using our third-party
                    subcontractors/affiliates/game providers/joint marketing
                    partners/business partners for the purpose of providing you
                    with promotional materials, concerning the Services as well
                    as products, services, websites and applications which
                    relate to you or interest you.&nbsp;&nbsp;
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    We may share and disclose said Personal Information with our
                    Marketing Affiliates for the purpose of providing you
                    different marketing offers, which We, or our Marketing
                    Affiliates, believe are relevant for you. Our Marketing
                    Affiliates may use this Personal Information for different
                    marketing techniques, such as direct email, post, SMS and
                    telephone marketing purposes.
                  </p>
                  <p>
                    We may share any Personal information We collect from and
                    about you with non-affiliated third parties. Information may
                    be shared with or collected by third party service providers
                    who provide us with Services such as information processing
                    (e.g. under AML or KYC policy adopted by our Website and
                    etc.). We require these providers to exercise reasonable
                    care to protect your Personal Information and restrict the
                    use of the data provided to the purposes for which it was
                    provided to them.
                  </p>
                  <p>
                    Your personal information may be transferred between the
                    countries and disclosed to other companies within associated
                    or subsidiary companies and to business partners, or
                    successors in title to our business.
                  </p>
                  <p>&nbsp;</p>
                  <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                  <p>
                    We take great care in implementing and maintaining the
                    security of the Services and your information. We have put
                    in place appropriate physical and technological safeguards
                    to help prevent unauthorized access, to maintain data
                    security, and to use correctly the information We collect
                    online. These safeguards vary based on the sensitivity of
                    the information that We collect and store.
                  </p>
                  <p>&nbsp;</p>
                  <h4>
                    <strong>
                      We employ industry standard procedures and controls to
                      ensure the safety of our users’ information, such as:
                    </strong>
                  </h4>
                  <p>
                    • &nbsp;Secure network topology, which includes intrusion
                    prevention and Firewall systems;
                  </p>
                  <p>• &nbsp;Encrypted communication;</p>
                  <p>• &nbsp;Authentication and Access Control;</p>
                  <p>• &nbsp;External and Internal audit tests, etc.</p>
                  <p>&nbsp;</p>
                  <h4>ONLINE SECURITY</h4>
                  <p>
                    Although We take reasonable steps to safeguard information,
                    however, due to the design of the Internet, We cannot be
                    responsible for the acts of those who gain unauthorized
                    access, abuse the Services or hacking and We make no
                    warranty, express, implied or otherwise, that We will
                    prevent such access.
                  </p>
                  <p>
                    The Site may contain hyperlinks to other websites that may
                    have information policies and practices that are different
                    from ours. The fact that We have provided a link to a site
                    is not an endorsement, authorization, sponsorship, or
                    affiliation by us with respect to such site, its owners, or
                    its providers. Links to other websites are provided only as
                    a convenience to you. We are not responsible for the
                    content, validity, privacy policies or reliability of any
                    website linked to this Site that is not within our family of
                    properties. There are risks in using any information,
                    products, software or Services found on the Internet, and
                    you should make sure that you completely understand these
                    risks before using, relying upon, or purchasing anything via
                    the Internet.
                  </p>
                  <p>&nbsp;</p>
                  <h4>TELEPHONE/VIDEO CALLS</h4>
                  <p>
                    Telephone or video calls to and from our Support office are
                    recorded for KYC, training and security purposes along with
                    the resolution of any queries arising from the Service you
                    receive.
                  </p>
                  <p>&nbsp;</p>
                  <h4>USE OF COOKIES</h4>
                  <p>
                    The Website uses cookies to deliver a better and more
                    personalized Service to users.
                  </p>
                  <p>
                    Cookies are small text files containing minimum amounts of
                    information which are sent to and stored on your computer,
                    smartphone or other device when you access a Website.
                    Cookies are then sent back to the originating Website on
                    each subsequent visit or to another website that recognizes
                    that cookie.
                  </p>
                  <p>
                    Cookies are generally used to improve and enhance the user
                    experience. Some of the cookies We use are essential for the
                    Website to operate.
                  </p>
                  <p>
                    UP-10X’s use of cookies on the Website falls into the
                    following categories:
                  </p>
                  <p>• &nbsp;Session Management;</p>
                  <p>• &nbsp;Functionality;</p>
                  <p>• &nbsp;Fraud Prevention;</p>
                  <p>• &nbsp;Tracking;</p>
                  <p>• &nbsp;Analysis;</p>
                  <p>
                    Third party advertising companies/affiliates, that work with
                    us, may also use cookies and related technologies to collect
                    and store Information to provide you with advertisements
                    that are likely to interest you.
                  </p>
                  <p>
                    You can find more information about cookies at&nbsp;
                    <a href="http://www.allaboutcookies.org/">
                      www.allaboutcookies.org
                    </a>
                    .
                  </p>
                  <p>&nbsp;</p>
                  <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                  <p>
                    We will assist you if you choose to exercise any of your
                    rights over your Personal Data, including:
                  </p>
                  <p>
                    • &nbsp;Withdrawing your previously granted consent
                    (however, this will not invalidate any information processed
                    under your previous consent);
                  </p>
                  <p>
                    • &nbsp;Lodging a complaint with any relevant Data
                    Protection Authority;
                  </p>
                  <p>
                    • &nbsp;Access to your Personal Data that We hold or
                    process;
                  </p>
                  <p>
                    • &nbsp;Correction of any Personal Data that is incorrect or
                    out of date;
                  </p>
                  <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                  <p>
                    Please be noted, that upon your request We can delete any
                    Personal Data after the account is
                    blocked/deactivated/deleted/otherwise no longer active,
                    except email and mobile number. We reserve the right to keep
                    the email and mobile number to avoid unlawful registering
                    using the same email and mobile number, even though,
                    standalone email and/or mobile number is not considered as
                    Personal Data;
                  </p>
                  <p>
                    Personal Data cannot be deleted when the account is
                    temporarily suspended upon your request. We reserve the
                    right to keep the Personal Data in order to match the
                    information when the account is pending to be reopened;
                  </p>
                  <p>
                    Asking us to provide you or another company you nominate
                    with certain aspects of your Personal Data, often referred
                    to as “the right to portability”;
                  </p>
                  <p>
                    The ability to object to any processing data where We are
                    doing it for our legitimate interests;
                  </p>
                  <p>
                    The ability to contest a decision made entirely by automated
                    processing, to express your point of view and to request
                    that a human review the decision;
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    For more information on these rights you can contact&nbsp;
                    <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                  </p>
                  <p>&nbsp;</p>
                  <h4>MINORS</h4>
                  <p>
                    The Services are not designed or directed to persons under
                    the age of 18 or persons under the age of legal consent with
                    respect to the use of the Services of any jurisdiction,
                    whichever is higher (“Legally of Age”). If you are not
                    Legally of Age, you should not download or use the Services
                    nor provide any Personal Information to us.
                  </p>
                  <p>
                    We reserve the right to access and verify any Personal
                    Information collected from you. In the event that We become
                    aware that a user who is not Legally of Age has shared any
                    information, we may discard such information. If you have
                    any reason to believe that a minor has shared any
                    information with us, please contact us.
                  </p>
                  <p>&nbsp;</p>
                  <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                  <p>
                    Over time, it may be necessary to modify or supplement our
                    Privacy Policy. When We make changes, We will post the
                    changes to our Websites and change the date at the top of
                    the policy. The amended Privacy Policy will be effective
                    upon posting on the site. We encourage you to visit our
                    Website from time to time to review our Privacy Policy in
                    the event it has changed. Your continued provision of
                    information to us or use of our Websites following the
                    posting of changes to the Privacy Policy means that you
                    accept the changes and our use of your Personal Information
                    in accordance with the revised Privacy Policy. If any
                    material changes are made to this Privacy Policy We will use
                    reasonable endeavors to inform you in advance by email,
                    notice on the Website or other agreed communications
                    channels.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    Additionally, in relation to the Privacy Policy, We reserve
                    right to amend, modify, update and change any of the terms
                    and conditions of the Privacy Policy from time to time and
                    you may be required to accept such changes to the Privacy
                    Policy in order to continue using the online Services and
                    products.
                  </p>
                  <p>
                    We will communicate the changes to you in advance, giving an
                    appropriate amount of time for you to consider and
                    understand the changes before they become effective. We will
                    not enforce material changes to the Privacy Policy without
                    your express consent. If you decline to accept the changes
                    to the Privacy Policy, or otherwise do not accept the
                    changes within the time period, We may not be able to
                    continue to provide some or all products and Services.
                  </p>
                  <p>&nbsp;</p>
                  <h4>TERMS OF USE</h4>
                  <p>
                    Your use of our Site constitutes your acceptance of
                    automatic use of cookies according to this Privacy Policy.
                    This Privacy Policy is an integral part of Website’s Terms
                    and Conditions and can be fully enforced as the Terms and
                    Conditions are accepted by registering on our Website.
                  </p>
                  <p>&nbsp;</p>
                  <h4>CONTACTING US</h4>
                  <p>
                    If you have any concerns, or would like more detail about
                    how We process your Personal Data, you can contact us
                    at&nbsp;
                    <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="terms-mobile svelte-rkbwo5">
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">Terms and Conditions</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className="active svelte-rkbwo5">Privacy Policy</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(180deg)",
                  }}
                >
                  <title>Arrow up</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper show show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">Sports T&amp;C</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">Prohibited Territories</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">Bonus T&amp;C</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">Games excluded from Bonus</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">FAQ</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">Affiliates</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="faq-block svelte-rkbwo5">
            <div className="faq-header svelte-rkbwo5">
              <h2 className=" svelte-rkbwo5">About Us</h2>
              <div className="arrow svelte-rkbwo5">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="20"
                  height="20"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                >
                  <title>Arrow down</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none !important" }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="faq-wrapper hide show-less svelte-rkbwo5">
              <div className="btn-container svelte-rkbwo5">
                <button className="show-btn svelte-rkbwo5">Read More</button>
              </div>
              <div className="block svelte-rkbwo5">
                <div className="download svelte-rkbwo5">
                  <button
                    className="secondary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    <span slot="icon-left">
                      <img
                        src={props.images["download-icon.2dd9fb9a.svg"]}
                        alt="download"
                        className="icon svelte-rkbwo5"
                      />{" "}
                      <img
                        src={props.images["download-icon-hov.20309507.svg"]}
                        alt="download"
                        className="icon-hover svelte-rkbwo5"
                      />
                    </span>{" "}
                  </button>
                </div>
                <h2>Privacy Policy</h2>
                <p>
                  <i>
                    This Privacy Policy is last updated on February 25, 2023
                  </i>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    This Privacy Policy describes the way in which UP-10X deals
                    with the information and data you provide to us to enable us
                    to manage your relationship with UP-10X.
                  </strong>
                </p>
                <p>
                  We will process any personal information provided to us or
                  otherwise held by us relating to you in the manner set out in
                  this Privacy Policy. Information may be provided via the
                  UP-10X Website (the "Website"), telephone calls or any other
                  means.
                </p>
                <p>
                  By accepting this Privacy Policy you agree that you understand
                  and accept the use of your personal information as set out in
                  this policy.&nbsp;
                </p>
                <p>
                  <strong>
                    If you do not agree with the terms of this Privacy Policy
                    please do not use the Website or otherwise provide us with
                    your personal information.
                  </strong>
                </p>
                <p>&nbsp;</p>
                <h4>WHO WE ARE</h4>
                <p>
                  References in this Privacy Policy to “UP-10X” (hereinafter
                  referred as “We”, “us” or “our”) shall mean up-10x.com (the
                  “Website” or “Site”) operated by Nexus Group Enterprises N.V.,
                  a limited liability company registered and established under
                  the laws of Curaçao with registration number 139781. Nexus
                  Group Enterprises N.V. is licensed by AntillePhone N.V. We
                  offer and provide Internet-based and/or web-based mobile
                  version interactive gaming services (hereinafter referred to
                  as the “Gambling Service” or “Services”.) We control the ways
                  your Personal Data is collected and the purposes for which
                  your Personal Data is used by UP-10X, acting as the “data
                  controller” for the purposes of applicable data protection
                  legislation.
                </p>
                <p>&nbsp;</p>
                <h4>OUR PRIVACY PRINCIPLES</h4>
                <p>
                  We protect, according to strict standards of security and
                  confidentiality, all non-public personal information we
                  collect about our customers;
                </p>
                <p>
                  We maintain physical, electronic and organizational safeguards
                  to protect this information;
                </p>
                <p>
                  We require any party providing products to us or our customers
                  on our behalf to protect the confidentiality of this
                  information;
                </p>
                <p>
                  We permit only authorized employees and contractors, including
                  but not limited to Support and Fraud team to have access to
                  non-public information of yours;
                </p>
                <p>
                  We afford former customers the same protections as existing
                  customers with respect to the use and sharing of their
                  information;
                </p>
                <h4>&nbsp;</h4>
                <h4>PROTECTING YOUR PERSONAL DATA</h4>
                <p>
                  Your Personal Data isn’t just protected by the quality,
                  commitment and high standards of UP-10X, it’s also protected
                  by law. We can only process your Personal Data when there is a
                  genuine reason to do so and it must meet one of the following:
                </p>
                <p>• &nbsp;Contractual relationship;</p>
                <p>• &nbsp;Legal obligation;</p>
                <p>• &nbsp;Your consent to process;</p>
                <p>• &nbsp;Our legitimate interest;</p>
                <p>• &nbsp;Public interest;</p>
                <p>• &nbsp;Your vital interests;</p>
                <p>&nbsp;</p>
                <h4>OUR INTERESTS</h4>
                <p>
                  When We have a business or commercial reason to process your
                  Personal Data this is referred to as a legitimate interest.
                  Your Personal Data is still protected and We must not process
                  it in a way that would be unfair to you or your interests.
                </p>
                <p>&nbsp;</p>
                <p>
                  If We do use legitimate interests as a reason to process your
                  Personal Data We will tell you that We are doing so, what our
                  legitimate interests are and provide you with a method to
                  raise any questions or objections you may have. However,
                  compelling grounds for processing such information may
                  over-ride your right to object.
                </p>
                <p>&nbsp;</p>
                <h4>WHY WE PROCESS YOUR PERSONAL DATA</h4>
                <p>
                  Considering legal obligations undertook to fulfill the
                  contract signed between us and the customers, including and
                  not limited to the legal obligations undertook to comply with
                  laws or regulations that apply to us, and for to be efficient
                  about how We fulfil our legal and contractual duties, We
                  process your Personal Data in order to:
                </p>
                <p>• &nbsp;Manage customer relationship;</p>
                <p>
                  • &nbsp;Develop and manage our brands, new and existing
                  products and Services for our customers, grow our business;
                </p>
                <p>• &nbsp;Create, develop, send and review marketing;</p>
                <p>
                  • &nbsp;Learn from our customers’ behavior in relation to our
                  product and Services;
                </p>
                <p>• &nbsp;To provide support for our products and Services;</p>
                <p>• &nbsp;To test new products, systems or Services;</p>
                <p>
                  • &nbsp;To compare and manage our relationship with and the
                  performance of other companies that provide Services to us and
                  our customers;
                </p>
                <p>• &nbsp;Deliver quality products and Services to you;</p>
                <p>
                  • &nbsp;Manage customer accounts, winnings, losses, payments
                  and withdrawals;
                </p>
                <p>
                  • &nbsp;Investigate, resolve and respond to any customer
                  complaints;
                </p>
                <p>• &nbsp;Prevent and detect improper use of our systems;</p>
                <p>• &nbsp;Prevent crime happening;</p>
                <p>• &nbsp;Detect, investigate and report crime;</p>
                <p>• &nbsp;Managing risk for us and our customers;</p>
                <p>
                  • &nbsp;Complying with any laws and regulations that apply to
                  us;
                </p>
                <p>
                  • &nbsp;Ensure corporate governance and compliance to all
                  legal and regulatory obligations;
                </p>
                <p>
                  • &nbsp;Fulfil our obligations as an accountable and
                  responsible organization;
                </p>
                <p>
                  • &nbsp;To exercise our rights set out in agreements or
                  contracts;
                </p>
                <p>&nbsp;</p>
                <h4>INTERNAL REPORTING</h4>
                <p>
                  If you choose not to provide your Personal Data it may prevent
                  us from meeting legal obligations, fulfilling a contract, or
                  performing Services required to run your account. Not
                  providing your Personal Data may mean We are unable to provide
                  you with products or Services.
                </p>
                <p>&nbsp;</p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                  The information and data about you which We may (Please, note
                  that the scope of the information provided below is not
                  mandatorily collected/stored/used/processed by us, UP-10X
                  reserves a right to collect/store/use/process data in certain
                  cases when necessary for the various purposes stated in this
                  Privacy Policy) collect, store, use and process may include
                  but not exceed the following:
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>1. Personal Information:</strong>
                </p>
                <p>
                  1.1.&nbsp;&nbsp;Account details: when you open an account and
                  register to use the Services, you may be asked to provide us
                  certain details about yourself, such as: first and last name,
                  email address, gender, birth data, physical address, ID
                  number, phone number, occupation, etc. In addition, We may
                  collect certain information as required under various KYC and
                  Anti-Money Laundering (AML) regulatory requirements, such as
                  your source of funds, identity, connection to criminal
                  authorities, utility bill copies and etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.2.&nbsp;&nbsp;Governmental-issued ID or Passport: in certain
                  instances, you will be required to provide a copy of a
                  governmental-issued ID or Passport, for identity verification
                  process. Please, note that this information is required for
                  certain features of our Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.3.&nbsp;&nbsp;Gaming information: in order to provide the
                  Services, We may automatically record different information
                  related to your account and use of our Services, including,
                  with respect to your transactions, wallets, deposits,
                  balances, bets, winnings, bonuses and withdraws.
                </p>
                <p>&nbsp;</p>
                <p>
                  1.4.&nbsp;&nbsp;Voluntary information: We also collect
                  information which you provide us voluntarily. For example,
                  when you respond to communications from us, communicate with
                  us via email or share additional information about yourself
                  through your use of the Services, such as on the live chat
                  with the support team or the game chat (e.g. Dice game).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2. Non-personal Information:</strong>
                </p>
                <p>
                  2.1.&nbsp;&nbsp;Log-in history and technical information: In
                  order to enhance the functionality of the Services and to
                  provide you with a better user experience, We collect
                  technical information transmitted by your device, including
                  certain software and hardware information (e.g. the type of
                  browser and operating system your device uses, language
                  preference, access time and the domain name of the Website
                  from which you linked to the Services; etc.).
                </p>
                <p>&nbsp;</p>
                <p>
                  2.2.&nbsp;&nbsp;Gameplay information: We record gameplay
                  information including, which, amongst other things, may
                  include information regarding your deposits, crypto-wallet
                  balances, bets, bonuses, game session duration and your high
                  scores. We may also share and publish such information through
                  any of our Platforms using your nickname or initials in cases
                  if the user wins a sum worthy of publicity.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.3.&nbsp;&nbsp;Device and connection information: If you
                  download the Software to your device, We may collect
                  information from the particular device you are using, for
                  security and fraud detection and prevention purposes. For
                  example, We may gather information with regard to other
                  software which is running simultaneously with the Software for
                  detecting if you are using software which is associated with
                  fraudulent activity (e.g. robots, malware, AI, etc.) or
                  checking if the connection you are using is via a VPN or
                  proxy.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.4.&nbsp;&nbsp;Analytic information: We may collect
                  information about your use of the Services, such as
                  applications' usage, log files, user activity (e.g. pages
                  viewed, the amount of time spent on particular pages, online
                  browsing, clicks, actions, etc.), time stamps, alerts, etc.
                  This information is collected for amongst other things
                  troubleshooting errors and bugs as well as for research and
                  analytics purposes about your use of the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  2.5.&nbsp;&nbsp;Anonymous information: We may anonymize or
                  de-identify the information collected by the Services or via
                  other means so that the information cannot, on its own,
                  personally identify you. Our use and disclosure of such
                  aggregated or de-identified information is not subject to any
                  restrictions under this Privacy Policy, and We may disclose it
                  to others without limitation and for any purpose, such as for
                  advertising or marketing purposes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Where it is reasonable for us to do so and not detrimental to
                  your rights and freedoms, We may also collect Personal Data
                  from publicly available sources such as internet searches,
                  social media or broadcast media.
                </p>
                <p>&nbsp;</p>
                <h4>HOW LONG WE KEEP YOUR PERSONAL DATA</h4>
                <p>
                  Whenever your data is kept by UP-10X We will ensure that it is
                  appropriately protected and only for acceptable purposes
                </p>
                <p>
                  We will keep your data for the period that you are a customer
                  of UP-10X. If you are no longer a customer of UP-10X, We will
                  keep your data for 10 years as required to comply with the
                  purposes set out in this policy. Your personal records will be
                  kept even if the customer account is
                  blocked/deactivated/deleted/otherwise no longer active or
                  cannot be deleted for technical reasons.
                </p>
                <p>&nbsp;</p>
                <h4>DATA SHARED WITH THIRD PARTIES</h4>
                <p>
                  When necessary for the following purposes, We may share your
                  Personal Data within the UP-10X group and with other
                  organizations stated below:
                </p>
                <p>
                  • &nbsp;Law enforcement agencies, regulators and other
                  authorities;
                </p>
                <p>• &nbsp;Credit reference agencies;</p>
                <p>• &nbsp;Fraud prevention agencies;</p>
                <p>• &nbsp;Identity verification agencies;</p>
                <p>• &nbsp;Organizations that introduce you to us;</p>
                <p>
                  • &nbsp;Third parties you ask us (or permit us) to share your
                  data with;
                </p>
                <p>
                  • &nbsp;Third parties necessary to provide products or
                  Services which you have requested;
                </p>
                <p>&nbsp;</p>
                <p>
                  The Site may use your Personal Information, including, but not
                  limited to your name, email address or telephone number,
                  ourselves or by using our third-party
                  subcontractors/affiliates/game providers/joint marketing
                  partners/business partners for the purpose of providing you
                  with promotional materials, concerning the Services as well as
                  products, services, websites and applications which relate to
                  you or interest you.&nbsp;&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  We may share and disclose said Personal Information with our
                  Marketing Affiliates for the purpose of providing you
                  different marketing offers, which We, or our Marketing
                  Affiliates, believe are relevant for you. Our Marketing
                  Affiliates may use this Personal Information for different
                  marketing techniques, such as direct email, post, SMS and
                  telephone marketing purposes.
                </p>
                <p>
                  We may share any Personal information We collect from and
                  about you with non-affiliated third parties. Information may
                  be shared with or collected by third party service providers
                  who provide us with Services such as information processing
                  (e.g. under AML or KYC policy adopted by our Website and
                  etc.). We require these providers to exercise reasonable care
                  to protect your Personal Information and restrict the use of
                  the data provided to the purposes for which it was provided to
                  them.
                </p>
                <p>
                  Your personal information may be transferred between the
                  countries and disclosed to other companies within associated
                  or subsidiary companies and to business partners, or
                  successors in title to our business.
                </p>
                <p>&nbsp;</p>
                <h4>HOW WE KEEP YOUR INFORMATION SECURED</h4>
                <p>
                  We take great care in implementing and maintaining the
                  security of the Services and your information. We have put in
                  place appropriate physical and technological safeguards to
                  help prevent unauthorized access, to maintain data security,
                  and to use correctly the information We collect online. These
                  safeguards vary based on the sensitivity of the information
                  that We collect and store.
                </p>
                <p>&nbsp;</p>
                <h4>
                  <strong>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </strong>
                </h4>
                <p>
                  • &nbsp;Secure network topology, which includes intrusion
                  prevention and Firewall systems;
                </p>
                <p>• &nbsp;Encrypted communication;</p>
                <p>• &nbsp;Authentication and Access Control;</p>
                <p>• &nbsp;External and Internal audit tests, etc.</p>
                <p>&nbsp;</p>
                <h4>ONLINE SECURITY</h4>
                <p>
                  Although We take reasonable steps to safeguard information,
                  however, due to the design of the Internet, We cannot be
                  responsible for the acts of those who gain unauthorized
                  access, abuse the Services or hacking and We make no warranty,
                  express, implied or otherwise, that We will prevent such
                  access.
                </p>
                <p>
                  The Site may contain hyperlinks to other websites that may
                  have information policies and practices that are different
                  from ours. The fact that We have provided a link to a site is
                  not an endorsement, authorization, sponsorship, or affiliation
                  by us with respect to such site, its owners, or its providers.
                  Links to other websites are provided only as a convenience to
                  you. We are not responsible for the content, validity, privacy
                  policies or reliability of any website linked to this Site
                  that is not within our family of properties. There are risks
                  in using any information, products, software or Services found
                  on the Internet, and you should make sure that you completely
                  understand these risks before using, relying upon, or
                  purchasing anything via the Internet.
                </p>
                <p>&nbsp;</p>
                <h4>TELEPHONE/VIDEO CALLS</h4>
                <p>
                  Telephone or video calls to and from our Support office are
                  recorded for KYC, training and security purposes along with
                  the resolution of any queries arising from the Service you
                  receive.
                </p>
                <p>&nbsp;</p>
                <h4>USE OF COOKIES</h4>
                <p>
                  The Website uses cookies to deliver a better and more
                  personalized Service to users.
                </p>
                <p>
                  Cookies are small text files containing minimum amounts of
                  information which are sent to and stored on your computer,
                  smartphone or other device when you access a Website. Cookies
                  are then sent back to the originating Website on each
                  subsequent visit or to another website that recognizes that
                  cookie.
                </p>
                <p>
                  Cookies are generally used to improve and enhance the user
                  experience. Some of the cookies We use are essential for the
                  Website to operate.
                </p>
                <p>
                  UP-10X’s use of cookies on the Website falls into the
                  following categories:
                </p>
                <p>• &nbsp;Session Management;</p>
                <p>• &nbsp;Functionality;</p>
                <p>• &nbsp;Fraud Prevention;</p>
                <p>• &nbsp;Tracking;</p>
                <p>• &nbsp;Analysis;</p>
                <p>
                  Third party advertising companies/affiliates, that work with
                  us, may also use cookies and related technologies to collect
                  and store Information to provide you with advertisements that
                  are likely to interest you.
                </p>
                <p>
                  You can find more information about cookies at&nbsp;
                  <a href="http://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <h4>YOUR RIGHTS OVER YOUR PERSONAL DATA</h4>
                <p>
                  We will assist you if you choose to exercise any of your
                  rights over your Personal Data, including:
                </p>
                <p>
                  • &nbsp;Withdrawing your previously granted consent (however,
                  this will not invalidate any information processed under your
                  previous consent);
                </p>
                <p>
                  • &nbsp;Lodging a complaint with any relevant Data Protection
                  Authority;
                </p>
                <p>
                  • &nbsp;Access to your Personal Data that We hold or process;
                </p>
                <p>
                  • &nbsp;Correction of any Personal Data that is incorrect or
                  out of date;
                </p>
                <p>• &nbsp;Erasure of any Personal Data that We process;</p>
                <p>
                  Please be noted, that upon your request We can delete any
                  Personal Data after the account is
                  blocked/deactivated/deleted/otherwise no longer active, except
                  email and mobile number. We reserve the right to keep the
                  email and mobile number to avoid unlawful registering using
                  the same email and mobile number, even though, standalone
                  email and/or mobile number is not considered as Personal Data;
                </p>
                <p>
                  Personal Data cannot be deleted when the account is
                  temporarily suspended upon your request. We reserve the right
                  to keep the Personal Data in order to match the information
                  when the account is pending to be reopened;
                </p>
                <p>
                  Asking us to provide you or another company you nominate with
                  certain aspects of your Personal Data, often referred to as
                  “the right to portability”;
                </p>
                <p>
                  The ability to object to any processing data where We are
                  doing it for our legitimate interests;
                </p>
                <p>
                  The ability to contest a decision made entirely by automated
                  processing, to express your point of view and to request that
                  a human review the decision;
                </p>
                <p>&nbsp;</p>
                <p>
                  For more information on these rights you can contact&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
                <p>&nbsp;</p>
                <h4>MINORS</h4>
                <p>
                  The Services are not designed or directed to persons under the
                  age of 18 or persons under the age of legal consent with
                  respect to the use of the Services of any jurisdiction,
                  whichever is higher (“Legally of Age”). If you are not Legally
                  of Age, you should not download or use the Services nor
                  provide any Personal Information to us.
                </p>
                <p>
                  We reserve the right to access and verify any Personal
                  Information collected from you. In the event that We become
                  aware that a user who is not Legally of Age has shared any
                  information, we may discard such information. If you have any
                  reason to believe that a minor has shared any information with
                  us, please contact us.
                </p>
                <p>&nbsp;</p>
                <h4>CHANGES TO OUR PRIVACY STATEMENT</h4>
                <p>
                  Over time, it may be necessary to modify or supplement our
                  Privacy Policy. When We make changes, We will post the changes
                  to our Websites and change the date at the top of the policy.
                  The amended Privacy Policy will be effective upon posting on
                  the site. We encourage you to visit our Website from time to
                  time to review our Privacy Policy in the event it has changed.
                  Your continued provision of information to us or use of our
                  Websites following the posting of changes to the Privacy
                  Policy means that you accept the changes and our use of your
                  Personal Information in accordance with the revised Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy We will use reasonable endeavors to inform you in
                  advance by email, notice on the Website or other agreed
                  communications channels.
                </p>
                <p>&nbsp;</p>
                <p>
                  Additionally, in relation to the Privacy Policy, We reserve
                  right to amend, modify, update and change any of the terms and
                  conditions of the Privacy Policy from time to time and you may
                  be required to accept such changes to the Privacy Policy in
                  order to continue using the online Services and products.
                </p>
                <p>
                  We will communicate the changes to you in advance, giving an
                  appropriate amount of time for you to consider and understand
                  the changes before they become effective. We will not enforce
                  material changes to the Privacy Policy without your express
                  consent. If you decline to accept the changes to the Privacy
                  Policy, or otherwise do not accept the changes within the time
                  period, We may not be able to continue to provide some or all
                  products and Services.
                </p>
                <p>&nbsp;</p>
                <h4>TERMS OF USE</h4>
                <p>
                  Your use of our Site constitutes your acceptance of automatic
                  use of cookies according to this Privacy Policy. This Privacy
                  Policy is an integral part of Website’s Terms and Conditions
                  and can be fully enforced as the Terms and Conditions are
                  accepted by registering on our Website.
                </p>
                <p>&nbsp;</p>
                <h4>CONTACTING US</h4>
                <p>
                  If you have any concerns, or would like more detail about how
                  We process your Personal Data, you can contact us at&nbsp;
                  <a href="mailto:support@up-10x.com">support@up-10x.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default TermsAndConditions;
