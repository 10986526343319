const BestSlotsBlock = (props) => {
  return (
    <div className="games-block container ms-auto me-auto swiper-wrapper-auto">
      <div className="s-wrapper position-relative swiper-wrapper-auto false svelte-12mba6b">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode">
          <div
            className="container-start svelte-12mba6b"
            slot="container-start"
          >
            <div className="title svelte-12mba6b">
              Best Slots{" "}
              <img
                src="./assets/right-arrow.ba864f8e.svg"
                alt="arrow"
                className="svelte-12mba6b"
              />
            </div>
            <div className="action-buttons d-flex items-center svelte-12mba6b">
              <button
                className="prev1e15a3ba-32e1-4918-91a3-2defb252cf2c btn svelte-12mba6b swiper-button-disabled"
                disabled=""
              >
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(90deg)",
                  }}
                >
                  <title>Arrow left</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>{" "}
              <button className="next1e15a3ba-32e1-4918-91a3-2defb252cf2c btn svelte-12mba6b">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(270deg)",
                  }}
                >
                  <title>Arrow right</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            className="swiper-wrapper"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <div className="swiper-slide swiper-slide-active">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['original.7fd4529ced38a8ff87cb.avif']}
                      alt="Super Golden Dragon Inferno"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Super Golden Dragon Inferno"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide swiper-slide-next">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(55)"
                      alt="Big Bass Splash"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Big Bass Splash"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(45)"
                      alt="Jumbo Joker"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Jumbo Joker"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(56)"
                      alt="Jungle Stripes"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Jungle Stripes"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(57)"
                      alt="Money Train 3"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Money Train 3"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(58)"
                      alt="The Rite"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="The Rite"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(59)"
                      alt="Wild Streak"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Wild Streak"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(60)"
                      alt="The Rise of AI"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="The Rise of AI"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(12)"
                      alt="Sweet Bonanza Xmas"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Sweet Bonanza Xmas"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(61)"
                      alt="Trinity Reels"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Trinity Reels"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(62)"
                      alt="Mystic Hive"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Mystic Hive"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(63)"
                      alt="Snakes and Ladders Megadice"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Snakes and Ladders Megadice"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(64)"
                      alt="Valley of the Gods"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Valley of the Gods"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(65)"
                      alt="Cash Streak"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Cash Streak"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(66)"
                      alt="Black Jack"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Black Jack"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(67)"
                      alt="Riot 2: Blow &amp; Burn"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Riot 2: Blow &amp; Burn"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(68)"
                      alt="Book of Vlad"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Book of Vlad"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(69)"
                      alt="Joker Stoker"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Joker Stoker"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(70)"
                      alt="The Tomb: Dragon Emperor&#39;s Treasure"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="The Tomb: Dragon Emperor&#39;s Treasure"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(71)"
                      alt="The Dog House Multihold"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="The Dog House Multihold"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(72)"
                      alt="The Candy Crush"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="The Candy Crush"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(73)"
                      alt="Cyber Wolf"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Cyber Wolf"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(74)"
                      alt="Dynamite Miner"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Dynamite Miner"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(75)"
                      alt="Mermaid&#39;s Bay"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Mermaid&#39;s Bay"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(48)"
                      alt="Dragon&#39;s Lucky 25"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Dragon&#39;s Lucky 25"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(21)"
                      alt="Buffalo King Megaways"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Buffalo King Megaways"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(28)"
                      alt="Expansion"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Expansion"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(16)"
                      alt="Gates of Olympus 1000"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Gates of Olympus 1000"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(76)"
                      alt="Wilds Of Fortune"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Wilds Of Fortune"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(77)"
                      alt="2023 Hit Slot"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="2023 Hit Slot"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BestSlotsBlock;
