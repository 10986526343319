const Social = (props) => {
  return (
    <div className="social desktop svelte-111tpvb">
      <p className="title svelte-111tpvb">Social Pages</p>
      <div className="social-icons svelte-111tpvb">

        {/* Twitter */}
        <li className="social-icon svelte-111tpvb" style={{ "--hover-color": "black" }}>
          <a
            href="https://twitter.com/up-10xcom"
            target="_blank"
            rel="noreferrer"
            className="svelte-111tpvb"
          >
            <img
              src={props.images["twitter.5d5f36ce.svg"]}
              alt="twitter"
              className="svelte-111tpvb"
            />
          </a>{" "}
        </li>

        {/* FB 
        <li className="social-icon svelte-111tpvb" style={{ "--hover-color": "black" }}>
          <a
            href="https://www.facebook.com/people/Up-10xcom/100094244176659/"
            target="_blank"
            rel="noreferrer"
            className="svelte-111tpvb"
          >
            <img
              src={props.images["twitter.5d5f36ce.svg"]}
              alt="twitter"
              className="svelte-111tpvb"
            />
          </a>{" "}
        </li>*/}

        {/* LinkedIn 
        <li className="social-icon svelte-111tpvb" style={{ "--hover-color": "black" }}>
          <a
            href="https://www.linkedin.com/company/up-10x-com/"
            target="_blank"
            rel="noreferrer"
            className="svelte-111tpvb"
          >
            <img
              src={props.images["twitter.5d5f36ce.svg"]}
              alt="twitter"
              className="svelte-111tpvb"
            />
          </a>{" "}
        </li>*/}

        <li
          className="social-icon svelte-111tpvb"
          style={{ "--hover-color": "#5B65EA" }}
        >
          <a
            href="https://discord.com/invite/up-10xcom"
            target="_blank"
            rel="noreferrer"
            className="svelte-111tpvb"
          >
            <img
              src={props.images["discord.9a150ea4.svg"]}
              alt="discord"
              className="svelte-111tpvb"
            />
          </a>{" "}
        </li>
        <li
          className="social-icon svelte-111tpvb"
          style={{ "--hover-color": "#F0B90B" }}
        >
          <a
            href="https://bitcointalk.org/"
            target="_blank"
            rel="noreferrer"
            className="svelte-111tpvb"
          >
            <img
              src={props.images["btc.ac6140a6.svg"]}
              alt="btc talk"
              className="svelte-111tpvb"
            />
          </a>{" "}
        </li>
        <li
          className="social-icon svelte-111tpvb"
          style={{ "--hover-color": "#33A9DE" }}
        >
          <a
            href="https://t.me/up-10xcom"
            target="_blank"
            rel="noreferrer"
            className="svelte-111tpvb"
          >
            <img
              src={props.images["telegram.287f68d1.svg"]}
              alt="Telegram"
              className="svelte-111tpvb"
            />
          </a>{" "}
        </li>
        <li
          className="social-icon svelte-111tpvb"
          style={{ "--hover-color": "#EB5528" }}
        >
          <a
            href="https://www.reddit.com/r/up-10xcom/"
            target="_blank"
            rel="noreferrer"
            className="svelte-111tpvb"
          >
            <img
              src={props.images["reddit.b6d4dd0c.svg"]}
              alt="Reddit"
              className="svelte-111tpvb"
            />
          </a>{" "}
        </li>
      </div>
    </div>
  );
};
export default Social;
