const Slider = (props) => {
  return (
    <div className="container ms-auto me-auto">
      <div className="s-wrapper position-relative false is-banner svelte-174fjto">
        <div className="swiper swiper-fade swiper-initialized swiper-horizontal swiper-pointer-events swiper-watch-progress swiper-backface-hidden">
          <div
            className="container-start svelte-174fjto"
            slot="container-start"
          ></div>

          <div className="swiper-wrapper" style={{ transitionDuration: "0ms" }}>
            <div
              className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
              data-swiper-slide-index="4"
              style={{
                width: "1200px",
                transitionDuration: "0ms",
                opacity: 1,
                transform: "translate3d(0px, 0px, 0px)",
              }}
            >
              <div className="banner svelte-1x9oow1">
                <div className="info svelte-1x9oow1">
                  <h2 className="cursor-pointer title svelte-1x9oow1">
                    Unique Live Game
                  </h2>
                  <p className="cursor-pointer subtitle svelte-1x9oow1">
                    Play and Win massive multiplier!
                  </p>
                </div>
                <div className="button svelte-1x9oow1">
                  <button
                    className="primary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    {" "}
                    Play Now{" "}
                  </button>
                </div>
                <div className="md-down-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={
                          props.images["42f0394cca674bd0ac84fd5ff5ef7f2f.webp"]
                        }
                        alt="Unique Live Game"
                        className="image svelte-l3byt6"
                        style={{}}
                        title="Unique Live Game"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="md-up-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={
                          props.images["25691ff243c442e6bb3c84a834d6ed38.webp"]
                        }
                        alt="Unique Live Game"
                        className="image svelte-l3byt6"
                        style={{ objectPosition: "center" }}
                        title="Unique Live Game"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide swiper-slide-duplicate-next"
              data-swiper-slide-index="0"
              style={{
                width: "1200px",
                transitionDuration: "0ms",
                opacity: 1,
                transform: "translate3d(-1200px, 0px, 0px)",
              }}
            >
              <div className="banner svelte-1x9oow1">
                <div className="info svelte-1x9oow1">
                  <h2 className="cursor-pointer title svelte-1x9oow1">
                    Evolution’s Crazy Pachinko
                  </h2>
                  <p className="cursor-pointer subtitle svelte-1x9oow1">
                    Exciting Fusion of Slot and Bonus Play
                  </p>
                </div>
                <div className="button svelte-1x9oow1">
                  <button
                    className="primary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    {" "}
                    Play Now{" "}
                  </button>
                </div>
                <div className="md-down-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/9848b50a109545918b35c691f75c3fa0.webp"
                        alt="Evolution’s Crazy Pachinko"
                        className="image svelte-l3byt6"
                        style={{}}
                        title="Evolution’s Crazy Pachinko"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="md-up-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/0e109c90d33f49c7a07a928d60a32daf.webp"
                        alt="Evolution’s Crazy Pachinko"
                        className="image svelte-l3byt6"
                        style={{ objectPosition: "center" }}
                        title="Evolution’s Crazy Pachinko"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide"
              data-swiper-slide-index="1"
              style={{
                width: "1200px",
                transitionDuration: "0ms",
                opacity: 1,
                transform: "translate3d(-2400px, 0px, 0px)",
              }}
            >
              <div className="banner svelte-1x9oow1">
                <div className="info svelte-1x9oow1">
                  <h2 className="cursor-pointer title svelte-1x9oow1">
                    Evolution: Red Door Roulette
                  </h2>
                  <p className="cursor-pointer subtitle svelte-1x9oow1">
                    Have Crazy Time and Win Big At FJ
                  </p>
                </div>
                <div className="button svelte-1x9oow1">
                  <button
                    className="primary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    {" "}
                    Play Now{" "}
                  </button>
                </div>
                <div className="md-down-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/5ae4cea552e247668f74ab4cf176301f.webp"
                        alt="Evolution: Red Door Roulette"
                        className="image svelte-l3byt6"
                        style={{}}
                        title="Evolution: Red Door Roulette"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="md-up-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/6d1b963619ab4ec1a2bd2711e6e6b2e9.webp"
                        alt="Evolution: Red Door Roulette"
                        className="image svelte-l3byt6"
                        style={{ objectPosition: "center" }}
                        title="Evolution: Red Door Roulette"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide"
              data-swiper-slide-index="2"
              style={{
                width: "1200px",
                transitionDuration: "0ms",
                opacity: 1,
                transform: "translate3d(-3600px, 0px, 0px)",
              }}
            >
              <div className="banner svelte-1x9oow1">
                <div className="info svelte-1x9oow1">
                  <h2 className="cursor-pointer title svelte-1x9oow1">
                    Try PowerUP first!
                  </h2>
                  <p className="cursor-pointer subtitle svelte-1x9oow1">
                    Exclusively on Up-10X
                  </p>
                </div>
                <div className="button svelte-1x9oow1">
                  <button
                    className="primary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    {" "}
                    Play Now{" "}
                  </button>
                </div>
                <div className="md-down-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={
                          props.images["436af5fd81e24fa5a5d5722a6e832c1b.webp"]
                        }
                        alt="Try PowerUP first!"
                        className="image svelte-l3byt6"
                        style={{}}
                        title="Try PowerUP first!"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="md-up-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/4dfc597f0c204d8898e1fb87b01986aa.webp"
                        alt="Try PowerUP first!"
                        className="image svelte-l3byt6"
                        style={{ objectPosition: "center" }}
                        title="Try PowerUP first!"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide swiper-slide-prev"
              data-swiper-slide-index="3"
              style={{
                width: "1200px",
                transitionDuration: "0ms",
                opacity: 1,
                transform: "translate3d(-4800px, 0px, 0px)",
              }}
            >
              <div className="banner svelte-1x9oow1">
                <div className="info svelte-1x9oow1">
                  <h2 className="cursor-pointer title svelte-1x9oow1">
                    Experience Sweet Wins
                  </h2>
                  <p className="cursor-pointer subtitle svelte-1x9oow1">
                    Play live game from Pragmatic
                  </p>
                </div>
                <div className="button svelte-1x9oow1">
                  <button
                    className="primary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    {" "}
                    Play Now{" "}
                  </button>
                </div>
                <div className="md-down-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/220be6a8826f4a37b4e7f5e0a5aa1719.webp"
                        alt="Experience Sweet Wins"
                        className="image svelte-l3byt6"
                        style={{}}
                        title="Experience Sweet Wins"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="md-up-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/d5309259b2af49998a551fef24b323de.webp"
                        alt="Experience Sweet Wins"
                        className="image svelte-l3byt6"
                        style={{ objectPosition: "center" }}
                        title="Experience Sweet Wins"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide swiper-slide-visible swiper-slide-active"
              data-swiper-slide-index="4"
              style={{
                width: "1200px",
                transitionDuration: "0ms",
                opacity: 1,
                transform: "translate3d(-6000px, 0px, 0px)",
              }}
            >
              <div className="banner svelte-1x9oow1">
                <div className="info svelte-1x9oow1">
                  <h2 className="cursor-pointer title svelte-1x9oow1">
                    Unique Live Game
                  </h2>
                  <p className="cursor-pointer subtitle svelte-1x9oow1">
                    Play and Win massive multiplier!
                  </p>
                </div>
                <div className="button svelte-1x9oow1">
                  <button
                    className="primary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    {" "}
                    Play Now{" "}
                  </button>
                </div>
                <div className="md-down-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={
                          props.images["42f0394cca674bd0ac84fd5ff5ef7f2f.webp"]
                        }
                        alt="Unique Live Game"
                        className="image svelte-l3byt6"
                        style={{}}
                        title="Unique Live Game"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="md-up-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={
                          props.images["25691ff243c442e6bb3c84a834d6ed38.webp"]
                        }
                        alt="Unique Live Game"
                        className="image svelte-l3byt6"
                        style={{ objectPosition: "center" }}
                        title="Unique Live Game"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide swiper-slide-duplicate swiper-slide-next"
              data-swiper-slide-index="0"
              style={{
                width: "1200px",
                transitionDuration: "0ms",
                opacity: 0,
                transform: "translate3d(-7200px, 0px, 0px)",
              }}
            >
              <div className="banner svelte-1x9oow1">
                <div className="info svelte-1x9oow1">
                  <h2 className="cursor-pointer title svelte-1x9oow1">
                    Evolution’s Crazy Pachinko
                  </h2>
                  <p className="cursor-pointer subtitle svelte-1x9oow1">
                    Exciting Fusion of Slot and Bonus Play
                  </p>
                </div>
                <div className="button svelte-1x9oow1">
                  <button
                    className="primary standart normal svelte-uia73q"
                    type="button"
                    style={{}}
                  >
                    {" "}
                    Play Now{" "}
                  </button>
                </div>
                <div className="md-down-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/9848b50a109545918b35c691f75c3fa0.webp"
                        alt="Evolution’s Crazy Pachinko"
                        className="image svelte-l3byt6"
                        style={{}}
                        title="Evolution’s Crazy Pachinko"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="md-up-show-image">
                  <div className="img-wrap cursor-pointer svelte-1x9oow1">
                    <div
                      className="wrapper svelte-l3byt6"
                      style={{
                        "--borderRadius": "12px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="./assets/0e109c90d33f49c7a07a928d60a32daf.webp"
                        alt="Evolution’s Crazy Pachinko"
                        className="image svelte-l3byt6"
                        style={{ objectPosition: "center" }}
                        title="Evolution’s Crazy Pachinko"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Slider;
