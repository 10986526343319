import CasinoContent from "./CasinoContent";
import "./casino.css";

const Casino = (props) => {
  return (
    <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">

      {/* Slider */}
      <div className="container ms-auto me-auto">
        <div className="s-wrapper position-relative false is-banner svelte-174fjto">
          <div className="swiper swiper-fade swiper-initialized swiper-horizontal swiper-pointer-events swiper-watch-progress swiper-backface-hidden">
            <div
              className="container-start svelte-174fjto"
              slot="container-start"
            ></div>
            <div
              className="swiper-wrapper"
              style={{ transitionDuration: "0ms" }}
            >
              <div
                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                data-swiper-slide-index="4"
                style={{
                  width: "1074px",
                  transitionDuration: "0ms",
                  opacity: 1,
                  transform: "translate3d(0px, 0px, 0px)",
                }}
              >
                <div className="banner svelte-1x9oow1">
                  <div className="info svelte-1x9oow1">
                    <h2 className="cursor-pointer title svelte-1x9oow1">
                      Re Kill Ultimate Release from Mascot
                    </h2>
                    <p className="cursor-pointer subtitle svelte-1x9oow1">
                      Play to Survive From High-Stake Zombies and Win Big!
                    </p>
                  </div>
                  <div className="button svelte-1x9oow1">
                    <button
                      className="primary standart normal svelte-uia73q"
                      type="button"
                      style={{}}
                    >
                      {" "}
                      Play Now{" "}
                    </button>
                  </div>
                  <div className="md-down-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['0e882354719d4406986aed64d1b28891.webp']}
                          alt="Re Kill Ultimate Release from Mascot"
                          className="image svelte-l3byt6"
                          style={{}}
                          title="Re Kill Ultimate Release from Mascot"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md-up-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['c07f2adfdfb14c8a9c0a9b5367efb27c.webp']}
                          alt="Re Kill Ultimate Release from Mascot"
                          className="image svelte-l3byt6"
                          style={{ objectPosition: "center" }}
                          title="Re Kill Ultimate Release from Mascot"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-duplicate-next"
                data-swiper-slide-index="0"
                style={{
                  width: "1074px",
                  transitionDuration: "0ms",
                  opacity: 1,
                  transform: "translate3d(-1074px, 0px, 0px)",
                }}
              >
                <div className="banner svelte-1x9oow1">
                  <div className="info svelte-1x9oow1">
                    <h2 className="cursor-pointer title svelte-1x9oow1">
                      Pragmatic Hunt
                    </h2>
                    <p className="cursor-pointer subtitle svelte-1x9oow1">
                      Boost Your Multipliers to Claim Major Wins!
                    </p>
                  </div>
                  <div className="button svelte-1x9oow1">
                    <button
                      className="primary standart normal svelte-uia73q"
                      type="button"
                      style={{}}
                    >
                      {" "}
                      Enter the Arena{" "}
                    </button>
                  </div>
                  <div className="md-down-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['78513cada95e47cc8b06236d8c082ddc.webp']}
                          alt="Pragmatic Hunt"
                          className="image svelte-l3byt6"
                          style={{}}
                          title="Pragmatic Hunt"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md-up-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['8442adcd1ad14397acb3487cd7db04da.webp']}
                          alt="Pragmatic Hunt"
                          className="image svelte-l3byt6"
                          style={{ objectPosition: "center" }}
                          title="Pragmatic Hunt"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide"
                data-swiper-slide-index="1"
                style={{ width: "1074px", transitionDuration: "0ms", opacity: 1, transform: "translate3d(-2148px, 0px, 0px)" }}
              >
                <div className="banner svelte-1x9oow1">
                  <div className="info svelte-1x9oow1">
                    <h2 className="cursor-pointer title svelte-1x9oow1">
                      Dragon's Lucky 25
                    </h2>
                    <p className="cursor-pointer subtitle svelte-1x9oow1">
                      Immerse in Asian mythology and win x1000 your bet!
                    </p>
                  </div>
                  <div className="button svelte-1x9oow1">
                    <button
                      className="primary standart normal svelte-uia73q"
                      type="button"
                      style={{}}
                    >
                      {" "}
                      Play Now{" "}
                    </button>
                  </div>
                  <div className="md-down-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['a1c00b8d40dc40588b1e1483cdfcc1c4.webp']}
                          alt="Dragon&#39;s Lucky 25"
                          className="image svelte-l3byt6"
                          style={{}}
                          title="Dragon&#39;s Lucky 25"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md-up-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['bca9998e4bfb4810ba6af79451657612.webp']}
                          alt="Dragon&#39;s Lucky 25"
                          className="image svelte-l3byt6"
                          style={{ objectPosition: "center" }}
                          title="Dragon&#39;s Lucky 25"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide"
                data-swiper-slide-index="2"
                style={{ width: "1074px", transitionDuration: "0ms", opacity: 1, transform: "translate3d(-3222px, 0px, 0px)" }}
              >
                <div className="banner svelte-1x9oow1">
                  <div className="info svelte-1x9oow1">
                    <h2 className="cursor-pointer title svelte-1x9oow1">
                      Jade Coins by Endorphina
                    </h2>
                    <p className="cursor-pointer subtitle svelte-1x9oow1">
                      Explore the wealth of Asian aesthetics
                    </p>
                  </div>
                  <div className="button svelte-1x9oow1">
                    <button
                      className="primary standart normal svelte-uia73q"
                      type="button"
                      style={{}}
                    >
                      {" "}
                      Play Now{" "}
                    </button>
                  </div>
                  <div className="md-down-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['c90fdf8be7c2417cb90a2e7517a73851.webp']}
                          alt="Jade Coins by Endorphina"
                          className="image svelte-l3byt6"
                          style={{}}
                          title="Jade Coins by Endorphina"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md-up-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src="./assets/304b5232c43449d5a3ec0a3e08e2a7ac.webp"
                          alt="Jade Coins by Endorphina"
                          className="image svelte-l3byt6"
                          style={{ objectPosition: "center" }}
                          title="Jade Coins by Endorphina"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-prev"
                data-swiper-slide-index="3"
                style={{ width: "1074px", transitionDuration: "0ms", opacity: 1, transform: "translate3d(-4296px, 0px, 0px)" }}
              >
                <div className="banner svelte-1x9oow1">
                  <div className="info svelte-1x9oow1">
                    <h2 className="cursor-pointer title svelte-1x9oow1">
                      2024 Hit Slot by Endorphina
                    </h2>
                    <p className="cursor-pointer subtitle svelte-1x9oow1">
                      Grab a basket full of healthy fruits and get rewards
                    </p>
                  </div>
                  <div className="button svelte-1x9oow1">
                    <button
                      className="primary standart normal svelte-uia73q"
                      type="button"
                      style={{}}
                    >
                      {" "}
                      Play Now{" "}
                    </button>
                  </div>
                  <div className="md-down-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src="./assets/3ed57a0a79054d9ca4d083295cd3371a.webp"
                          alt="2024 Hit Slot by Endorphina"
                          className="image svelte-l3byt6"
                          style={{}}
                          title="2024 Hit Slot by Endorphina"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md-up-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src="./assets/397900bde5854de29c7f8e460c638527.webp"
                          alt="2024 Hit Slot by Endorphina"
                          className="image svelte-l3byt6"
                          style={{ objectPosition: "center" }}
                          title="2024 Hit Slot by Endorphina"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-visible swiper-slide-active"
                data-swiper-slide-index="4"
                style={{ width: "1074px", transitionDuration: "0ms", opacity: 1, transform: "translate3d(-5370px, 0px, 0px)" }}
              >
                <div className="banner svelte-1x9oow1">
                  <div className="info svelte-1x9oow1">
                    <h2 className="cursor-pointer title svelte-1x9oow1">
                      Re Kill Ultimate Release from Mascot
                    </h2>
                    <p className="cursor-pointer subtitle svelte-1x9oow1">
                      Play to Survive From High-Stake Zombies and Win Big!
                    </p>
                  </div>
                  <div className="button svelte-1x9oow1">
                    <button
                      className="primary standart normal svelte-uia73q"
                      type="button"
                      style={{}}
                    >
                      {" "}
                      Play Now{" "}
                    </button>
                  </div>
                  <div className="md-down-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['0e882354719d4406986aed64d1b28891.webp']}
                          alt="Re Kill Ultimate Release from Mascot"
                          className="image svelte-l3byt6"
                          style={{}}
                          title="Re Kill Ultimate Release from Mascot"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md-up-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={props.images['c07f2adfdfb14c8a9c0a9b5367efb27c.webp']}
                          alt="Re Kill Ultimate Release from Mascot"
                          className="image svelte-l3byt6"
                          style={{ objectPosition: "center" }}
                          title="Re Kill Ultimate Release from Mascot"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-duplicate swiper-slide-next"
                data-swiper-slide-index="0"
                style={{ width: "1074px", transitionDuration: "0ms", opacity: 0, transform: "translate3d(-6444px, 0px, 0px)" }}
              >
                <div className="banner svelte-1x9oow1">
                  <div className="info svelte-1x9oow1">
                    <h2 className="cursor-pointer title svelte-1x9oow1">
                      Pragmatic Hunt
                    </h2>
                    <p className="cursor-pointer subtitle svelte-1x9oow1">
                      Boost Your Multipliers to Claim Major Wins!
                    </p>
                  </div>
                  <div className="button svelte-1x9oow1">
                    <button
                      className="primary standart normal svelte-uia73q"
                      type="button"
                      style={{}}
                    >
                      {" "}
                      Enter the Arena{" "}
                    </button>
                  </div>
                  <div className="md-down-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src="./assets/78513cada95e47cc8b06236d8c082ddc.webp"
                          alt="Pragmatic Hunt"
                          className="image svelte-l3byt6"
                          style={{}}
                          title="Pragmatic Hunt"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md-up-show-image">
                    <div className="img-wrap cursor-pointer svelte-1x9oow1">
                      <div
                        className="wrapper svelte-l3byt6"
                        style={{
                          "--borderRadius": "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src="./assets/8442adcd1ad14397acb3487cd7db04da.webp"
                          alt="Pragmatic Hunt"
                          className="image svelte-l3byt6"
                          style={{ objectPosition: "center" }}
                          title="Pragmatic Hunt"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            </div>
          </div>
        </div>
      </div>

      {/* Games Filter */}
      <div className="games-filters container d-flex svelte-1ba933x">
        <div className="d-flex gap-8">
          <div className="filter-dropdown svelte-8o69b0">
            <div className="handle svelte-8o69b0">
              <div className="icons">
                <img
                  slot="icon"
                  src={props.images['provider-filter.1190ad14.svg']}
                  alt="providers"
                  className="svelte-1ba933x"
                />
              </div>
              <div className="weight-600">Providers</div>{" "}
              <svg
                className="down svelte-1m58f2p"
                viewBox="0 0 12 12"
                width="20"
                height="20"
                fill="none"
              >
                <title>Arrow down</title>
                <path
                  d="M9.5 4.5L6 8L2.5 4.5"
                  stroke="#E0E0E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ fill: "none!important" }}
                ></path>
              </svg>
            </div>
          </div>
          <div className="filter-dropdown svelte-8o69b0">
            <div className="handle svelte-8o69b0">
              <div className="icons">
                <img
                  slot="icon"
                  src={props.images['type-filter.5e54ab4f.svg']}
                  alt="providers"
                  className="svelte-1ba933x"
                />
              </div>
              <div className="weight-600">Game types</div>{" "}
              <svg
                className="down svelte-1m58f2p"
                viewBox="0 0 12 12"
                width="20"
                height="20"
                fill="none"
              >
                <title>Arrow down</title>
                <path
                  d="M9.5 4.5L6 8L2.5 4.5"
                  stroke="#E0E0E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ fill: "none!important" }}
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="input-wrapper undefined svelte-m2q48g">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="#fff"
          >
            <path
              d="M1557.558,48.559a1.5,1.5,0,0,1-2.125,0l-2.651-2.652a7.048,7.048,0,1,1,2.125-2.125l2.652,2.652A1.5,1.5,0,0,1,1557.558,48.559ZM1549.011,35a5.008,5.008,0,1,0,5.008,5.008A5.008,5.008,0,0,0,1549.011,35Z"
              transform="translate(-1541.999 -33)"
            ></path>
          </svg>{" "}
          <input placeholder="Search" className="svelte-m2q48g" />
        </div>
      </div>

      <div className="container ms-auto me-auto title svelte-ptg4pu" id="games">
        All Crypto Games
      </div>

      {/* Games List */}
      <div className="games-filtered container ms-auto me-auto svelte-ptg4pu">

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['c22fa79e0af943babf776de83b5c6dc5.webp']}
                alt="Wins of Mermaid Multi Power"
                className="image svelte-l3byt6"
                style={{}}
                title="Wins of Mermaid Multi Power"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['988657d36b14438db6666b17b6253ca3.webp']}
                alt="Valentine&#39;s Heart"
                className="image svelte-l3byt6"
                style={{}}
                title="Valentine&#39;s Heart"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['fa5ab0c2d0f447399a79baaa66cd68ff.webp']}
                alt="Hot Hot Summer"
                className="image svelte-l3byt6"
                style={{}}
                title="Hot Hot Summer"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['51f00d29446d4548bbe8a20da7a7f75e.webp']}
                alt="Rise and Fall of Ra"
                className="image svelte-l3byt6"
                style={{}}
                title="Rise and Fall of Ra"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['713e0c5a53a34fc2a36e5f069db0f4ae.webp']}
                alt="Hearts Highway"
                className="image svelte-l3byt6"
                style={{}}
                title="Hearts Highway"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['14f3a54763004ec09f260b76448cf00e.webp']}
                alt="The Wild Kiss"
                className="image svelte-l3byt6"
                style={{}}
                title="The Wild Kiss"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['739c3aa692c24a82a7e739598fd8c772.webp']}
                alt="Candy Glyph"
                className="image svelte-l3byt6"
                style={{}}
                title="Candy Glyph"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['c1321b2f34b746a7a88d7ddf65cc072f.webp']}
                alt="Midnight Thirst"
                className="image svelte-l3byt6"
                style={{}}
                title="Midnight Thirst"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['2ea3a9a000a04e48b258a08a3f2ad797.webp']}
                alt="1 Reel - Medusa"
                className="image svelte-l3byt6"
                style={{}}
                title="1 Reel - Medusa"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['248b22cb873a4d1fbb297a6afa0b617d.webp']}
                alt="Hot As Devil: Hold &#39;N&#39; Link"
                className="image svelte-l3byt6"
                style={{}}
                title="Hot As Devil: Hold &#39;N&#39; Link"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['e15cd5fce62b453095ddc3d78c2aaaf6.webp']}
                alt="Big Bass Floats my Boat"
                className="image svelte-l3byt6"
                style={{}}
                title="Big Bass Floats my Boat"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['824a477389c54024ab9db42fba4ddfdc.webp']}
                alt="Golden Fish"
                className="image svelte-l3byt6"
                style={{}}
                title="Golden Fish"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['d23547c664814507aaff9abbcd8abe49.webp']}
                alt="Oriental Dragon"
                className="image svelte-l3byt6"
                style={{}}
                title="Oriental Dragon"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['7b0f34c0ffd3492d94affbb75d7dcd05.webp']}
                alt="Urban Neon"
                className="image svelte-l3byt6"
                style={{}}
                title="Urban Neon"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['838fc368a4cb4026bc4f8f820c4fea7c.webp']}
                alt="Cat Wilde and the Incan Quest"
                className="image svelte-l3byt6"
                style={{}}
                title="Cat Wilde and the Incan Quest"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['d8449c72806b4241943ecd9f2100c4c0.webp']}
                alt="Pop Zen"
                className="image svelte-l3byt6"
                style={{}}
                title="Pop Zen"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['28bd4e548a7e4641b852c213b7227b2e.webp']}
                alt="Mardi Gras Fortunes"
                className="image svelte-l3byt6"
                style={{}}
                title="Mardi Gras Fortunes"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        
        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['aa9c4eaa251e40ed946376a9aebd5331.webp']}
                alt="The Alter Ego"
                className="image svelte-l3byt6"
                style={{}}
                title="The Alter Ego"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['ccc1717adbbf41489efc0c13cd3a2ced.webp']}
                alt="Sweet Lotto"
                className="image svelte-l3byt6"
                style={{}}
                title="Sweet Lotto"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['5f27a477a1db4d57a0ce66500363d73a.webp']}
                alt="Legacy of the Sages"
                className="image svelte-l3byt6"
                style={{}}
                title="Legacy of the Sages"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['f867f8ff68ff434f92ca812b6bcfaef8.webp']}
                alt="Redline Rush"
                className="image svelte-l3byt6"
                style={{}}
                title="Redline Rush"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['0f0cc2c79b504501b4cac5d757c00824.webp']}
                alt="Money Cart 4"
                className="image svelte-l3byt6"
                style={{}}
                title="Money Cart 4"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['031ca536fc914a64b7df9bb6d26e0d15.webp']}
                alt="Dragon King Megaways"
                className="image svelte-l3byt6"
                style={{}}
                title="Dragon King Megaways"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['1ae9264356da42cabc8402ca73159518.webp']}
                alt="Holidays Joker - Valentines"
                className="image svelte-l3byt6"
                style={{}}
                title="Holidays Joker - Valentines"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['d624f0bcf9ed41efb85c247666e1f472.webp']}
                alt="Trees of Treasure"
                className="image svelte-l3byt6"
                style={{}}
                title="Trees of Treasure"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['ef3b03d41cf6492f8289c6ec6484c1a8.webp']}
                alt="Golden Dragon: Hold &#39;N&#39; Link"
                className="image svelte-l3byt6"
                style={{}}
                title="Golden Dragon: Hold &#39;N&#39; Link"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['62b4f591d73f4d7d857e35b9fc279050.webp']}
                alt="Fruit Express"
                className="image svelte-l3byt6"
                style={{}}
                title="Fruit Express"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['6413ccf5b71e4745a84cfd48869c495a.webp']}
                alt="Heimdall&#39;s Gate Cash Quest"
                className="image svelte-l3byt6"
                style={{}}
                title="Heimdall&#39;s Gate Cash Quest"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['5dd878a656934068a01ece0fd065bebe.webp']}
                alt="4 Fantastic Fish GigaBlox"
                className="image svelte-l3byt6"
                style={{}}
                title="4 Fantastic Fish GigaBlox"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['6a9bda75796540b0aa48f03bdab30c9e.webp']}
                alt="Love is in the Fair"
                className="image svelte-l3byt6"
                style={{}}
                title="Love is in the Fair"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['4c6d67125277446ab506905c7f782647.webp']}
                alt="Temple of Thunder II Bonus Buy"
                className="image svelte-l3byt6"
                style={{}}
                title="Temple of Thunder II Bonus Buy"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['8e3daa31232d47e4b9f14696aa9e4194.webp']}
                alt="777 Super Strike"
                className="image svelte-l3byt6"
                style={{}}
                title="777 Super Strike"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['69bb9d98a9f84776b414c066eefa9353.webp']}
                alt="Aztec Clusters"
                className="image svelte-l3byt6"
                style={{}}
                title="Aztec Clusters"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['e307de9be66047fba467c44a96fa9963.webp']}
                alt="Classic Fantastic"
                className="image svelte-l3byt6"
                style={{}}
                title="Classic Fantastic"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['4535c55fe615473499e5c0e3a142d39a.webp']}
                alt="Wild Dragon Riches"
                className="image svelte-l3byt6"
                style={{}}
                title="Wild Dragon Riches"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['5721af6a0cee4cf09c2b94fe075f37e2.webp']}
                alt="Red Hot Luck"
                className="image svelte-l3byt6"
                style={{}}
                title="Red Hot Luck"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['c5886bd2202042a1b29f08997adbc56c.webp']}
                alt="Buckshot Wilds"
                className="image svelte-l3byt6"
                style={{}}
                title="Buckshot Wilds"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['a7d4d1060ae04c08b2aaad6c37b8a28a.webp']}
                alt="Valentine Monchy"
                className="image svelte-l3byt6"
                style={{}}
                title="Valentine Monchy"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['2395724eb910490bb9a00c97ef2bf819.webp']}
                alt="Dragon&#39;s Lucky 25"
                className="image svelte-l3byt6"
                style={{}}
                title="Dragon&#39;s Lucky 25"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['fa29977fc7ea4768b6dce69e38a3bdb3.webp']}
                alt="2024 Hit Slot"
                className="image svelte-l3byt6"
                style={{}}
                title="2024 Hit Slot"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['a73918800fc44778b39414ea5aad8f68.webp']}
                alt="Golden Furong"
                className="image svelte-l3byt6"
                style={{}}
                title="Golden Furong"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['33a1d74cbd044c7383aeeb7ba6170191.webp']}
                alt="Jade Coins"
                className="image svelte-l3byt6"
                style={{}}
                title="Jade Coins"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['4a96d6e4c7aa4f1ca8c3f880bc9a75e0.webp']}
                alt="Rockstar: World Tour - Hold &amp; Win"
                className="image svelte-l3byt6"
                style={{}}
                title="Rockstar: World Tour - Hold &amp; Win"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['744a5cac5ce845a0831e8c9d123bc0b0.webp']}
                alt="Gold Blitz Extreme"
                className="image svelte-l3byt6"
                style={{}}
                title="Gold Blitz Extreme"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['821ac0e3eee34892bb1b1576930636ab.webp']}
                alt="Mighty Empire: Hold &amp; Win"
                className="image svelte-l3byt6"
                style={{}}
                title="Mighty Empire: Hold &amp; Win"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['757b2c0351e347079ee0a7e974c05782.webp']}
                alt="Diamonds Power: Hold and Win"
                className="image svelte-l3byt6"
                style={{}}
                title="Diamonds Power: Hold and Win"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['3d87e99b92ca49408c6152e6ef4e5fbb.webp']}
                alt="Gus&#39;s Gold: Minecart Mayhem"
                className="image svelte-l3byt6"
                style={{}}
                title="Gus&#39;s Gold: Minecart Mayhem"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['3183f9db9c194b26a09b5e3ab4676300.webp']}
                alt="Book of Loki - Master of Illusions"
                className="image svelte-l3byt6"
                style={{}}
                title="Book of Loki - Master of Illusions"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['77eed4c93ff3498fb2622ec68a4a7466.webp']}
                alt="Hot Fruits Wheel"
                className="image svelte-l3byt6"
                style={{}}
                title="Hot Fruits Wheel"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['be466c922edd43d8a5913ca73e5dd5e1.webp']}
                alt="Moon Princess: Power of Love"
                className="image svelte-l3byt6"
                style={{}}
                title="Moon Princess: Power of Love"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['4b587be5e1bc4faab700b8553d3bdedc.webp']}
                alt="Blazing Wins: 5 Lines"
                className="image svelte-l3byt6"
                style={{}}
                title="Blazing Wins: 5 Lines"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['d0ac0dac3d3c4e35953ca15548907db7.webp']}
                alt="Pirate Respins"
                className="image svelte-l3byt6"
                style={{}}
                title="Pirate Respins"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['177b2209097c431a9d23441fbe6f5989.webp']}
                alt="Winter Star Bonus Buy"
                className="image svelte-l3byt6"
                style={{}}
                title="Winter Star Bonus Buy"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['905e01454a2140a08efd5c6df5dec7ad.webp']}
                alt="Tiki Runner Gigablox"
                className="image svelte-l3byt6"
                style={{}}
                title="Tiki Runner Gigablox"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['f7a3847147594a4399b5bb25557c04ac.webp']}
                alt="Wild Heart"
                className="image svelte-l3byt6"
                style={{}}
                title="Wild Heart"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['123b40ed0c4647ebbae36e7138d69c1d.webp']}
                alt="Pirates Party"
                className="image svelte-l3byt6"
                style={{}}
                title="Pirates Party"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['6ba69ed530ab40b2b512c3c79438144e.webp']}
                alt="Book Of Demi Gods V"
                className="image svelte-l3byt6"
                style={{}}
                title="Book Of Demi Gods V"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['db33000dea3e48f2bc6652d1c92a6d0b.webp']}
                alt="Loki&#39;s Riches"
                className="image svelte-l3byt6"
                style={{}}
                title="Loki&#39;s Riches"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['eecc773350af4ce0aaeaef699596990b.webp']}
                alt="Mining Pots of Gold"
                className="image svelte-l3byt6"
                style={{}}
                title="Mining Pots of Gold"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['f06031378a6e48bba4bef1bea5812322.webp']}
                alt="Re Kill Ultimate"
                className="image svelte-l3byt6"
                style={{}}
                title="Re Kill Ultimate"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['f3957c40a3874747aef901f47c92923c.webp']}
                alt="Shining Royal 100"
                className="image svelte-l3byt6"
                style={{}}
                title="Shining Royal 100"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['8f503fd3af0445249ee37f3dfa6b1f80.webp']}
                alt="Good Luck &amp; Good Fortune"
                className="image svelte-l3byt6"
                style={{}}
                title="Good Luck &amp; Good Fortune"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['875bd84b472942de98cd6bb0df68eff7.webp']}
                alt="1 Reel - Classic"
                className="image svelte-l3byt6"
                style={{}}
                title="1 Reel - Classic"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['cdd464fdabe44707bc3ec7781c4ae422.webp']}
                alt="Ranch Life"
                className="image svelte-l3byt6"
                style={{}}
                title="Ranch Life"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['2fe7f4571e684cdb98bd6839f05cb036.webp']}
                alt="Ninjaz vs Narcos"
                className="image svelte-l3byt6"
                style={{}}
                title="Ninjaz vs Narcos"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['278e0ed184474c36898a73b6c12e8435.webp']}
                alt="3 Clown Monty II"
                className="image svelte-l3byt6"
                style={{}}
                title="3 Clown Monty II"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['880a873c021e4af1baab459c83fe1883.webp']}
                alt="King Arthur 10K Ways"
                className="image svelte-l3byt6"
                style={{}}
                title="King Arthur 10K Ways"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['f2a978dd97b14db0ad8f097519e02091.webp']}
                alt="Jelly Belly Megaways"
                className="image svelte-l3byt6"
                style={{}}
                title="Jelly Belly Megaways"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['c26b67b08b674cdeb828c5b53d74914c.webp']}
                alt="Power Crown: Hold and Win"
                className="image svelte-l3byt6"
                style={{}}
                title="Power Crown: Hold and Win"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['73d59f446e5549949f4bbc3e7733f433.webp']}
                alt="God of Wealth Hold And Win"
                className="image svelte-l3byt6"
                style={{}}
                title="God of Wealth Hold And Win"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['54a7ad04239d47fa9d78183e572849a0.webp']}
                alt="Story Of Zeus"
                className="image svelte-l3byt6"
                style={{}}
                title="Story Of Zeus"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['d0d37ed074c7447fb591133425942ed4.webp']}
                alt="Blazing Wilds Megaways"
                className="image svelte-l3byt6"
                style={{}}
                title="Blazing Wilds Megaways"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="restriction-overlay svelte-1tenouv"></div>
          <div className="overlay svelte-1tenouv">
            <div className="restricted svelte-1tenouv">
              Restricted from your location
            </div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['9d74c1d4ffd1400d8667540d7d0711eb.webp']}
                alt="Hot Slot: 777 Rubies Extremely Light"
                className="image svelte-l3byt6"
                style={{}}
                title="Hot Slot: 777 Rubies Extremely Light"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['9526857b92c84773b571806fcb187f6a.webp']}
                alt="Fruity Mayan"
                className="image svelte-l3byt6"
                style={{}}
                title="Fruity Mayan"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['6416f377c1614e118fc044847aecddc2.webp']}
                alt="Temple of Thunder II"
                className="image svelte-l3byt6"
                style={{}}
                title="Temple of Thunder II"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['c14070cadb2b456ba29040fdc417da25.webp']}
                alt="Nice Catch 2 DoubleMax"
                className="image svelte-l3byt6"
                style={{}}
                title="Nice Catch 2 DoubleMax"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['52850f072ff94b19bf59e1c0b0cde466.webp']}
                alt="Astros"
                className="image svelte-l3byt6"
                style={{}}
                title="Astros"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['b47d16907a8848e4ab02a2869d1c51cd.webp']}
                alt="7s Fury 20"
                className="image svelte-l3byt6"
                style={{}}
                title="7s Fury 20"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['bfd672ee1e6e486ea51fa5c0c33287b3.webp']}
                alt="Year Of The Dragon King"
                className="image svelte-l3byt6"
                style={{}}
                title="Year Of The Dragon King"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['b826856a9ac341d98ffa2d7a50a2f7b3.webp']}
                alt="Majestic Winter - Polar Adventures"
                className="image svelte-l3byt6"
                style={{}}
                title="Majestic Winter - Polar Adventures"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['2d877c84a8cd40d7877832d33c426144.webp']}
                alt="Blessed Dragons Hold and Win"
                className="image svelte-l3byt6"
                style={{}}
                title="Blessed Dragons Hold and Win"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['054f8ac5f16443b59e1c54c0a82a31f6.webp']}
                alt="Big Catch Bonanza: Bonus Buy"
                className="image svelte-l3byt6"
                style={{}}
                title="Big Catch Bonanza: Bonus Buy"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['2d469879ef124e4daa086c2077315419.webp']}
                alt="WondeReels"
                className="image svelte-l3byt6"
                style={{}}
                title="WondeReels"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['1cf3399b5257425e91e5bd6168adfb7f.webp']}
                alt="Sidewinder DoubleMax"
                className="image svelte-l3byt6"
                style={{}}
                title="Sidewinder DoubleMax"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['f9f38137e39b449b8b9e9b9a9a48533b.webp']}
                alt="Vault Cracker Megaways"
                className="image svelte-l3byt6"
                style={{}}
                title="Vault Cracker Megaways"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['e0b60d94b6704471a659cfbf809176ca.webp']}
                alt="Pandastic Adventure"
                className="image svelte-l3byt6"
                style={{}}
                title="Pandastic Adventure"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['da97a6bc71064505b585de9bf6df04b5.webp']}
                alt="Castle of Fire"
                className="image svelte-l3byt6"
                style={{}}
                title="Castle of Fire"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['65afe68577744fde9afadf431e00a64c.webp']}
                alt="Dracula - Unleashed"
                className="image svelte-l3byt6"
                style={{}}
                title="Dracula - Unleashed"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['dca1c16e724249ebb11bf2fba8693a3c.webp']}
                alt="Lucky Adam"
                className="image svelte-l3byt6"
                style={{}}
                title="Lucky Adam"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['d7c00cb2399947aebe550129ca6ac11f.webp']}
                alt="Medusa Hunt"
                className="image svelte-l3byt6"
                style={{}}
                title="Medusa Hunt"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['abcada77fd354c2ba0655c40957818a2.webp']}
                alt="Mata Hari: the spy"
                className="image svelte-l3byt6"
                style={{}}
                title="Mata Hari: the spy"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['0ba51f902a1f4abc822bbddbd84ebae5.webp']}
                alt="Million Dracula 2"
                className="image svelte-l3byt6"
                style={{}}
                title="Million Dracula 2"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['62568dbe44d641caae5a55884eb3a189.webp']}
                alt="Twin Harlequin"
                className="image svelte-l3byt6"
                style={{}}
                title="Twin Harlequin"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['3ca6a175eda1416093db21ab1e3021ea.webp']}
                alt="Tommy Gun´s Vendetta"
                className="image svelte-l3byt6"
                style={{}}
                title="Tommy Gun´s Vendetta"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['4f8c52fbfb074551ace2013e1d04d406.webp']}
                alt="Secrets of the Temple"
                className="image svelte-l3byt6"
                style={{}}
                title="Secrets of the Temple"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="game-item-large svelte-1tenouv">
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ '--borderRadius': "10px", width: "100%", height: "100%" }}
            >
              <img
                src={props.images['9730615d2f444fc9b1110fc2dd487a44.webp']}
                alt="PARROT BAY"
                className="image svelte-l3byt6"
                style={{}}
                title="PARROT BAY"
                loading="lazy"
              />
            </div>
          </div>
        </div>

      </div>

      <div className="load-more  svelte-ptg4pu">
        <div className="counter svelte-ptg4pu">Displaying 96 of 3439 games</div>
        <div className=" load-more--btn svelte-ptg4pu">Load More</div>
      </div>
      
      {/* Content Learn more */ }
      <CasinoContent images={props.images} />

    </main>
  );
};
export default Casino;
