import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";

import Casino from "./pages/Casino/Casino";
import LiveCasino from "./pages/LiveCasino/LiveCasino";
import Footer from "./components/Footer/Footer";
import ProvablyFair from "./pages/ProvablyFair";
import Promotions from "./pages/Promotions";
import Blog from "./pages/Blog/Blog";
import BlogMore from "./pages/Blog/BlogReadMore";
import Tournaments from "./pages/Tournaments";
import Loyalty from "./pages/Loyalty";
import VIP from "./pages/VIP";
import Payment from "./pages/Payment/Payment";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SportTAndC from "./pages/SportTAndC";
import ProhibitedTerritories from "./pages/ProhibitedTerritories";
import BonusTAndC from "./pages/BonusTAndC";
import GamesExcludedFromBonus from "./pages/GamesExcludedFromBonus";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Aside from "./components/Aside";
import Header from "./components/Header/Header";
import Profile from "./pages/Profile/Profile";
import Bonuses from "./pages/Profile/Bonuses";
import Deposit from "./pages/Profile/Deposit";
import PageNotFound from "./pages/PageNotFound";
import FastGames from "./pages/FastGames/FastGames";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail";
import BottomMenu from "./components/BottomMenu";
import Iframe from "./pages/Iframe";
import Home from "./pages/Home/Home";
import Search from "./pages/Search/Search";
import Up10x from "./pages/Up10X/Up10x";
import Withdrawal from "./components/Withdrawal";
import Inbox from "./pages/Profile/Inbox";
import Transactions from "./pages/Profile/Transactions";
import Security from "./pages/Profile/Security";
import TonCoin from "./pages/Payment/TonCoin";
import Plinko from "./pages/Plinko/Plinko";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("./assets", false, /\.(png|jpe?g|svg|avif|webp)$/)
);

// Scroll To Top
function Wrapper({ children }) {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

function RouterComponent() {
  const { user, isAuthenticated, logoutUser } = useContext(AuthContext);

  //const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    console.log(isAuthenticated + " and User: " + user);
  }, [isAuthenticated]);

  return (
    <div id="app">
      <div className="default svelte-ulvgrp">
        <Wrapper>
          <Aside images={images} />
          <div className="content svelte-ulvgrp">
            <Header images={images} />
            <Routes>
              {/* Header Menu */}
              <Route index path="/" element={<Home images={images} />} />
              <Route path="casino" element={<Casino images={images} />} />
              <Route path="live-casino" element={<LiveCasino images={images} />} />
              <Route path="provably-fair" element={<ProvablyFair images={images} />} />
              <Route path="fast-games" element={<FastGames images={images} />} />
              <Route path="up-10x" element={<Up10x images={images} />} />
              <Route path="plinko" element={<Plinko images={images} />} />
              <Route path="iframe" element={<Iframe images={images} />} />
              {/*<Route path="sports" element={<Sports images={images} />}/>*/}

              {/* Authentication */}
              <Route path="login" element={<Login images={images} />} />
              <Route path="register" element={<Register images={images} />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="verify/:id" element={<VerifyEmail />} />

              <Route path="tournaments" element={<Tournaments images={images} />} />
              <Route path="promotions" element={<Promotions images={images} />} />
              <Route path="promotions/loyalty" element={ isAuthenticated === true ? (<Loyalty images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="promotions/vip" element={ isAuthenticated === true ? (<VIP images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="blog" element={<Blog images={images} />} />
              <Route path="blog-read-more" element={<BlogMore images={images} />} />
              <Route path="payment" element={<Payment images={images} />} />

              <Route path="faq/terms-and-conditions" element={<TermsAndConditions images={images} />} />
              <Route path="privacy-policy" element={<PrivacyPolicy images={images} />} />
              <Route path="sporttAndc" element={<SportTAndC images={images} />} />
              <Route path="ProhibitedTerritories" element={<ProhibitedTerritories images={images} />} />
              <Route path="BonusTAndC" element={<BonusTAndC images={images} />} />
              <Route path="GamesExcludedFromBonus" element={<GamesExcludedFromBonus images={images} />} />

              {/* Profile */}
              <Route path="dashboard/profile" element={ isAuthenticated === true ? (<Profile images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="dashboard/bonuses" element={ isAuthenticated === true ? (<Bonuses images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="dashboard/search"  element={ isAuthenticated === true ? (<Search  images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="dashboard/inbox"   element={ isAuthenticated === true ? (<Inbox   images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="dashboard/transactions" element={ isAuthenticated === true ? (<Transactions images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="dashboard/security"   element={ isAuthenticated === true ? (<Security   images={images} />) : (<Navigate to="/login" replace />) } />

              {/* Deposit/Withdrawal
              <Route path="dashboard/deposit"    element={ isAuthenticated === true ? (<Deposit    images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="dashboard/withdrawal" element={ isAuthenticated === true ? (<Withdrawal images={images} />) : (<Navigate to="/login" replace />) } />
              <Route path="toncoin" element={<TonCoin />} />*/}

              {/* 404 */}
              <Route path="*" element={<PageNotFound images={images} />} />
            </Routes>

            <Footer images={images} />
          </div>
          <BottomMenu images={images} />
        </Wrapper>
      </div>
    </div>
  );
}

export default RouterComponent;
