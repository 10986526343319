import { Link } from "react-router-dom";

const Payment = (props) => {
  return (
    <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">
      <div className="container ms-auto me-auto">
        <div className="currency-page svelte-1cizpui">
          <div className="currencies-block hide-scrollbar svelte-asy6i6">
            <div className="block svelte-asy6i6">
              <div className="item active svelte-asy6i6">
                <img
                  src={props.images["5072b0f801224a6990d44e76c6fddbb9.svg"]}
                  alt="Bitcoin"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Bitcoin</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["9f891487d3eb4b3b8dcac816fbe93953.svg"]}
                  alt="Tether"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Tether</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["720bdf8f98074988ad927a2a1673b98d.svg"]}
                  alt="Ethereum"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Ethereum</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["596b0a59a54945b5bc86caa6ee797f68.svg"]}
                  alt="Tron"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Tron</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["d160d3071f46472597104ce1a68e9038.svg"]}
                  alt="Litecoin "
                  className="img svelte-asy6i6"
                />{" "}
                <span>Litecoin </span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["c91f76c6b24648a0ad4286015347ece8.svg"]}
                  alt="Solana"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Solana</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["7892e260ae0c4db89692c82810e945ee.svg"]}
                  alt="Dogecoin"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Dogecoin</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["40004e50f8b44db8918ec5fdafb7bdca.svg"]}
                  alt="DASH"
                  className="img svelte-asy6i6"
                />{" "}
                <span>DASH</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["273aeb93dc854c1195c7a3b56b001f6f.svg"]}
                  alt="Zcash"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Zcash</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["92a6974006d1402d8eae8f3132b3605a.svg"]}
                  alt="USD Coin"
                  className="img svelte-asy6i6"
                />{" "}
                <span>USD Coin</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["565230535b8445ebbe869a1a955ff6be.svg"]}
                  alt="Binance"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Binance</span>
              </div>
              <div className="border svelte-asy6i6"></div>
              <div className="item  svelte-asy6i6">
                <img
                  src={props.images["c1487df6c91348ec8246fe67d64d8078.svg"]}
                  alt="Polygon"
                  className="img svelte-asy6i6"
                />{" "}
                <span>Polygon</span>
              </div>
              <div className="border svelte-asy6i6"></div>
            </div>
            <div className="terms-mobile svelte-asy6i6">
              <div className="terms-block svelte-asy6i6">
                <div className="item active svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["5072b0f801224a6990d44e76c6fddbb9.svg"]}
                      alt="Bitcoin"
                      className="img svelte-asy6i6"
                    />
                    <span>Bitcoin</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["9f891487d3eb4b3b8dcac816fbe93953.svg"]}
                      alt="Tether"
                      className="img svelte-asy6i6"
                    />
                    <span>Tether</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["720bdf8f98074988ad927a2a1673b98d.svg"]}
                      alt="Ethereum"
                      className="img svelte-asy6i6"
                    />
                    <span>Ethereum</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["596b0a59a54945b5bc86caa6ee797f68.svg"]}
                      alt="Tron"
                      className="img svelte-asy6i6"
                    />
                    <span>Tron</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["d160d3071f46472597104ce1a68e9038.svg"]}
                      alt="Litecoin "
                      className="img svelte-asy6i6"
                    />
                    <span>Litecoin </span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["c91f76c6b24648a0ad4286015347ece8.svg"]}
                      alt="Solana"
                      className="img svelte-asy6i6"
                    />
                    <span>Solana</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["7892e260ae0c4db89692c82810e945ee.svg"]}
                      alt="Dogecoin"
                      className="img svelte-asy6i6"
                    />
                    <span>Dogecoin</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["40004e50f8b44db8918ec5fdafb7bdca.svg"]}
                      alt="DASH"
                      className="img svelte-asy6i6"
                    />
                    <span>DASH</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["273aeb93dc854c1195c7a3b56b001f6f.svg"]}
                      alt="Zcash"
                      className="img svelte-asy6i6"
                    />
                    <span>Zcash</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["92a6974006d1402d8eae8f3132b3605a.svg"]}
                      alt="USD Coin"
                      className="img svelte-asy6i6"
                    />
                    <span>USD Coin</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["565230535b8445ebbe869a1a955ff6be.svg"]}
                      alt="Binance"
                      className="img svelte-asy6i6"
                    />
                    <span>Binance</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="terms-block svelte-asy6i6">
                <div className="item  svelte-asy6i6">
                  <div className="currency-details svelte-asy6i6">
                    <img
                      src={props.images["c1487df6c91348ec8246fe67d64d8078.svg"]}
                      alt="Polygon"
                      className="img svelte-asy6i6"
                    />
                    <span>Polygon</span>
                  </div>
                  <div className="arrow svelte-asy6i6">
                    <svg
                      className="icon icon-arrowLeft"
                      viewBox="0 0 12 12"
                      width="20"
                      height="20"
                      style={{
                        transition:
                          "transform 0.2s ease-in-out;transform: rotate(0deg)",
                      }}
                    >
                      <title>Arrow down</title>
                      <path
                        d="M9.5 4.5L6 8L2.5 4.5"
                        stroke="#E0E0E0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ fill: "none !important" }}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "relative", flex: "1 0 0px" }}>
            <div className="wrap svelte-1ea0vdb">
              <div className="currency-info svelte-1ea0vdb">
                <h2>What is Bitcoin?</h2>
                <p>
                  You know how cash is anonymous? You can take it out of an ATM,
                  hand it to anyone, and a transaction has been made. Bitcoin
                  (and cryptocurrencies in general) are a lot like that, but
                  digital. Every transaction made can be made anonymously, but
                  where a cash exchange may not leave a paper trail, every
                  Bitcoin transaction is also recorded. This ensures that any
                  and all disputes, any claims to ownership, can be quickly and
                  easily verified by independent parties with no stake in the
                  claim.
                </p>
                <p>
                  And you know how people mine for gold? Well, you can mine for
                  Bitcoin too, and it can be incredibly lucrative if you know
                  what you're doing and willing to take an investment risk.
                </p>
                <p>
                  But put simply, Bitcoin is a digital token that represents a
                  value, dictated independently of middlemen or governments, and
                  performed anonymously while presented publicly.
                </p>
                <p>&nbsp;</p>
                <h2>What is Bitcoin gambling?</h2>
                <p>
                  If you've ever played at a casino, be it traditional or
                  online, you have a pretty good understanding of Bitcoin
                  betting. The similar odds, the same strategies and gameplay,
                  and an enormous selection of games, it can all be enjoyed
                  using this cryptocurrency.
                </p>
                <p>
                  Just like with regular gambling, the idea of bitcoin gambling
                  is that you're risking a specific amount of money in the hopes
                  that you'll win that money back, plus an additional amount.
                  For example, if you play with Bitcoin on Roulette, it would be
                  a double or nothing win if you placed chips on Red.
                </p>
                <p>
                  The quality, fairness, and immersion of BTC gambling is
                  mesmerizing, and the number of options is pretty much
                  limitless. At UP-10X, for example, Bitcoin games range
                  from Slot machines like five-reel Mega Moolah and its
                  progressive jackpot, to table classics like Blackjack and
                  Craps. You can even bet on Live Casino games, where you can
                  bet with real dealers on real tables via a live stream. Talk
                  about digital cash!
                </p>
                <p>
                  UP-10X also pulled a card out of the Bitcoin transparency
                  book and introduced the Provably Fair system. It's where
                  players can see firsthand the true randomness of the game, and
                  even change things up to really ensure fairness. Learn
                  more&nbsp;<Link to="/provably">here</Link>.
                </p>
                <p>
                  For those of us who prefer fun over complex math equations,
                  gambling beats Bitcoin mining by a landslide.
                </p>
                <p>&nbsp;</p>
                <h2>Can bitcoin be turned into cash?</h2>
                <p>
                  Just like selling a stock (but easier and more
                  straightforward), you can easily sell your Bitcoin in an
                  exchange for real money. Whatever currency you prefer as well.
                  There are some fees that are a bit steeper than they would be
                  than standard Bitcoin transactions, but if the market hits a
                  high point and you're looking to cash out, it's as easy as
                  Google searching "Bitcoin to [your currency of choice]."
                  Verify that it's a legitimate website, and you'll have a
                  fatter bank account in no time.
                </p>
                <p>
                  Your digital coins can easily be exchanged for physical coins.
                  Don't forget your coin belt!
                </p>
                <p>&nbsp;</p>
                <h2>What else should you know about?</h2>
                <p>
                  From here, really diving into the Bitcoin gambling community
                  will be up to you. Go forth, it can be very rewarding. We
                  recommend digging into Bitcoin's scalability issue, how more
                  than 25% of Bitcoins have been lost for good, the big Bitcoin
                  hack of 2016, and more. It can take up a lot of your time, but
                  it can also fill up a lot of your Bitcoin wallet (and,
                  subsequently, your financial worth.)
                </p>
                <p>
                  Bitcoin may be the first and most well-known cryptocurrency,
                  but there are more. Take a look at our various pages covering
                  some of the most common, and please reach out to UP-10X's
                  friendly and knowledgeable support staff for any and all
                  questions or clarifications.
                </p>
                <p>
                  <i>Good luck, have fun, and may good fortune be with you!</i>
                </p>
              </div>
              <div className="gradient svelte-1ea0vdb"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Payment;
