import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";

const Register = (props) => {
  const { user, registerUser, isAuthenticated } = useContext(AuthContext);

  const [passwordShown, setPasswordShown] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: "First Name",
    lastName: "Last Name",
    dateOfBirth: "01/01/1980",
    email: "",
    country: "Cyprus",
    countryCode: "+357",
    city: "Nicosia",
    address: "Nicosia",
    mobileNumber: "357112233",
    userName: "",
    currency: "EUR",
    password: "",
    passwordVerify: "",
    receive_all_promos: true,
    agreeTermsConditions: false,
    zipCode: "0000",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Checking input data is empty or not
    if (
      !formValue.firstName ||
      !formValue.lastName ||
      !formValue.dateOfBirth ||
      !formValue.email ||
      !formValue.country ||
      !formValue.countryCode ||
      !formValue.city ||
      !formValue.address ||
      !formValue.mobileNumber ||
      !formValue.userName ||
      !formValue.currency ||
      !formValue.password ||
      !formValue.passwordVerify ||
      !formValue.receiveAllPromos ||
      !formValue.agreeTermsConditions
    ) {
      console.log("Please add all required fields");
    }

    // Checking password is matching or not
    if (formValue.password !== formValue.passwordVerify) {
      //toast.error("Password does not match.");
      return;
    }

    registerUser(formValue);
  };

  const {
    email,
    firstName,
    lastName,
    dateOfBirth,
    city,
    address,
    mobileNumber,
    userName,
    password,
    passwordVerify,
    currency,
    country,
    countryCode,
    receiveAllPromos,
    agreeTermsConditions,
    promoCode,
  } = formValue;

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    console.log("Register useEffect: ");
  }, [isAuthenticated]);

  return (
    <>
      <div className="backdrop svelte-1vfp6m0"></div>
      <div role="dialog" className="dialog">
        <div className="modal-blank svelte-vu0w7c">
          <div className="modal-sign svelte-k0joe5">
            <div className="banner-wrapper svelte-k0joe5">
              <div className="md-down-show-image">
                <div
                  className="wrapper svelte-l3byt6"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={props.images["signup-mobile.jpg"]}
                    alt="signup banner"
                    className="image svelte-l3byt6"
                    style={{}}
                    title="signup banner"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="md-up-show-image">
                <div
                  className="wrapper svelte-l3byt6"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={props.images["signup-banner.webp"]}
                    alt="signup banner"
                    className="image svelte-l3byt6"
                    style={{}}
                    title="signup banner"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="sign-container svelte-k0joe5">
              <div className="sign-header flex justify-between svelte-k0joe5">
                <div className="tabs svelte-mlvufa">
                  <input
                    type="radio"
                    id="radio-1"
                    name="tabs"
                    className="svelte-mlvufa"
                  />{" "}
                  <label
                    className="tab  signup-tab svelte-mlvufa"
                    htmlFor="radio-1"
                  >
                    Sign Up
                  </label>{" "}
                  <input
                    type="radio"
                    id="radio-2"
                    name="tabs"
                    className="svelte-mlvufa"
                  />{" "}
                  <label className="tab svelte-mlvufa" htmlFor="radio-2">
                    Sign In
                  </label>{" "}
                  <span className="active signup svelte-mlvufa"></span>
                </div>
              </div>

              {/* Close button */}
              <div className="close-button svelte-k0joe5">
                <Link to="/">
                  <button className="small primary nav-button svelte-1ld08ko">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4L4 12"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M4 4L12 12"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                </Link>
              </div>

              <form 
                onSubmit={handleSubmit} 
                autoComplete="off" 
                className="form svelte-ethfw2"
                >
                <div className="top-wrap svelte-ethfw2">

                  {/* Enter your email */}
                  <div className="mb-24 svelte-ethfw2">
                    <div className="full false false false svelte-12junve">
                      <div className="input-wrapper default svelte-12junve">
                        <input
                          placeholder=""
                          type="email"
                          inputMode="text"
                          autoComplete="off"
                          id="email"
                          name="email"
                          className="svelte-12junve"
                          value={email}
                          onChange={handleChange}
                        />{" "}
                        <span className="placeholder svelte-12junve">
                          Enter Your Email
                        </span>
                        <div className="left-icon svelte-12junve"></div>
                      </div>
                    </div>
                  </div>
                  
                  {/* Username */}
                  <div className="mb-24 svelte-ethfw2">
                    <div className="full false false false svelte-12junve">
                      <div className="input-wrapper default svelte-12junve">
                        <input
                          placeholder=""
                          type="text"
                          inputMode="text"
                          autoComplete="off"
                          id="userName"
                          name="userName"
                          className="svelte-12junve"
                          value={userName}
                          onChange={handleChange}
                        />{" "}
                        <span className="placeholder svelte-12junve">
                          Username
                        </span>
                        <div className="left-icon svelte-12junve"></div>
                      </div>
                    </div>
                  </div>

                  {/* Password */}
                  <div className="full false false false svelte-12junve">
                    <div className="input-wrapper default svelte-12junve">
                      <input
                        placeholder=""
                        type={passwordShown ? "text" : "password"}
                        inputMode="text"
                        autoComplete="off"
                        id="password"
                        name="password"
                        className="svelte-12junve"
                        value={password}
                        onChange={handleChange}
                      />{" "}
                      <span className="placeholder svelte-12junve">
                        Password
                      </span>
                      <div className="left-icon svelte-12junve"></div>{" "}
                      <img
                        onClick={togglePassword}
                        src={props.images["eyeIconClosed.056bb3c5.svg"]}
                        alt="eye-icon"
                        className="eye-icon svelte-12junve"
                      />
                    </div>
                  </div>
                  
                  {/* Verify Password */}
                  <div className="full false false false svelte-12junve" style={{ marginTop: "20px"}}>
                    <div className="input-wrapper default svelte-12junve">
                      <input
                        placeholder=""
                        type={passwordShown ? "text" : "password"}
                        inputMode="text"
                        autoComplete="off"
                        id="passwordVerify"
                        name="passwordVerify"
                        className="svelte-12junve"
                        required
                        value={passwordVerify}
                        onChange={handleChange}
                      />{" "}
                      <span className="placeholder svelte-12junve">
                        Confirm Password
                      </span>
                      <div className="left-icon svelte-12junve"></div>{" "}
                      <img
                        onClick={togglePassword}
                        src={props.images["eyeIconClosed.056bb3c5.svg"]}
                        alt="eye-icon"
                        className="eye-icon svelte-12junve"
                      />
                    </div>
                  </div>


                  <div className="promo-code-wrapper svelte-ethfw2">
                    <p className="svelte-ethfw2">Enter Promo Code</p>{" "}
                    <img
                      src={props.images["arrowDown.e5d4ef33.svg"]}
                      alt="arrow-down"
                      className="svelte-ethfw2"
                    />
                  </div>

                  <div className="terms svelte-ethfw2">
                    <div>
                      <label className="container svelte-1r2xmao">
                        <input 
                          type="checkbox" 
                          className="svelte-1r2xmao"
                          name="agreeTermsConditions"
                          value={agreeTermsConditions}
                          onChange={handleChange}
                          required
                          />{" "}
                        <span className="checkmark svelte-1r2xmao"></span>
                      </label>
                    </div>
                    <ul className="svelte-ethfw2">
                      <li className="svelte-ethfw2">I am 18+</li>
                      <li className="svelte-ethfw2">
                        I'm playing for myself using my own money.
                      </li>
                      <li className="svelte-ethfw2">
                        This is my first/only registration at{" "}
                        <span className="svelte-ethfw2">up-10x.com</span>
                      </li>
                      <li className="svelte-ethfw2">
                        I haven't banned myself from gambling sites in the past
                        year.
                      </li>
                      <li className="svelte-ethfw2">
                        I've read and accepted
                        <Link
                          to="/faq/terms_and_conditions"
                          target="_blank"
                          className="link svelte-ethfw2"
                        >
                          Terms &amp; Conditions
                        </Link>
                      </li>
                      <li className="svelte-ethfw2">
                        I confirm all my info is correct and verifiable.
                        Providing the wrong info will void my winnings.
                      </li>
                      <li className="svelte-ethfw2">
                        I know
                        <span className="svelte-ethfw2">up-10x.com</span>
                        has daily/weekly/monthly limits on winnings/withdrawals
                      </li>
                    </ul>
                  </div>

                  {/* Submit Button / Play Now */}
                  <div className="mt-12 mb-32 btn-wrap svelte-ethfw2">
                    <button
                      className="primary standart normal svelte-uia73q w-100"
                      type="submit"
                      style={{}}
                    >
                      {" "}
                      Play Now{" "}
                    </button>
                  </div>

                  {/* Sign Up Direct With */}
                  <div className="third-party-auth svelte-mtun1q">
                    <div className="title svelte-mtun1q">
                      Sign Up directly with
                    </div>{" "}
                    <button
                      className="secondary standart normal svelte-uia73q w-100"
                      type="button"
                      style={{}}
                    >
                      <img
                        src={props.images["google.webp"]}
                        alt="Authenticate with google"
                        className="svelte-mtun1q"
                      />
                      Google{" "}
                    </button>
                    <div className="d-none">
                      <div
                        className="S9gUrf-YoZ4jf"
                        style={{ position: "relative" }}
                      >
                        <div>
                          <div
                            tabIndex="0"
                            role="button"
                            aria-labelledby="button-label"
                            className="nsm7Bb-HzV7m-LgbsSe  hJDwNd-SxQuSe MFS4be-JaPV2b-Ia7Qfc MFS4be-Ia7Qfc JGcpL-RbRzK"
                            style={{
                              width: "400px",
                              maxWidth: "400px",
                              minWidth: "min-content",
                            }}
                          >
                            <div className="nsm7Bb-HzV7m-LgbsSe-MJoBVe"></div>
                            <div className="nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb ">
                              <div className="nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf">
                                <div className="nsm7Bb-HzV7m-LgbsSe-Bz112c">
                                  <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                    className="LgbsSe-Bz112c"
                                  >
                                    <g>
                                      <path
                                        fill="#EA4335"
                                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                      ></path>
                                      <path
                                        fill="#4285F4"
                                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                      ></path>
                                      <path
                                        fill="#FBBC05"
                                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                      ></path>
                                      <path
                                        fill="#34A853"
                                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                      ></path>
                                      <path
                                        fill="none"
                                        d="M0 0h48v48H0z"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                              <span className="nsm7Bb-HzV7m-LgbsSe-BPrWId">
                                Continue with Google
                              </span>
                              <span className="L6cTce" id="button-label">
                                Continue with Google
                              </span>
                            </div>
                          </div>
                        </div>
                        <iframe
                          src=""
                          allow="identity-credentials-get"
                          id="gsi_801732_943649"
                          title="Sign in with Google Button"
                          style={{
                            display: "block",
                            position: "relative",
                            top: "0px",
                            left: "0px",
                            height: "0px",
                            width: "0px",
                            border: "0px",
                          }}
                        ></iframe>
                      </div>
                    </div>
                  </div>

                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
