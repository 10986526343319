import { AuthContextProvider } from "./context/AuthContext";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// Style Import
import "./styles/1.a6d68746.css";
import "./styles/2.6d3d51ba.css";
import "./styles/4.a36708f6.css";
import "./styles/index.03e35c0e.css";
import "./styles/index.5d834a98.css";
import "./styles/index.a53c9663.css";
import "./styles/main.css";
import RouterComponent from "./RouterComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <RouterComponent />
    </AuthContextProvider>
  </BrowserRouter>
);
