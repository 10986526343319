const PromotionBlock = (props) => {
  return (
    <div className="promotions-block container ms-auto me-auto">
      <div className="s-wrapper position-relative swiper-wrapper-auto false svelte-12mba6b">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode swiper-backface-hidden">
          <div className="container-start svelte-12mba6b" slot="container-start">
            <div className="title svelte-12mba6b">
              Promotions{" "}
              <img
                src={props.images['right-arrow.ba864f8e.svg']}
                alt="arrow"
                className="svelte-12mba6b"
              />
            </div>
            <div className="action-buttons d-flex items-center svelte-12mba6b">
              <button
                className="prev27eb870e-4a24-4103-ba53-1686517b05f6 btn black svelte-12mba6b swiper-button-disabled"
                disabled=""
              >
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(90deg)",
                  }}
                >
                  <title>Arrow left</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>{" "}
              <button className="next27eb870e-4a24-4103-ba53-1686517b05f6 btn black svelte-12mba6b">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(270deg)",
                  }}
                >
                  <title>Arrow right</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            className="swiper-wrapper"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <div className="swiper-slide swiper-slide-active">
              <div className="promo position-relative svelte-vcrzdg">
                <div className="img-wrap svelte-vcrzdg">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "12px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['Get100FreeSpins.avif']}
                      alt="Get 100 Free Spins"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Get 100 Free Spins"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="info d-flex flex-column justify-start svelte-vcrzdg">
                  <div className="title svelte-vcrzdg">Get 100 Free Spins</div>
                  <div className="description svelte-vcrzdg">
                    Create Account, Get Bonus Instantly
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide swiper-slide-next">
              <div className="promo position-relative svelte-vcrzdg">
                <div className="img-wrap svelte-vcrzdg">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "12px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['WelcomePack.avif']}
                      alt="Welcome Pack"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Welcome Pack"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="info d-flex flex-column justify-start svelte-vcrzdg">
                  <div className="title svelte-vcrzdg">Welcome Pack</div>
                  <div className="description svelte-vcrzdg">
                    6 BTC or Unlimited 20% Cashback
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="promo position-relative svelte-vcrzdg">
                <div className="img-wrap svelte-vcrzdg">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "12px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['ae1660eb9709449b89fa23b4dc7eba4b.webp']}
                      alt="Join Tournaments"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Join Tournaments"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="info d-flex flex-column justify-start svelte-vcrzdg">
                  <div className="title svelte-vcrzdg">Join Tournaments</div>
                  <div className="description svelte-vcrzdg">
                    Join &amp; Win up to $10,000 Weekly
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="promo position-relative svelte-vcrzdg">
                <div className="img-wrap svelte-vcrzdg">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "12px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(52)"
                      alt="Free-to-Play Game"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Free-to-Play Game"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="info d-flex flex-column justify-start svelte-vcrzdg">
                  <div className="title svelte-vcrzdg">Free-to-Play Game</div>
                  <div className="description svelte-vcrzdg">
                    Predict Goalscorers to Win $10,000
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="promo position-relative svelte-vcrzdg">
                <div className="img-wrap svelte-vcrzdg">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "12px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(53)"
                      alt="Unbeatable Bonuses"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Unbeatable Bonuses"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="info d-flex flex-column justify-start svelte-vcrzdg">
                  <div className="title svelte-vcrzdg">Unbeatable Bonuses</div>
                  <div className="description svelte-vcrzdg">
                    Start Playing, Earn Points Instantly
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PromotionBlock;
