import { Link } from "react-router-dom";

const Aside = (props) => {
  return (
    <aside className="svelte-1c36wph">
      <div className="content aside-menu svelte-1c36wph">
        <div
          className="tooltip  svelte-1c36wph"
          style={{ top: "4.875px" }}
        ></div>
        <div className="handle svelte-1c36wph">
          {" "}
          <button className="hide-down-xl svelte-1c36wph">
            <svg
              width="28"
              height="14"
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 7H27"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9 1H27"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9 13H27"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M3.75 9.5L1.5 7.25L3.75 5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>
        <ul className="hide-down-xl svelte-1c36wph">
          <div>
            <Link to="/tournaments" className="header-link svelte-3b4r31">
              <div className="aside-icon svelte-3b4r31">
                <img
                  className="new svelte-3b4r31"
                  src={props.images["new.24e182c9.svg"]}
                  alt="New"
                />{" "}
                <svg
                  width="18"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512.000000 512.000000"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    stroke="none"
                  >
                    <path
                      d="M1083 5100 c-45 -27 -55 -52 -61 -151 l-5 -88 -366 -3 -366 -3 -60
-29 c-80 -40 -149 -111 -188 -194 l-32 -67 0 -385 c0 -409 2 -429 52 -585 141
-436 547 -746 1013 -772 l105 -6 47 -86 c165 -306 425 -545 731 -672 l92 -38
3 -370 2 -371 -384 0 c-394 0 -421 -2 -462 -41 -11 -11 -112 -249 -233 -553
-238 -595 -232 -572 -166 -644 l33 -37 1722 0 1722 0 33 37 c66 72 72 49 -166
644 -121 304 -222 542 -233 553 -41 39 -68 41 -462 41 l-384 0 2 371 3 370 91
39 c310 129 586 382 735 674 l43 83 100 6 c472 24 877 332 1019 772 50 156 52
176 52 585 l0 385 -32 67 c-39 83 -108 154 -188 194 l-60 29 -366 3 -366 3 -5
88 c-6 99 -16 124 -61 151 -31 19 -64 20 -1477 20 -1413 0 -1446 -1 -1477 -20z
m-63 -1260 l0 -510 -27 6 c-209 50 -386 218 -454 429 -21 64 -23 94 -27 328
l-4 257 256 0 256 0 0 -510z m3588 253 c-4 -252 -4 -260 -32 -338 -15 -44 -43
-105 -61 -136 -82 -134 -235 -246 -390 -285 l-25 -6 0 511 0 511 256 0 256 0
-4 -257z"
                      fill="white"
                      className="hoverable"
                    ></path>
                  </g>
                </svg>
              </div>
              <div className="svelte-3b4r31">Tournaments</div>
            </Link>{" "}
          </div>
          <div>
            <Link
              to="/promotions"
              className="header-link svelte-3b4r31"
            >
              <div className="aside-icon svelte-3b4r31">
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.1396 15.71L8.78961 15.04H8.22961L5.19961 15.03H5.18961C4.90961 15.03 4.62961 15.01 4.34961 14.95C4.41961 15.47 4.50961 16.05 4.54961 16.21C4.66961 16.82 4.87961 17.41 5.01961 18.01C5.12961 18.46 5.24961 18.9 5.54961 19.27C6.10961 19.97 6.85961 20.36 7.71961 20.37H7.76961C8.85961 20.37 9.72961 19.86 10.2996 18.88C10.7196 18.15 10.7496 17.38 10.5196 16.62C10.4296 16.31 10.2796 16.01 10.1396 15.71Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M18.7803 6.1499V12.6999C20.0503 11.8599 20.7203 10.7199 20.6603 9.2399C20.6003 7.9099 19.9703 6.8599 18.7803 6.1499Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M16.97 16.19V16.25C16.95 16.59 16.67 16.86 16.33 16.86C16.25 16.86 16.17 16.84 16.1 16.82C16.06 16.8 16.02 16.78 15.98 16.75C15.98 16.75 15.95 16.74 15.94 16.73C14.65 16.08 13.35 15.43 12.06 14.78L11.4 14.45L9.92 13.7L9.66 13.57L9.61 13.55L9.46 13.47C9.21 13.35 8.98 13.34 8.81 13.34H8.22H6.04C5.75 13.34 5.45 13.34 5.18 13.34C4.91 13.34 4.64 13.31 4.38 13.24H4.36C3.99 13.13 3.62 12.97 3.26 12.72C3.22 12.69 3.17 12.66 3.13 12.63C2.44 12.14 1.95 11.47 1.68 10.65C1.53 10.19 1.51 9.71 1.5 9.17C1.5 8.47 1.75 7.8 2.29 7.05C2.77 6.39 3.4 5.95 4.22 5.69C4.63 5.56 4.97 5.55 5.29 5.5H8.81C9.07 5.5 9.32 5.45 9.55 5.33L10.46 4.87C11.14 4.52 11.81 4.18 12.5 3.86C13.15 3.56 13.78 3.23 14.41 2.89C14.94 2.61 15.49 2.33 16.04 2.07C16.07 2.06 16.09 2.04 16.12 2.04C16.19 2.01 16.26 2 16.33 2C16.68 2 16.96 2.28 16.96 2.63V11.53C16.96 13.08 16.95 14.64 16.97 16.19Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                </svg>
              </div>
              <div className="svelte-3b4r31">Promotions</div>
            </Link>{" "}
          </div>
          <div>
            <Link
              to="/promotions/loyalty"
              className="header-link svelte-3b4r31"
            >
              <div className="aside-icon svelte-3b4r31">
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5388 20.5C17.2152 20.5 17.7736 19.9954 17.7736 19.3726V18.1701C17.7736 17.4829 17.1616 16.9246 16.41 16.9246H4.95351C4.27708 16.9246 3.71875 17.4292 3.71875 18.052V19.2545C3.71875 19.9417 4.33076 20.5 5.08236 20.5H16.5281H16.5388Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M3.44016 5.68282C3.7408 5.67208 4.00923 5.76871 4.26692 5.92977C4.99704 6.41294 5.74863 6.87463 6.48949 7.34706C6.63981 7.44369 6.77939 7.41149 6.86529 7.25043C7.04782 6.94979 7.21961 6.63842 7.40214 6.33778C7.74573 5.74724 8.10005 5.16744 8.42216 4.56616C8.61543 4.2011 8.84091 3.84678 9.02344 3.47098C9.13081 3.25624 9.3026 3.0415 9.42071 2.82675C9.58177 2.54759 9.7643 2.30064 10.0435 2.13958C10.2045 2.05369 10.3763 2 10.5696 2C10.9239 2 11.2675 2 11.5574 2.23622C11.7507 2.38654 11.8902 2.55833 12.0083 2.77307C12.7277 4.08299 13.4578 5.39292 14.188 6.70284C14.2846 6.88537 14.392 7.0679 14.4993 7.25043C14.6175 7.45444 14.6711 7.47591 14.8751 7.3578C15.6482 6.88537 16.4428 6.42368 17.2051 5.91903C18.0318 5.37144 19.2666 5.83313 19.4062 6.85316C19.4491 7.18601 19.3632 7.48664 19.2988 7.79802C19.1807 8.4315 19.0411 9.06499 18.9015 9.69848C18.7834 10.2568 18.6546 10.8151 18.5365 11.3842C18.4184 11.9425 18.311 12.5009 18.1822 13.0485C18.107 13.3706 18.0855 13.7034 17.9567 14.0148C17.7419 14.5409 17.3017 14.7771 16.7756 14.8952C16.7004 14.9167 16.6145 14.8845 16.5286 14.8845C12.6526 14.8845 8.77649 14.8845 4.9004 14.8845C4.13807 14.8845 3.59048 14.4443 3.42942 13.8752C3.30058 13.4565 3.22542 13.0162 3.13952 12.5868C3.04289 12.1358 2.95699 11.6848 2.86036 11.2339C2.71004 10.536 2.57046 9.83806 2.42014 9.14015C2.30203 8.59256 2.19466 8.05571 2.05508 7.51886C1.88329 6.84242 2.1195 6.24114 2.67783 5.87608C2.89257 5.7365 3.15026 5.68282 3.41869 5.69356L3.44016 5.68282Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                </svg>
              </div>
              <div className="svelte-3b4r31">Loyalty</div>
            </Link>{" "}
          </div>
          <div>
            <Link
              to="/promotions/vip"
              className="header-link svelte-3b4r31"
            >
              <div className="aside-icon svelte-3b4r31">
                {" "}
                <svg
                  width="22"
                  height="11"
                  viewBox="0 0 22 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_912_8304)">
                    <path
                      d="M9.45704 0L5.89286 10.4534H3.53002L0 0H2.81832L4.74845 6.75828L6.70135 0H9.45704Z"
                      fill="white"
                      className="hoverable"
                    ></path>
                    <path
                      d="M12.4633 0H9.84424V10.4534H12.4633V0Z"
                      fill="white"
                      className="hoverable"
                    ></path>
                    <path
                      d="M21.015 0.831263C20.3717 0.278986 19.5119 0 18.4529 0H13.8639V10.4534H16.4886V6.90062H18.2992C19.455 6.90062 20.366 6.63302 21.0093 6.11491C21.6641 5.57402 22 4.66874 22 3.41615C22 2.26605 21.6698 1.40062 21.015 0.831263ZM18.1284 4.62888H16.4886V2.28882H18.1284C18.544 2.28882 18.8685 2.37422 19.0792 2.55072C19.2899 2.71584 19.3867 3.00621 19.3867 3.42754C19.3867 3.84886 19.2842 4.16201 19.0792 4.33851C18.8629 4.53209 18.5383 4.62888 18.1284 4.62888Z"
                      fill="white"
                      className="hoverable"
                    ></path>
                  </g>
                  <defs>
                    <clippath id="clip0_912_8304">
                      <rect width="22" height="10.4534" fill="white"></rect>
                    </clippath>
                  </defs>
                </svg>
              </div>
              <div className="svelte-3b4r31">Vip</div>
            </Link>{" "}
          </div>

          {/*<div>
            <Link
              to="/promotions/majestic7"
              className="header-link svelte-3b4r31"
            >
              <div className="aside-icon svelte-3b4r31">
                {" "}
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.4747 0V13.9415H14.2918V18H0V4.02453H4.16553V0H18.4747Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M8.27892 5.21313C8.15691 5.29804 7.98262 5.45087 7.77348 5.65464C7.58176 5.85842 7.39003 6.06219 7.19832 6.28295C7.0066 6.5037 6.83231 6.70747 6.71031 6.89427C6.57087 7.06408 6.48373 7.18295 6.48373 7.21691V7.43766H10.0044L4.65369 12.7867H8.0872L15.9477 5.21313H8.27892Z"
                    fill="#1B1B1F"
                  ></path>
                </svg>
              </div>
              <div className="svelte-3b4r31">Majestic 7</div>
            </Link>{" "}
          </div>*/}

          <div>
            <Link
              to="/blog"
              className="header-link svelte-3b4r31"
            >
              <div className="aside-icon svelte-3b4r31">
                {" "}
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.76 2H16.35C17.8743 2 19.11 3.2357 19.11 4.76V17.71C19.11 19.2343 17.8743 20.47 16.35 20.47H5.76C4.23569 20.47 3 19.2343 3 17.71V4.76C3 3.2357 4.23569 2 5.76 2ZM5.50984 12.5099C5.42984 12.5699 5.33984 12.5999 5.24984 12.5999C5.11984 12.5999 5.01984 12.5599 4.94984 12.4799C4.87984 12.3999 4.83984 12.2799 4.83984 12.1299V5.0399C4.83984 4.6599 4.93984 4.3799 5.12984 4.1899C5.31984 3.9999 5.59984 3.8999 5.96984 3.8999H9.15984C9.52984 3.8999 9.80984 3.9999 9.99984 4.1899C10.1898 4.3799 10.2898 4.6599 10.2898 5.0399V12.1299C10.2898 12.2799 10.2498 12.3999 10.1798 12.4799C10.1098 12.5599 10.0098 12.5999 9.87984 12.5999C9.78984 12.5999 9.69984 12.5699 9.61984 12.5099C9.53984 12.4499 9.41984 12.3399 9.24984 12.1799L7.59984 10.5399C7.57984 10.5199 7.52984 10.5399 7.52984 10.5399L5.87984 12.1799C5.70984 12.3399 5.58984 12.4499 5.50984 12.5099Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                </svg>
              </div>
              <div className="svelte-3b4r31">Blog</div>
            </Link>{" "}
          </div>
          <div>
            <Link
              to="/payment"
              className="header-link svelte-3b4r31"
            >
              <div className="aside-icon svelte-3b4r31">
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.03868 15.3596C4.81805 15.3596 4.64756 15.2894 4.50716 15.159C4.36676 15.0186 4.30659 14.8481 4.30659 14.6476V13.2536C4.30659 13.043 4.37679 12.8725 4.50716 12.7421C4.64756 12.6117 4.81805 12.5416 5.03868 12.5416H6.87393C7.09456 12.5416 7.27507 12.6117 7.40545 12.7421C7.54585 12.8725 7.60602 13.043 7.60602 13.2536V14.6476C7.60602 14.8481 7.53582 15.0287 7.40545 15.159C7.27507 15.2894 7.09456 15.3596 6.87393 15.3596H5.03868ZM2.01003 9.62321V7.94843H19.49V9.62321H2.01003ZM4.30659 17.6562H17.2135C17.9756 17.6562 18.5473 17.4656 18.9284 17.0845C19.3095 16.7034 19.5 16.1418 19.5 15.3897V7.26647C19.5 6.51432 19.3095 5.95273 18.9284 5.57164C18.5473 5.19055 17.9756 5 17.2135 5H4.29656C3.53438 5 2.95272 5.19055 2.57163 5.57164C2.19054 5.9427 2 6.51432 2 7.26647V15.3897C2 16.1418 2.19054 16.7034 2.57163 17.0845C2.95272 17.4656 3.53438 17.6562 4.29656 17.6562H4.30659Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                </svg>
              </div>
              <div className="svelte-3b4r31">Payment options</div>
            </Link>{" "}
          </div>
          <div>
            <Link
              to="https://cnews.es/"
              target="_blank"
              rel="noopener noreferrer"
              className="header-link svelte-3b4r31"
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1348_8621)">
                  <path
                    d="M6.28516 11.8249C6.75837 12.0002 7.18776 12.2631 7.55581 12.6049L9.22081 9.73041C8.7476 9.54637 8.31821 9.28346 7.94139 8.95044L6.28516 11.8249Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M13.5324 8.95044C13.1643 9.28346 12.7349 9.54637 12.2617 9.72164L13.9267 12.6137C14.2948 12.2719 14.7242 12.009 15.1974 11.8337L13.5324 8.95044Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M16.7311 13.0256C16.4594 13.0256 16.1965 13.0607 15.9512 13.1396C15.4604 13.2798 15.0223 13.5602 14.6893 13.9371C14.2424 14.4278 13.9707 15.0763 13.9707 15.795C13.9707 17.3198 15.2063 18.5555 16.7311 18.5555C18.2559 18.5555 19.5002 17.3198 19.5002 15.795C19.5002 14.2701 18.2646 13.0256 16.7311 13.0256Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M13.4973 5.76056C13.4973 4.23568 12.2617 3 10.737 3C9.21217 3 7.97656 4.23568 7.97656 5.76056C7.97656 6.47919 8.24822 7.13646 8.7039 7.62723C9.0369 7.99531 9.48382 8.27574 9.97456 8.41596C10.2199 8.48607 10.4741 8.52113 10.737 8.52113C10.9998 8.52113 11.254 8.48607 11.4993 8.41596C11.9901 8.27574 12.437 7.99531 12.77 7.62723C13.2257 7.13646 13.4973 6.47919 13.4973 5.76056Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                  <path
                    d="M5.53155 13.1308C5.28618 13.0607 5.02329 13.0256 4.76039 13.0256C3.2356 13.0256 2 14.2701 2 15.795C2 17.3198 3.2356 18.5555 4.76039 18.5555C6.28518 18.5555 7.52078 17.3198 7.52078 15.795C7.52078 15.0763 7.24912 14.4191 6.79344 13.9283C6.46044 13.5602 6.02228 13.2798 5.53155 13.1308Z"
                    fill="white"
                    className="hoverable"
                  ></path>
                </g>
                <defs>
                  <clippath id="clip0_1348_8621">
                    <rect
                      width="17.5"
                      height="15.5556"
                      fill="white"
                      transform="translate(2 3)"
                    ></rect>
                  </clippath>
                </defs>
              </svg>
              <div className="svelte-3b4r31">Affliate</div>
            </Link>{" "}
          </div>
          <div>
            <Link
              to="http://www.responsiblegambling.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="header-link svelte-3b4r31"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.974 5.15997C18.744 4.24997 18.174 3.65998 17.294 3.41998C16.304 3.14998 15.294 2.96999 14.304 2.73999C13.404 2.52999 12.504 2.34997 11.604 2.12997C11.494 2.10997 11.394 2.09999 11.264 2.04999C11.154 2.01999 11.084 2 11.004 2C10.924 2 10.844 2.01999 10.734 2.04999C10.384 2.17999 10.024 2.22 9.664 2.31C9.114 2.45 8.564 2.55999 8.014 2.67999C7.184 2.85999 6.344 3.02997 5.524 3.21997C5.104 3.31997 4.674 3.37 4.284 3.59C3.564 4 3.124 4.59999 3.034 5.42999C2.984 5.91999 3.014 6.40999 3.014 6.89999C3.014 7.56999 3.044 8.24999 3.004 8.92999C2.984 9.40999 3.044 9.87998 3.074 10.35C3.114 10.9 3.244 11.44 3.384 11.97C3.734 13.28 4.324 14.49 5.104 15.6C5.584 16.29 6.154 16.9 6.774 17.46C7.534 18.14 8.374 18.71 9.284 19.16C9.764 19.39 10.244 19.64 10.774 19.71C10.854 19.72 10.934 19.73 11.024 19.73C11.344 19.73 11.664 19.64 11.974 19.52C12.724 19.2 13.444 18.83 14.114 18.37C14.594 18.04 15.054 17.69 15.464 17.29C16.364 16.42 17.144 15.45 17.724 14.33C18.194 13.44 18.554 12.51 18.764 11.53C18.894 10.96 19.004 10.38 19.004 9.78998C19.014 9.67998 19.044 8.88 19.044 8.84V5.69998C19.044 5.69998 19.004 5.28997 18.974 5.15997ZM15.504 8.65997L11.094 13.06C10.904 13.26 10.644 13.36 10.394 13.36C10.144 13.36 9.884 13.26 9.684 13.06L7.294 10.68C6.904 10.29 6.904 9.64998 7.294 9.25998C7.684 8.86998 8.324 8.86998 8.714 9.25998L10.394 10.94L14.084 7.25C14.474 6.86 15.114 6.86 15.504 7.25C15.894 7.64 15.894 8.26997 15.504 8.65997Z"
                  fill="white"
                  className="hoverable"
                ></path>
                <path
                  d="M10.464 12.2599L10.444 12.2799C10.444 12.2799 10.374 12.1999 10.334 12.1599L10.464 12.2599Z"
                  fill="white"
                  className="hoverable"
                ></path>
              </svg>
              <div className="svelte-3b4r31">Responsible gaming</div>
            </Link>{" "}
          </div>
        </ul>
      </div>
    </aside>
  );
};
export default Aside;
