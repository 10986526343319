import { useEffect, useState } from "react";
import { domain } from "../../config";

const ProvablyFairBlock = (props) => {
  const [games, setGames] = useState(null);

  useEffect(() => {
    fetch(
      `${domain()}/aggregator/games?provider=fiablegames&gameMode=demo&partnerId=1000&language=en&token=`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      //.then(data => console.log(data.data.result) )
      .then((data) => setGames(data.data.result.game_list))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="games-block container ms-auto me-auto swiper-wrapper-auto">
      <div className="s-wrapper position-relative swiper-wrapper-auto false svelte-12mba6b">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode">
          <div className="container-start svelte-12mba6b" slot="container-start" >

            <div className="title svelte-12mba6b">
              Provably Fair Games{" "}
              <img
                src={props.images["right-arrow.ba864f8e.svg"]}
                alt="arrow"
                className="svelte-12mba6b"
              />
            </div>

            <div className="action-buttons d-flex items-center svelte-12mba6b">
              <button
                className="prevc8a4ce49-96ef-4257-9b7f-5986a1b46872 btn svelte-12mba6b swiper-button-disabled"
                disabled=""
              >
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(90deg)",
                  }}
                >
                  <title>Arrow left</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>{" "}
              <button className="nextc8a4ce49-96ef-4257-9b7f-5986a1b46872 btn svelte-12mba6b">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(270deg)",
                  }}
                >
                  <title>Arrow right</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>
            </div>
            
          </div>

          <div className="swiper-wrapper" style={{ transform: "translate3d(0px, 0px, 0px)" }}>
            {games &&
              games.map((item, index) => {
                return (
                  <div className="swiper-slide swiper-slide-next" key={index}>
                    <div className="game-item-large svelte-12iieh">
                      <div className="img-wrap svelte-12iieh">
                        <div
                          className="wrapper svelte-l3byt6"
                          style={{
                            "--borderRadius": "5px",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <img
                            src={`https://static.fiable.games/images/fiable/${item.image_name}`}
                            alt={item.name}
                            className="image svelte-l3byt6"
                            style={{}}
                            title={item.description}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }

            {/*<div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(130)"
                      alt="Mines"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Mines"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(140)"
                      alt="Soccer"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Soccer"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>*/}

          </div>
          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProvablyFairBlock;
