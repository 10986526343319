import { useNavigate } from "react-router-dom";

const PageNotFound = (props) => {

  let navigate = useNavigate();

  return (
    <main
      style={{
        paddingTop: "100px",
        fontSize: "24px",
        fontWeight: 500,
        textAlign: "center",
        display: "flex",
        paddingBottom: "40px",
      }}
      className="container mx-auto"
    >
      <div className="row svelte-lzbo3i">
        <div className="description svelte-lzbo3i">
          <h2 className="svelte-lzbo3i">Oops!</h2>
          <p className="svelte-lzbo3i">
          Looks like this page is unavailable right now.
          </p>{" "}
          <button
            className="primary standart normal svelte-1tio7sv"
            type="button"
            style={{}}
            onClick={() => navigate(-1)}
          >
            {" "}
            Back to home{" "}
          </button>
        </div>
        <div className="animation svelte-lzbo3i">
          <div className="slot-container svelte-1vxig37">
            <div
              className="slot-machine svelte-ga2p1r"
              style={{ height: "164px", gap: "20px" }}
            >
              <div
                className="door svelte-1bwq1fn"
                style={{
                  transform: "translate(0, 4260px)",
                  transition: "all 4s cubic-bezier(.13,-0.33,.88,1.3) 200ms",
                  gap: "16px",
                }}
              >
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
              </div>
              <div
                className="door svelte-1bwq1fn"
                style={{
                  transform: "translate(0, 4260px)",
                  transition: "all 4s cubic-bezier(.13,-0.33,.88,1.3) 400ms",
                  gap: "16px",
                }}
              >
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
              </div>
              <div
                className="door svelte-1bwq1fn"
                style={{
                  transform: "translate(0, 4260px)",
                  transition: "all 4s cubic-bezier(.13,-0.33,.88,1.3) 200ms",
                  gap: "16px",
                }}
              >
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
              </div>
            </div>
            <div className="arm svelte-1vxig37"></div>
            <div className="stick svelte-1vxig37">
              <div className="handle svelte-1vxig37" role="button" tabIndex="0">
                <div className="circle"></div>
              </div>
            </div>
          </div>{" "}
          <img
            src={props.images["icon-1.f37d15b2.svg"]}
            alt="icons"
            className="icon-1 svelte-lzbo3i"
          />{" "}
          <img
            src={props.images["icon-2.38227f05.svg"]}
            alt="icons"
            className="icon-2 svelte-lzbo3i"
          />{" "}
          <img
            src={props.images["icon-3.35578e4d.svg"]}
            alt="icons"
            className="icon-3 svelte-lzbo3i"
          />
        </div>
        <div className="blur svelte-lzbo3i"></div>
      </div>
      <div className="column svelte-lzbo3i">
        <div className="description svelte-lzbo3i">
          <h2 className="svelte-lzbo3i">Oops!</h2>
          <p className="svelte-lzbo3i">
          Looks like this page is unavailable right now.
          </p>
        </div>
        <div className="animation svelte-lzbo3i">
          <div className="slot-container svelte-1vxig37">
            <div
              className="slot-machine svelte-ga2p1r"
              style={{ height: "164px", gap: "20px" }}
            >
              <div
                className="door svelte-1bwq1fn"
                style={{
                  transform: "translate(0, 4260px)",
                  transition: "all 4s cubic-bezier(.13,-0.33,.88,1.3) 200ms",
                  gap: "16px",
                }}
              >
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
              </div>
              <div
                className="door svelte-1bwq1fn"
                style={{
                  transform: "translate(0, 4260px)",
                  transition: "all 4s cubic-bezier(.13,-0.33,.88,1.3) 400ms",
                  gap: "16px",
                }}
              >
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["0.101a6962.svg"]}
                  alt="zero"
                  style={{ height: "100px", minHeight: "100px" }}
                />
              </div>
              <div
                className="door svelte-1bwq1fn"
                style={{
                  transform: "translate(0, 4260px)",
                  transition: "all 4s cubic-bezier(.13,-0.33,.88,1.3) 200ms",
                  gap: "16px",
                }}
              >
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["heart.7ff4a9b3.svg"]}
                  alt="heart"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["fj.36a06413.svg"]}
                  alt="fj"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["diamond.53d3c3b2.svg"]}
                  alt="diamond"
                  style={{ height: "100px", minHeight: "100px" }}
                />
                <img
                  src={props.images["4.cd9a25d4.svg"]}
                  alt="four"
                  style={{ height: "100px", minHeight: "100px" }}
                />
              </div>
            </div>
            <div className="arm svelte-1vxig37"></div>
            <div className="stick svelte-1vxig37">
              <div className="handle svelte-1vxig37" role="button" tabIndex="0">
                <div className="circle"></div>
              </div>
            </div>
          </div>{" "}
          <img
            src="./45_files/icon-1.f37d15b2.svg"
            alt="icons"
            className="icon-1 svelte-lzbo3i"
          />{" "}
          <img
            src="./45_files/icon-2.38227f05.svg"
            alt="icons"
            className="icon-2 svelte-lzbo3i"
          />{" "}
          <img
            src="./45_files/icon-3.35578e4d.svg"
            alt="icons"
            className="icon-3 svelte-lzbo3i"
          />
        </div>{" "}
        <button
          className="primary standart normal svelte-1tio7sv"
          type="button"
          style={{}}
          onClick={() => navigate(-1)}
        >
          {" "}
          Back to home{" "}
        </button>
        <div className="blur svelte-lzbo3i"></div>
      </div>
    </main>
  );
};
export default PageNotFound;
