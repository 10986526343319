import { Link } from "react-router-dom";

const Currencies = (props) => {
  return (
    <div className="currencies svelte-111tpvb">
      <Link to="/btc" className="currency svelte-111tpvb">
        <img
          src={props.images["5072b0f801224a6990d44e76c6fddbb9.svg"]}
          alt="Bitcoin"
          className="img svelte-111tpvb"
        />{" "}
      </Link>
      <a href="/usdt" className="currency svelte-111tpvb">
        <img
          src={props.images['9f891487d3eb4b3b8dcac816fbe93953.svg']}
          alt="Tether"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/eth" className="currency svelte-111tpvb">
        <img
          src={props.images['720bdf8f98074988ad927a2a1673b98d.svg']}
          alt="Ethereum"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/trx" className="currency svelte-111tpvb">
        <img
          src={props.images['596b0a59a54945b5bc86caa6ee797f68.svg']}
          alt="Tron"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/ltc" className="currency svelte-111tpvb">
        <img
          src={props.images['d160d3071f46472597104ce1a68e9038.svg']}
          alt="Litecoin "
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/sol" className="currency svelte-111tpvb">
        <img
          src={props.images['c91f76c6b24648a0ad4286015347ece8.svg']}
          alt="Solana"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/xdg" className="currency svelte-111tpvb">
        <img
          src={props.images['7892e260ae0c4db89692c82810e945ee.svg']}
          alt="Dogecoin"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/dash" className="currency svelte-111tpvb">
        <img
          src={props.images['40004e50f8b44db8918ec5fdafb7bdca.svg']}
          alt="DASH"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/zec" className="currency svelte-111tpvb">
        <img
          src={props.images['273aeb93dc854c1195c7a3b56b001f6f.svg']}
          alt="Zcash"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/usdc" className="currency svelte-111tpvb">
        <img
          src={props.images['92a6974006d1402d8eae8f3132b3605a.svg']}
          alt="USD Coin"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/bnb" className="currency svelte-111tpvb">
        <img
          src={props.images['565230535b8445ebbe869a1a955ff6be.svg']}
          alt="Binance"
          className="img svelte-111tpvb"
        />{" "}
      </a>
      <a href="/matic" className="currency svelte-111tpvb">
        <img
          src={props.images['c1487df6c91348ec8246fe67d64d8078.svg']}
          alt="Polygon"
          className="img svelte-111tpvb"
        />{" "}
      </a>
    </div>
  );
};
export default Currencies;
