const Promotions = (props) => {
  return (
    <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">
      <div className="container ms-auto me-auto">
        <div className="promotion-banner">
          <div className="promotion-banner-info svelte-10w3e0n">
            <h2 className="svelte-10w3e0n">4 Deposit Welcome Pack</h2>
            <p className="svelte-10w3e0n">
              6 BTC Bonus or Unlimited 20% Cashback
            </p>
          </div>
          <img
            className="promotion-banner-img svelte-10w3e0n"
            src={props.images["welcome.webp"]}
            alt="6 BTC OR WELCOME OFFER"
          />
        </div>
        <div className="promotion-header svelte-10w3e0n">
          <div className="page-title svelte-10w3e0n">Promotions</div>
          <div className="filters svelte-10w3e0n">
            <div className="filter selected svelte-10w3e0n">All</div>
            <div className="filter svelte-10w3e0n">Sport </div>
            <div className="filter svelte-10w3e0n">Welcome </div>
            <div className="filter svelte-10w3e0n">Loyalty </div>
            <div className="filter svelte-10w3e0n">Casino </div>
          </div>
        </div>
        <div className="promotions-box svelte-10w3e0n">
          <div className="promo position-relative svelte-1dtwvdj">
            <div className="img-wrap svelte-1dtwvdj">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "12px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['cf12203b40ca4dc889eb457a2512095d.webp']}
                  alt="Welcome Offers"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Welcome Offers"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="info d-flex flex-column justify-start svelte-1dtwvdj">
              <div className="title svelte-1dtwvdj">Welcome Offers</div>
              <div className="description svelte-1dtwvdj">
                6 BTC Bonus or Unlimited 20% Cashback
              </div>
            </div>
          </div>
          <div className="promo position-relative svelte-1dtwvdj">
            <div className="img-wrap svelte-1dtwvdj">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "12px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['94d971018a8146cb9c5bee7b9441bec9.webp']}
                  alt="Get 100 Free Spins"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Get 100 Free Spins"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="info d-flex flex-column justify-start svelte-1dtwvdj">
              <div className="title svelte-1dtwvdj">Get 100 Free Spins</div>
              <div className="description svelte-1dtwvdj">
                Get 100 Free Spins at UP-10X. Get a Bitcoin Bonus and e...
              </div>
            </div>
          </div>
          <div className="promo position-relative svelte-1dtwvdj">
            <div className="img-wrap svelte-1dtwvdj">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "12px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['369db41afbfd47af99605776d2d9ac9f.webp']}
                  alt="Unbeatable Bonuses"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Unbeatable Bonuses"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="info d-flex flex-column justify-start svelte-1dtwvdj">
              <div className="title svelte-1dtwvdj">Unbeatable Bonuses</div>
              <div className="description svelte-1dtwvdj">
                Start Earning Points by Playing at UP-10X
              </div>
            </div>
          </div>
          <div className="promo position-relative svelte-1dtwvdj">
            <div className="img-wrap svelte-1dtwvdj">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "12px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['dc6c899e4fa4424098717823422486fa.webp']}
                  alt="Majestic 7"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Majestic 7"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="info d-flex flex-column justify-start svelte-1dtwvdj">
              <div className="title svelte-1dtwvdj">Majestic 7</div>
              <div className="description svelte-1dtwvdj">
                PREDICT GOALSCORERS TO WIN $10,000
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Promotions;
