const Partners = (props) => {
  return (
    <div className="partners svelte-1txt4j5">
      <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode">
        <div
          className="swiper-wrapper"
          style={{
            transform: "translate3d(-3108px, 0px, 0px)",
            transitionDuration: "0ms",
          }}
        >
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/pragmaticplay">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['c925d75aaca1464ea9d6dc5fccb23c29.svg']}
                      alt="Pragmatic Play"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/relaxgaming">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['6aa636461d7f4431b2d814c4ea5e2f81.svg']}
                      alt="Relax Gaming"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/yggdrasil">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/bab90034301d482f98f07d0d4cff9375.svg"
                      alt="Yggdrasil"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/endorphina">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/881e1c82f1c64c8e909c655937796baa.svg"
                      alt="Endorphina"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/betsoft">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/1ff8a698b31848c3b267c5bdb46de5e8.svg"
                      alt="BetSoft"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/evoplay">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/a80f74541d7a419bb16fdb5e42931a5c.svg"
                      alt="Evoplay"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/mascot">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/2df657ed80c146e59d850292235f5082.svg"
                      alt="Mascot"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/netgame">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/46cf9123c1754b53b09108e7461bff8c.svg"
                      alt="NetGame"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/gameart">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/7b05735d43384ce6a8b78e873c7d727c.svg"
                      alt="GameArt"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/playngo">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/c757bb3b2c0e4edfa7dc5914b2619354.svg"
                      alt="Play&#39;n GO"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/quickspin">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/e0f23d2ce7ae4b74b6cccce7d957d9e2.svg"
                      alt="Quickspin"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/microgaming">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/627d73afcd7241dab99aa9c0aabd9c0a.svg"
                      alt="Microgaming"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/bgaming">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/c6728c344c5c434e8f9a18ff36b6d6ef.svg"
                      alt="BGaming"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/booming_games">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/29dd4232b9554bea9a528d704dd58b24.svg"
                      alt="Booming Games"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/pushgaming">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/da692dc23cbb48bfb197de5f2fb40bed.svg"
                      alt="Push Gaming"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/1spin4win">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/dccf9b60f5fb42108b3ea8c35bbe6ea8.webp"
                      alt="  1Spin4Win"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/amatic">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/40ff3c503e8a4c90bf3bbcb7b3a24bff.svg"
                      alt="Amatic"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/betgamestv">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/47007e19c6b249b9b83592dc84a78a75.svg"
                      alt="Betgames.tv"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/belatra">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/cd4c00a1ab2c4de3b3a4da0a75200dde.svg"
                      alt="Belatra Games"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/evolutiongaming">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/edfcb124f31c479db93002e944236fc0.svg"
                      alt="Evolution Gaming"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide swiper-slide-prev">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/fugaso">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/9c7317e6d1284d2c98782fb488697a4a.svg"
                      alt="Fugaso"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          
          <div className="swiper-slide swiper-slide-active">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/habanero">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['5baed20be480445e91c1f9c282c9001c.svg']}
                      alt="Habanero"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide swiper-slide-next">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/playson">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['59ff0d28ede74fbcbadf240eafef2f1b.svg']}
                      alt="Playson"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/platipus">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['a0f58bb7da4746de96d2ea207a8a0e4d.svg']}
                      alt="Platipus"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/playtech">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['aaa40d9b5e36461baaaa51a1f469723e.svg']}
                      alt="Playtech"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/lambdagaming">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['e28018683f0940a195db4d60a9c1cf17.svg']}
                      alt="Lambda Gaming"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/netent">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['f5ee35a042ba4f2ab67e98f60f3768de.svg']}
                      alt="NetEnt"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/orbital">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['b4a8fd25eab24e68957f22eb0b3b63b8.svg']}
                      alt="Orbital"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/wazdan">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['42946c4039b34ad789f0ea7eb3c81386.svg']}
                      alt="Wazdan"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/kalamba">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src={props.images['05f4a9772bc645b8b8d508c0d8ae1c0d.svg']}
                      alt="Kalamba"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/gamesinc">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/0313218d50164b57acba19460aa68e94.svg"
                      alt="Games Inc"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="banner-container svelte-1txt4j5">
              <div className="banner svelte-1txt4j5">
                <a href="/provider/redtiger">
                  <div className="banner-item svelte-1txt4j5">
                    <img
                      src="./assets/53be3076ad534c408383feff1cb639d5.svg"
                      alt="Red Tiger"
                      className="svelte-1txt4j5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Partners;
