import Aside from "../components/Aside";
import ProvablyFairContent from "../components/ProvablyFairContent";

const ProvablyFair = (props) => {
    return (
        <>
            <Aside images={props.images} />
            <ProvablyFairContent images={props.images} />
            
        </>
    )
}
export default ProvablyFair;