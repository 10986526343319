import Slider from "./Slider";

const LiveCasino = (props) => {
  return (
    <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">
      <Slider images={props.images} />

      <div className="wrapper container ms-auto me-auto svelte-14d9lv5">
        <h2 className="title svelte-14d9lv5">Best Live Casino Games</h2>
        <div className="games svelte-14d9lv5">
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['75df8a2201114c6e85d5028a0e727a35.webp']}
                  alt="PowerUp Roulette"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="PowerUp Roulette"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['27bcc4667a7c43c889dfa1d8b20183fd.webp']}
                  alt="Sweet Bonanza CandyLand"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Sweet Bonanza CandyLand"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['94695c0f41f04e69925a987684683811.webp']}
                  alt="BOOM CITY"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="BOOM CITY"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['d9b8b97875a44e399f83a668e75172c6.webp']}
                  alt="Crazy Time"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Crazy Time"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['520c03843d674893995b587c3c052735.webp']}
                  alt="Lightning Roulette"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Lightning Roulette"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper container ms-auto me-auto svelte-14d9lv5">
        <h2 className="title svelte-14d9lv5">Best Crypto Games Lobby</h2>
        <div className="games svelte-14d9lv5">
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['46177abdc98041509b19886e5cbf5ecf.webp']}
                  alt="Blackjack Lobby"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Blackjack Lobby"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['7267040c32d54de8a1ba00f771c0605e.webp']}
                  alt="Asian Games Lobby"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Asian Games Lobby"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['5d8f07a6087d47388aeb9b0b44c82a2a.webp']}
                  alt="Live Game Shows Lobby"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live Game Shows Lobby"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['6320d11333fb468aacef4aaaa1a7c299.webp']}
                  alt="Baccarat Lobby "
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Baccarat Lobby "
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['1b15022bcb464e4d9a2866491c88be23.webp']}
                  alt="Blackjack Lobby"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Blackjack Lobby"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['f23193e9934e477ca4b91244d6ae0f13.webp']}
                  alt="Roulette Lobby "
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Roulette Lobby "
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['0a852703b5eb4bfb806710be81b67733.webp']}
                  alt="Baccarat Lobby"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Baccarat Lobby"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper container ms-auto me-auto svelte-14d9lv5">
        <h2 className="title svelte-14d9lv5">Best Crypto Games&nbsp;</h2>
        <div className="games svelte-14d9lv5">
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['d9f517e427dc49eab3eeaf441c858e53.webp']}
                  alt="Poker Lobby"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Poker Lobby"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['6eddac7e51824d33a02259f5f6a2ce10.webp']}
                  alt="Lucky 6 Roulette"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Lucky 6 Roulette"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['5a82682d7ff04718ad72b196945bedcd.webp']}
                  alt="First Person Super Sic Bo"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="First Person Super Sic Bo"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['c3a7122dce6b4752b2471656245c75b6.webp']}
                  alt="Red Door Roulette"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Red Door Roulette"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['8a2237712c0e4f22b62579c6351fd88d.webp']}
                  alt="Sic Bo"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Sic Bo"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['13ea5b90ee1d424289441ad44bdfa308.webp']}
                  alt="First Person XXXtreme Lightning Baccarat"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="First Person XXXtreme Lightning Baccarat"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['a788ce7f14014e5d9ed23ba592a10949.webp']}
                  alt="First Person Lightning Lotto"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="First Person Lightning Lotto"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['71d3aaacfef949049dd4affed7d4c7dc.webp']}
                  alt="First Person Prosperity Tree Baccarat"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="First Person Prosperity Tree Baccarat"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['fd007b25c42541f6bd23b8f47f62fdbf.webp']}
                  alt="Instant Lucky 7"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Instant Lucky 7"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['498f5ce8ec74490aa128a744931117e2.webp']}
                  alt="VIP Roulette - The Club"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="VIP Roulette - The Club"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['a486356abf1f4c9492d115bd0aef3b15.webp']}
                  alt="Vegas Ball Bonanza"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Vegas Ball Bonanza"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['1d132a998c584af09abc596b28c88f0d.webp']}
                  alt="Dead or Alive: Saloon"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Dead or Alive: Saloon"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['d736e25c4fb04f9d9e42c632a2c9e689.webp']}
                  alt="Football Studio Roulette"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Football Studio Roulette"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['26068e6a1c444db28e5b685bf100890a.webp']}
                  alt="Peek Baccarat"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Peek Baccarat"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['964d606e2676493a954e70387023d981.webp']}
                  alt="Mega Ball"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Mega Ball"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['893436aa4ece45c68f6dfe98ecdc400e.webp']}
                  alt="Cash or Crash"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Cash or Crash"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['3778de0323004e6a99041bdd92baee58.webp']}
                  alt="Dragon Tiger"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Dragon Tiger"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['02ff531516d041eda9623ef3281e81b1.webp']}
                  alt="Mega Sic Bo"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Mega Sic Bo"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['c2f27b9fddca4ea78db15ce33005f62f.webp']}
                  alt="MONOPOLY Live"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="MONOPOLY Live"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['1bab22e291b348fc89f3779f7f5c97e5.webp']}
                  alt="Fan Tan"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Fan Tan"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['00989f3effd648db9a084a0306307c39.webp']}
                  alt="ONE Blackjack"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="ONE Blackjack"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['8be400a1fa2c458d857d6b100172c1c2.webp']}
                  alt="Mega Roulette"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Mega Roulette"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['4ce341140b9242e0a78858f3e48f752d.webp']}
                  alt="Craps"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Craps"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['69003c89f9554570a2f72ca9cbb1f2ef.webp']}
                  alt="Mega Wheel"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Mega Wheel"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['6ba2cf10b08b45e79c81131a8bfc1eba.webp']}
                  alt="Auto Roulette "
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Auto Roulette "
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['7bac668b88cb4452909033184a3f74db.webp']}
                  alt="Live - Speed Roulette"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live - Speed Roulette"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['0320cecf2b7d429384e344719323aebb.webp']}
                  alt="Deal or No Deal"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Deal or No Deal"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['653054e026f84efa83c41d01c227a257.webp']}
                  alt="Football studio"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Football studio"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['32f5abf708cd4901aaf5049a4973eb51.webp']}
                  alt="Speedy 7"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Speedy 7"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['33572987c98e4bb798b60d547f56e8a7.webp']}
                  alt="Lightning Baccarat"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Lightning Baccarat"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['ac5e9d7601f24d19b57a4d0caf5f8d20.webp']}
                  alt="Lightning Dice"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Lightning Dice"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['12e0fbad8d184f87a425a74cb728d589.webp']}
                  alt="Live First Person BlackJack"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live First Person BlackJack"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['1b8ad23a8daa44d0acf0a629f9a1f859.webp']}
                  alt="Dice Duel"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Dice Duel"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['99e1834cd4ac4afb917dab73ca728b93.webp']}
                  alt="Live Lucky 6"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live Lucky 6"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['ff3c9d5ec522487a9ba4d6f9abe98c9b.webp']}
                  alt="Live Wheel of Fortune"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live Wheel of Fortune"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['16ae2327051b42189dbb3f1dbda14b73.webp']}
                  alt="Live Baccarat"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live Baccarat"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['b946af46894249448fc7d66944960ae4.webp']}
                  alt="Live Bet On Poker"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live Bet On Poker"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['fd9835e1b1f1451194e9076326b1e1b7.webp']}
                  alt="Live Lucky 7"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live Lucky 7"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['a4b56750054447e880fe178a80a80327.webp']}
                  alt="Live Lucky 5"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live Lucky 5"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="game-item-large svelte-1tenouv">
            <div className="overlay svelte-1tenouv">
              <div className="play-button svelte-1tenouv">
                <button
                  className="primary standart small svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Play Now{" "}
                </button>
              </div>
            </div>
            <div className="img-wrap svelte-1tenouv">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['46557e0cbf904572bbdff247211c4def.webp']}
                  alt="Live War of Bets"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="Live War of Bets"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contents-expandable container ms-auto me-auto svelte-ngs2b1" style={{ "--height-before-expand": "212px" }}>
        <img
          src={props.images['icon-7.webp']}
          alt="7"
          className="icon--7 svelte-ngs2b1"
        />{" "}
        <img
          src={props.images['icon-coin.webp']}
          alt="coin"
          className="icon--coin svelte-ngs2b1"
        />
        <div>
          <h1>The Best Crypto Live Casino Games at Up-10X</h1>
          <p>
            The crypto live casino experience at Up-10X brings the thrill
            and excitement of a real casino right to your fingertips.
          </p>
          <p style={{ display: "none" }}>
            Our immersive platform hosts many classic games like&nbsp;
            <strong>Blackjack</strong>,&nbsp;<strong>Roulette</strong>,
            and&nbsp;<strong>Baccarat</strong>, as well as popular game-show
            style games, all conducted in real time by professional live
            dealers.
          </p>
          <p style={{ display: "none" }}>
            The sophisticated streaming technology allows for smooth and
            high-definition gameplay, while the interactive chat features create
            a friendly atmosphere similar to a traditional casino.
          </p>
          <p style={{ display: "none" }}>
            The live casino games cater to all types of players, from beginners
            to seasoned gamblers, ensuring an enjoyable experience for all. The
            added layer of transparency with live dealing increases trust and
            satisfaction among players, making Up-10X's live casino a
            premier destination for those seeking the authentic thrill of live
            gambling in the crypto world.&nbsp;Let's take a closer look at these
            popular live casino games.
          </p>
          <p style={{ display: "none" }}>&nbsp;</p>
          <h2 style={{ display: "none" }}>
            <strong>Live Blackjack Online</strong>
          </h2>
          <p style={{ display: "none" }}>
            &nbsp;Live Casino Blackjack is a trendy game at Up-10X. Its
            real-time dealing and interactive gaming experience enhance the
            appeal of this timeless card game. Players can apply their
            strategies to achieve the coveted number 21 while interacting with
            skilled dealers and other players, resulting in an exciting and
            unique game every time.
          </p>
          <p style={{ display: "none" }}>&nbsp;</p>
          <h2 style={{ display: "none" }}>
            <strong>Live Roulette Table wheel</strong>
          </h2>
          <p style={{ display: "none" }}>
            Experience the excitement of the classic casino game Roulette with
            Up-10X's Live Casino Roulette. Whether you prefer European,
            American, or French Roulette, you can enjoy watching the ball spin
            in real-time, creating an immersive gaming experience that captures
            the essence of playing at a real casino.
          </p>
          <p style={{ display: "none" }}>&nbsp;</p>
          <h2 style={{ display: "none" }}>
            &nbsp;<strong>Live Baccarat online</strong>
          </h2>
          <p style={{ display: "none" }}>
            Up-10X's Live Casino offers the game of Baccarat, known for its
            sophistication and strategic elements. Despite its simple rules,
            Baccarat provides an interactive and engaging gameplay experience.
            Players can enjoy watching the dealer shuffle and deal the cards in
            real time, which adds transparency and excitement to the game. This
            unique experience keeps players coming back for more.
          </p>
          <p style={{ display: "none" }}>&nbsp;</p>
          <h2 style={{ display: "none" }}>
            <strong>Live Casino Promotions</strong>
          </h2>
          <p style={{ display: "none" }}>
            Up-10X's promotions for their live casino games aim to enhance
            the excitement and potential rewards. These promotions can be in the
            form of&nbsp;<strong>deposit bonuses</strong>,&nbsp;
            <strong>cashback offers</strong>, and special&nbsp;
            <strong>tournaments</strong>, providing an added level of thrill to
            the live gaming experience.
          </p>
          <p style={{ display: "none" }}>
            An example of a helpful promotion for online gaming is a deposit
            bonus, which adds extra funds to your account and lets you play for
            longer. Alternatively, cashback offers to act as a safety net by
            giving back a portion of your losses.
          </p>
          <p style={{ display: "none" }}>
            Players can compete in special tournaments to win significant
            prizes, creating a friendly and competitive environment. These
            promotions increase potential winnings and add a unique element to
            live casino games, making each hand dealt or wheel spin even more
            exciting.
          </p>
          <p style={{ display: "none" }}>&nbsp;</p>
          <h2 style={{ display: "none" }}>
            <strong>Play and Win real money online</strong>
          </h2>
          <p style={{ display: "none" }}>
            Playing and winning big at Up-10X is an exciting experience
            with the potential for substantial rewards. As a leading platform
            for crypto gaming, Up-10X offers a wide variety of live casino
            games that provide numerous opportunities for hefty payouts.
          </p>
          <p style={{ display: "none" }}>
            Up-10X's live casino is a testament to our dedication in
            recreating the thrilling ambiance of a physical casino. It features
            skilled live dealers, real-time gameplay, and the opportunity to hit
            it big with every game.
          </p>
          <p style={{ display: "none" }}>
            All the mentioned, coupled with generous promotions and a secure,
            user-friendly platform, makes Up-10X an ideal destination for
            new and skilled players looking to experience the thrill of gambling
            and the joy of winning big.
          </p>
          <p style={{ display: "none" }}>&nbsp;</p>
        </div>
        <div className="btn svelte-ngs2b1">
          <button
            className="primary standart normal svelte-uia73q"
            type="button"
            style={{}}
          >
            <span slot="icon-left">
              <svg
                className="right svelte-1m58f2p"
                viewBox="0 0 12 12"
                width="12"
                height="12"
                fill="none"
              >
                <title>Arrow right</title>
                <path
                  d="M9.5 4.5L6 8L2.5 4.5"
                  stroke="#E0E0E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ fill: "none!important" }}
                ></path>
              </svg>
            </span>{" "}
            Learn More{" "}
          </button>
        </div>
      </div>

    </main>
  );
};
export default LiveCasino;
