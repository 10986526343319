const ProvidersBlock = (props) => {
  return (
    <div className="providers-block container ms-auto me-auto">
      <div className="s-wrapper position-relative swiper-wrapper-auto false svelte-12mba6b">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode">
          <div
            className="container-start svelte-12mba6b"
            slot="container-start"
          >
            <div className="title svelte-12mba6b">
              Best Crypto Game Providers{" "}
              <img
                src={props.images["right-arrow.ba864f8e.svg"]}
                alt="arrow"
                className="svelte-12mba6b"
              />
            </div>
            <div className="action-buttons d-flex items-center svelte-12mba6b">
              <button
                className="prevfe30408c-6dc8-4b40-b334-d664a2349e2d btn black svelte-12mba6b swiper-button-disabled"
                disabled=""
              >
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(90deg)",
                  }}
                >
                  <title>Arrow left</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>{" "}
              <button className="nextfe30408c-6dc8-4b40-b334-d664a2349e2d btn black svelte-12mba6b">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(270deg)",
                  }}
                >
                  <title>Arrow right</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            className="swiper-wrapper"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <div className="swiper-slide swiper-slide-active">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["c925d75aaca1464ea9d6dc5fccb23c29.svg"]}
                  alt="Pragmatic Play"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide swiper-slide-next">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["6aa636461d7f4431b2d814c4ea5e2f81.svg"]}
                  alt="Relax Gaming"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["bab90034301d482f98f07d0d4cff9375.svg"]}
                  alt="Yggdrasil"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["881e1c82f1c64c8e909c655937796baa.svg"]}
                  alt="Endorphina"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["1ff8a698b31848c3b267c5bdb46de5e8.svg"]}
                  alt="BetSoft"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["627d73afcd7241dab99aa9c0aabd9c0a.svg"]}
                  alt="Microgaming"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["2df657ed80c146e59d850292235f5082.svg"]}
                  alt="Mascot"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/original(141)"
                  alt="PG Soft"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/a80f74541d7a419bb16fdb5e42931a5c.svg"
                  alt="Evoplay"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/46cf9123c1754b53b09108e7461bff8c.svg"
                  alt="NetGame"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["7b05735d43384ce6a8b78e873c7d727c.svg"]}
                  alt="GameArt"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/c757bb3b2c0e4edfa7dc5914b2619354.svg"
                  alt="Play&#39;n GO"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/e0f23d2ce7ae4b74b6cccce7d957d9e2.svg"
                  alt="Quickspin"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/c6728c344c5c434e8f9a18ff36b6d6ef.svg"
                  alt="BGaming"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/29dd4232b9554bea9a528d704dd58b24.svg"
                  alt="Booming Games"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/da692dc23cbb48bfb197de5f2fb40bed.svg"
                  alt="Push Gaming"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/dccf9b60f5fb42108b3ea8c35bbe6ea8.webp"
                  alt="  1Spin4Win"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/40ff3c503e8a4c90bf3bbcb7b3a24bff.svg"
                  alt="Amatic"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/47007e19c6b249b9b83592dc84a78a75.svg"
                  alt="Betgames.tv"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/cd4c00a1ab2c4de3b3a4da0a75200dde.svg"
                  alt="Belatra Games"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/edfcb124f31c479db93002e944236fc0.svg"
                  alt="Evolution Gaming"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/9c7317e6d1284d2c98782fb488697a4a.svg"
                  alt="Fugaso"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/5baed20be480445e91c1f9c282c9001c.svg"
                  alt="Habanero"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/59ff0d28ede74fbcbadf240eafef2f1b.svg"
                  alt="Playson"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/a0f58bb7da4746de96d2ea207a8a0e4d.svg"
                  alt="Platipus"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/aaa40d9b5e36461baaaa51a1f469723e.svg"
                  alt="Playtech"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/e28018683f0940a195db4d60a9c1cf17.svg"
                  alt="Lambda Gaming"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/f5ee35a042ba4f2ab67e98f60f3768de.svg"
                  alt="NetEnt"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src="./assets/b4a8fd25eab24e68957f22eb0b3b63b8.svg"
                  alt="Orbital"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["42946c4039b34ad789f0ea7eb3c81386.svg"]}
                  alt="Wazdan"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["05f4a9772bc645b8b8d508c0d8ae1c0d.svg"]}
                  alt="Kalamba"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["0313218d50164b57acba19460aa68e94.svg"]}
                  alt="Games Inc"
                  className="svelte-5w5101"
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="provider-item svelte-5w5101">
                <img
                  src={props.images["53be3076ad534c408383feff1cb639d5.svg"]}
                  alt="Red Tiger"
                  className="svelte-5w5101"
                />
              </div>
            </div>
          </div>
          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProvidersBlock;
