
const Blog = (props) => {
  return (
    <main className="svelte-ulvgrp" style={{ paddingTop: "86px" }}>
    <div className="container mx-auto blog svelte-m7mmtx">
      <div className="banners svelte-ld7916">
        <div className="s-wrapper position-relative false is-banner svelte-174fjto">
          <div className="swiper swiper-fade swiper-initialized swiper-horizontal swiper-pointer-events swiper-watch-progress swiper-backface-hidden">
            <div
              className="container-start svelte-174fjto"
              slot="container-start"
            ></div>
            <div
              className="swiper-wrapper"
              style={{ transitionDuration: "0ms" }}
            >
              <div
                className="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                data-swiper-slide-index="4"
                style={{
                  width: "1200px",
                  opacity: 1,
                  transform: "translate3d(0px, 0px, 0px)",
                }}
              >
                <div className="banner svelte-1rn6hbi">
                  <div className="banner__info svelte-1rn6hbi">
                    <h2 className="banner__info__title svelte-1rn6hbi">
                      Solana – New Payment Method at CrossGaming
                    </h2>
                    <div className="banner__info__excerpt svelte-1rn6hbi">
                      <p>
                        Solana, an innovative digital currency, has now landed
                        on CrossGaming, which is a significant lea...
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="banner__info__category svelte-1rn6hbi">
                        Cryptocurrency
                      </span>
                      <time
                        dateTime="2023-12-27T12:51:57+04:00"
                        className="banner__info__date svelte-1rn6hbi"
                      >
                        December 27, 2023
                      </time>
                    </div>
                  </div>
                  <div className="banner__img svelte-1rn6hbi">
                    <img
                      src={props.images["31113.png"]}
                      alt=""
                      className="svelte-1rn6hbi"
                    />
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-visible swiper-slide-active"
                style={{
                  width: "1200px",
                  transitionDuration: "0ms",
                  opacity: 1,
                  transform: "translate3d(-1200px, 0px, 0px)",
                }}
                data-swiper-slide-index="0"
              >
                <div className="banner svelte-1rn6hbi">
                  <div className="banner__info svelte-1rn6hbi">
                    <h2 className="banner__info__title svelte-1rn6hbi">
                      Which Gambler Type Are You? – A Fun Journey
                    </h2>
                    <div className="banner__info__excerpt svelte-1rn6hbi">
                      <p>
                        Feeling lucky, young padawan? In this exciting world
                        of online casinos, where crypto meets Vegas ...
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="banner__info__category svelte-1rn6hbi">
                        Gambling
                      </span>{" "}
                      <time
                        dateTime="2024-02-09T16:57:21+04:00"
                        className="banner__info__date svelte-1rn6hbi"
                      >
                        February 9, 2024
                      </time>
                    </div>
                  </div>
                  <div className="banner__img svelte-1rn6hbi">
                    <img
                      src={props.images['Gambler-Type-1-1.jpg']}
                      alt=""
                      className="svelte-1rn6hbi"
                    />
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-next"
                data-swiper-slide-index="1"
                style={{
                  width: "1200px",
                  opacity: 0,
                  transform: "translate3d(-2400px, 0px, 0px)",
                }}
              >
                <div className="banner svelte-1rn6hbi">
                  <div className="banner__info svelte-1rn6hbi">
                    <h2 className="banner__info__title svelte-1rn6hbi">
                      Live Blackjack – Rules and Strategies Explained
                    </h2>
                    <div className="banner__info__excerpt svelte-1rn6hbi">
                      <p>
                        Blackjack, also known as 21, is a popular card game in
                        casinos worldwide. Live Blackjack brings t...
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="banner__info__category svelte-1rn6hbi">
                        Gambling
                      </span>{" "}
                      <time
                        dateTime="2024-02-02T18:24:42+04:00"
                        className="banner__info__date svelte-1rn6hbi"
                      >
                        February 2, 2024
                      </time>
                    </div>
                  </div>
                  <div className="banner__img svelte-1rn6hbi">
                    <img
                      src={props.images['Live-Blackjack-Rules-and-Strategies-Explained.jpg']}
                      alt=""
                      className="svelte-1rn6hbi"
                    />
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide"
                data-swiper-slide-index="2"
                style={{
                  width: "1200px",
                  opacity: 0,
                  transform: "translate3d(-3600px, 0px, 0px)",
                }}
              >
                <div className="banner svelte-1rn6hbi">
                  <div className="banner__info svelte-1rn6hbi">
                    <h2 className="banner__info__title svelte-1rn6hbi">
                      Celebrating Excellence: CrossGaming Wins the LCB Members
                      Choice Award
                    </h2>
                    <div className="banner__info__excerpt svelte-1rn6hbi">
                      <p>
                        The online gaming world is buzzing with news of
                        CrossGaming Casino receiving the LCB Awards in Me...
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="banner__info__category svelte-1rn6hbi">
                        Casino News
                      </span>
                      <time
                        dateTime="2024-01-24T17:44:30+04:00"
                        className="banner__info__date svelte-1rn6hbi"
                      >
                        January 24, 2024
                      </time>
                    </div>
                  </div>
                  <div className="banner__img svelte-1rn6hbi">
                    <img
                      src={props.images['LCB-awards.jpg']}
                      alt=""
                      className="svelte-1rn6hbi"
                    />
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide"
                data-swiper-slide-index="3"
                style={{
                  width: "1200px",
                  opacity: 0,
                  transform: "translate3d(-4800px, 0px, 0px)",
                }}
              >
                <div className="banner svelte-1rn6hbi">
                  <div className="banner__info svelte-1rn6hbi">
                    <h2 className="banner__info__title svelte-1rn6hbi">
                      Bet, Win, Celebrate: Dive into CrossGaming’s $10,000
                      Sports Tour
                    </h2>
                    <div className="banner__info__excerpt svelte-1rn6hbi">
                      <p>
                        Ready to take your sports betting game to the next
                        level? CrossGaming is excited to announce a th...
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="banner__info__category svelte-1rn6hbi">
                        Uncategorized
                      </span>
                      <time
                        dateTime="2024-01-10T18:28:08+04:00"
                        className="banner__info__date svelte-1rn6hbi"
                      >
                        January 10, 2024
                      </time>
                    </div>
                  </div>
                  <div className="banner__img svelte-1rn6hbi">
                    <img
                      src={props.images['sportsbook-tournament.jpg']}
                      alt=""
                      className="svelte-1rn6hbi"
                    />
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-duplicate-prev"
                data-swiper-slide-index="4"
                style={{
                  width: "1200px",
                  opacity: 0,
                  transform: "translate3d(-6000px, 0px, 0px)",
                }}
              >
                <div className="banner svelte-1rn6hbi">
                  <div className="banner__info svelte-1rn6hbi">
                    <h2 className="banner__info__title svelte-1rn6hbi">
                      Solana – New Payment Method at CrossGaming
                    </h2>
                    <div className="banner__info__excerpt svelte-1rn6hbi">
                      <p>
                        Solana, an innovative digital currency, has now landed
                        on CrossGaming, which is a significant lea...
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="banner__info__category svelte-1rn6hbi">
                        Cryptocurrency
                      </span>
                      <time
                        dateTime="2023-12-27T12:51:57+04:00"
                        className="banner__info__date svelte-1rn6hbi"
                      >
                        December 27, 2023
                      </time>
                    </div>
                  </div>
                  <div className="banner__img svelte-1rn6hbi">
                    <img
                      src={props.images["31113.png"]}
                      alt=""
                      className="svelte-1rn6hbi"
                    />
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                style={{
                  width: "1200px",
                  transitionDuration: "0ms",
                  opacity: 0,
                  transform: "translate3d(-7200px, 0px, 0px)",
                }}
                data-swiper-slide-index="0"
              >
                <div className="banner svelte-1rn6hbi">
                  <div className="banner__info svelte-1rn6hbi">
                    <h2 className="banner__info__title svelte-1rn6hbi">
                      Which Gambler Type Are You? – A Fun Journey
                    </h2>
                    <div className="banner__info__excerpt svelte-1rn6hbi">
                      <p>
                        Feeling lucky, young padawan? In this exciting world
                        of online casinos, where crypto meets Vegas ...
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="banner__info__category svelte-1rn6hbi">
                        Gambling
                      </span>{" "}
                      <time
                        dateTime="2024-02-09T16:57:21+04:00"
                        className="banner__info__date svelte-1rn6hbi"
                      >
                        February 9, 2024
                      </time>
                    </div>
                  </div>
                  <div className="banner__img svelte-1rn6hbi">
                    <img
                      src={props.images['Gambler-Type-1-1.jpg']}
                      alt=""
                      className="svelte-1rn6hbi"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
              <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="filters d-flex items-center">
        <div className="d-flex gap-16 categories svelte-6r1jme">
          <div className="d-flex overflow-x-auto scroll-smooth svelte-6r1jme">
            <div className="item false svelte-1yj8fxa">Casino</div>
            <div className="item false svelte-1yj8fxa">Casino News</div>
            <div className="item false svelte-1yj8fxa">Crypto 101</div>
            <div className="item false svelte-1yj8fxa">Cryptocurrency</div>
            <div className="item false svelte-1yj8fxa">Gambling</div>
            <div className="item false svelte-1yj8fxa">Gambling 101</div>
            <div className="item false svelte-1yj8fxa">Orbital</div>
            <div className="item false svelte-1yj8fxa">Other</div>
            <div className="item false svelte-1yj8fxa">Sports</div>
            <div className="item false svelte-1yj8fxa">Uncategorized</div>
            <div className="item false svelte-1yj8fxa">VPN</div>
          </div>
          <div className="cursor-pointer d-flex items-center">
            <svg
              className="right svelte-1m58f2p"
              viewBox="0 0 12 12"
              width="18"
              height="18"
              fill="none"
            >
              <title>Arrow right</title>
              <path
                d="M9.5 4.5L6 8L2.5 4.5"
                stroke="#E0E0E0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ fill: "none !important" }}
              ></path>
            </svg>
          </div>
        </div>
        <div className="input-wrapper ms-auto svelte-1wb5k62">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="#fff"
            className="svelte-1wb5k62"
          >
            <path
              d="M1557.558,48.559a1.5,1.5,0,0,1-2.125,0l-2.651-2.652a7.048,7.048,0,1,1,2.125-2.125l2.652,2.652A1.5,1.5,0,0,1,1557.558,48.559ZM1549.011,35a5.008,5.008,0,1,0,5.008,5.008A5.008,5.008,0,0,0,1549.011,35Z"
              transform="translate(-1541.999 -33)"
            ></path>
          </svg>{" "}
          <input placeholder="Search" className="svelte-1wb5k62" />
        </div>
      </div>
      <div className="blog-items svelte-1mr8sl4">
        <a
          href="blog/which-gambler-type-are-you-a-fun-journey"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img
              src={props.images['Gambler-Type-1-1.jpg']}
              alt=""
              className="svelte-j1ieck"
            />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Which Gambler Type Are You? – A Fun Journey
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                Feeling lucky, young padawan? In this exciting world of online
                casinos, where crypto meets Vegas vibes, are you the Maverick
                with a strategy, ...
              </p>
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Gambling
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2024-02-09T16:57:21+04:00"
              >
                February 9, 2024
              </time>
            </div>
          </div>
        </a>
        <a
          href="blog/live-blackjack-rules-and-strategies-explained"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img
              src={props.images['Live-Blackjack-Rules-and-Strategies-Explained.jpg']}
              alt=""
              className="svelte-j1ieck"
            />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Live Blackjack – Rules and Strategies Explained
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                Blackjack, also known as 21, is a popular card game in casinos
                worldwide. Live Blackjack brings the excitement of the game
                right to your scree...
              </p>
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Gambling
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2024-02-02T18:24:42+04:00"
              >
                February 2, 2024
              </time>
            </div>
          </div>
        </a>
        <a
          href="blog"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img
              src={props.images['LCB-awards.jpg']}
              alt=""
              className="svelte-j1ieck"
            />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Celebrating Excellence: CrossGaming Wins the LCB Members Cho
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                The online gaming world is buzzing with news of CrossGaming
                Casino receiving the LCB Awards in Members Choice
              </p>
              ...
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Casino News
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2024-01-24T17:44:30+04:00"
              >
                January 24, 2024
              </time>
            </div>
          </div>
        </a>
        <a
          href="blog/bet-win-celebrate-dive-into-CrossGamings-10000-sports-tournament"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img
              src={props.images['sportsbook-tournament.jpg']}
              alt=""
              className="svelte-j1ieck"
            />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Bet, Win, Celebrate: Dive into CrossGaming’s $10,000 S
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                Ready to take your sports betting game to the next level?
                CrossGaming is excited to announce a thrilling sports
                tournament that will engage an...
              </p>
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Uncategorized
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2024-01-10T18:28:08+04:00"
              >
                January 10, 2024
              </time>
            </div>
          </div>
        </a>
        <a
          href="blog/solana-new-payment-method-at-CrossGaming"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img
              src={props.images["31113.png"]}
              alt=""
              className="svelte-j1ieck"
            />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Solana – New Payment Method at CrossGaming
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                Solana, an innovative digital currency, has now landed on
                CrossGaming, which is a significant leap forward. In addition
                to giving players more...
              </p>
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Cryptocurrency
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2023-12-27T12:51:57+04:00"
              >
                December 27, 2023
              </time>
            </div>
          </div>
        </a>
        <a
          href="blog/fj-changes-to-withdrawal"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img src={props.images['31113.jpg']} alt="" className="svelte-j1ieck" />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Minimum Withdrawal limit reduced to $50
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                We’ve got some exciting news about our withdrawal policy at
                CrossGaming! We’ve lowered the minimum withdrawal limit from
                $150 to j...
              </p>
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Casino News
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2023-12-15T16:24:31+04:00"
              >
                December 15, 2023
              </time>
            </div>
          </div>
        </a>
        <a
          href="blog/best-deposit-and-no-deposit-bonuses-from-CrossGaming"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img
              src={props.images['777-slot.jpg']}
              alt=""
              className="svelte-j1ieck"
            />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Best Deposit and No-Deposit Bonuses from CrossGaming
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                Free spins, Free bets, Deposit Bonuses and Cash backs
                exclusively on Silentbet.com, Betenemy.com, Nostrabet.com,
                Efirbet.com
              </p>
              ...
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Casino News
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2023-12-07T19:07:31+04:00"
              >
                December 7, 2023
              </time>
            </div>
          </div>
        </a>
        <a
          href="blog/slot-tournament-every-week-at-CrossGaming"
          className="blog-item svelte-j1ieck"
        >
          <div className="blog-item__image svelte-j1ieck">
            <img
              src={props.images['Slot-Tournament.jpg']}
              alt=""
              className="svelte-j1ieck"
            />
          </div>
          <div className="blog-item__body svelte-j1ieck">
            <h4 className="blog-item__body__title title svelte-j1ieck">
              Slot Tournament Every Week at CrossGaming
            </h4>
            <div className="blog-item__body__excerpt svelte-j1ieck">
              <p>
                Massive weekly slot tournaments have launched at
                up-10x.com. From five tournaments that have already
                concluded, $41,000 and 26,000 free s...
              </p>
            </div>
            <div className="blog-item__body__footer svelte-j1ieck">
              <span className="blog-item__body__category svelte-j1ieck">
                Casino News
              </span>{" "}
              <time
                className="blog-item__body__date svelte-j1ieck"
                dateTime="2023-12-01T17:53:09+04:00"
              >
                December 1, 2023
              </time>
            </div>
          </div>
        </a>
      </div>
      <div className="pagination svelte-1vxjt5l" style={{}}>
        <div className="pager svelte-1vxjt5l">
          <span className="page active svelte-1vxjt5l">1</span>{" "}
          <span className="page  svelte-1vxjt5l">2 </span>
          <span className="page  svelte-1vxjt5l">3 </span>
          <span className="separator svelte-1vxjt5l">...</span>{" "}
          <span className="page  svelte-1vxjt5l">18 </span>
        </div>
      </div>
    </div>
  </main>
  );
};
export default Blog;
