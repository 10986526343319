import { Link, useNavigate } from "react-router-dom";


const Security = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="backdrop svelte-1u3ulqr"></div>
      <div className="profile profile-container mx-auto svelte-1u3ulqr">
        <div className="close-button svelte-1u3ulqr" onClick={ () => navigate(-1)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4L4 12"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M4 4L12 12"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>

        <div className="profile-wrapper svelte-1u3ulqr">
          <div className="aside svelte-1u3ulqr check">
            <div className="player svelte-su3hdm">
              <div className="avatar svelte-su3hdm">
                <img
                  src="./assets/avatar.e65f5681.svg"
                  alt="avatar"
                  className="img svelte-su3hdm"
                />
              </div>
              <div className="info svelte-su3hdm">
                <div className="username svelte-su3hdm">vardanplay</div>
                <div className="id svelte-su3hdm">ID: 3320307</div>
                <div className="status svelte-su3hdm">Unverified</div>
              </div>
            </div>
            <div className="verify svelte-su3hdm">
              <div className="item svelte-1ddvoey">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.939 0C16.28 0 17.57 0.53 18.519 1.481C19.469 2.43 20 3.71 20 5.05V12.95C20 15.74 17.73 18 14.939 18H5.06C2.269 18 0 15.74 0 12.95V5.05C0 2.26 2.259 0 5.06 0H14.939ZM16.53 6.54L16.61 6.46C16.849 6.17 16.849 5.75 16.599 5.46C16.46 5.311 16.269 5.22 16.07 5.2C15.86 5.189 15.66 5.26 15.509 5.4L11 9C10.42 9.481 9.589 9.481 9 9L4.5 5.4C4.189 5.17 3.759 5.2 3.5 5.47C3.23 5.74 3.2 6.17 3.429 6.47L3.56 6.6L8.11 10.15C8.67 10.59 9.349 10.83 10.06 10.83C10.769 10.83 11.46 10.59 12.019 10.15L16.53 6.54Z"
                    fill="#3CCD53"
                  ></path>
                </svg>
                <div className="right svelte-1ddvoey">
                  <div className="div svelte-1ddvoey">
                    <span className="text svelte-1ddvoey">email</span>
                    <div className="icon mark svelte-1ddvoey">
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0058 2.34812C12.0196 2.881 11.807 3.22652 11.4989 3.5325C10.5265 4.50028 9.55745 5.47065 8.58672 6.44016C7.81306 7.21284 7.04284 7.98811 6.26488 8.75649C5.50843 9.50425 4.75112 9.49738 3.99811 8.74618C3.14098 7.89098 2.2804 7.03837 1.42928 6.17716C1.03686 5.78007 0.896586 5.29532 1.07903 4.75985C1.25975 4.2304 1.64701 3.90809 2.2038 3.82472C2.66938 3.7551 3.06869 3.91841 3.39829 4.24845C3.90603 4.75641 4.41893 5.25922 4.91463 5.77921C5.08244 5.95541 5.17366 5.97088 5.35611 5.78609C6.7795 4.34472 8.20979 2.91022 9.64867 1.48519C10.4284 0.713361 11.6882 1.06317 11.9662 2.11519C11.9929 2.21747 12.0006 2.32491 12.0049 2.34984L12.0058 2.34812Z"
                          fill="#FDFEFD"
                          stroke="#1B1B1F"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item not svelte-1ddvoey">
                <svg
                  width="11"
                  height="19"
                  viewBox="0 0 11 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_10547_59989)">
                    <path
                      d="M1.13047 19.0002C1.12145 18.9711 1.09608 18.9672 1.07015 18.959C0.377331 18.741 0.00865335 18.2539 0.00639844 17.5515C0.00470726 16.9985 0.0103445 16.4455 0.00301608 15.8925C0.0013249 15.764 0.0233102 15.7168 0.17157 15.7168C3.72361 15.7212 7.27566 15.7212 10.8277 15.7168C10.9743 15.7168 10.9996 15.7607 10.9979 15.8914C10.9906 16.4378 10.9957 16.9842 10.9946 17.5306C10.9929 18.2852 10.6022 18.7772 9.85301 18.9722C9.84343 18.9749 9.83723 18.9903 9.8299 19.0002H1.13047ZM5.50104 17.7454C5.8483 17.7454 6.19555 17.7475 6.54225 17.7448C6.81171 17.7426 6.98139 17.596 6.9859 17.3703C6.99097 17.1336 6.82073 16.9787 6.54112 16.9776C5.84661 16.9749 5.15266 16.9749 4.45815 16.9776C4.18981 16.9787 4.01901 17.1276 4.0145 17.3522C4.00942 17.5877 4.18136 17.7426 4.45928 17.7454C4.80653 17.7481 5.15379 17.7459 5.50048 17.7459L5.50104 17.7454Z"
                      fill="#CD453C"
                    ></path>
                    <path
                      d="M0.00556641 8.37503C0.00556641 6.2663 0.00838504 4.15702 0.000492866 2.04828C-7.08603e-05 1.87036 0.0512283 1.84126 0.219783 1.8418C3.74026 1.84675 7.26073 1.84675 10.7818 1.8418C10.9543 1.8418 10.9994 1.8764 10.9988 2.05048C10.9932 6.28112 10.9926 10.5118 10.9988 14.743C10.9988 14.9258 10.9452 14.9604 10.7699 14.9599C7.25622 14.9549 3.74195 14.9549 0.228238 14.9599C0.0489734 14.9599 0.000492866 14.9209 0.00105659 14.7408C0.00838504 12.6189 0.00556641 10.4969 0.00556641 8.37503Z"
                      fill="#CD453C"
                    ></path>
                    <path
                      d="M5.4998 2.07442C3.7359 2.07442 1.97256 2.07222 0.20866 2.07826C0.0451793 2.07881 -0.0128845 2.04696 0.0023361 1.88002C0.0277038 1.60215 -0.0241591 1.32099 0.0423607 1.04367C0.184984 0.445644 0.721651 0.00577631 1.3519 0.00412886C4.11754 -0.000813478 6.88375 -0.00191178 9.64939 0.00412886C10.3851 0.00577631 10.977 0.59721 10.9928 1.3144C10.9973 1.50935 10.982 1.70594 10.9984 1.89979C11.0114 2.05245 10.955 2.07717 10.8107 2.07717C9.09356 2.07222 7.37701 2.07387 5.6599 2.07387C5.60634 2.07387 5.55279 2.07387 5.4998 2.07387V2.07442Z"
                      fill="#CD453C"
                    ></path>
                  </g>
                  <defs>
                    <clippath id="clip0_10547_59989">
                      <rect width="11" height="19" rx="2.5" fill="white"></rect>
                    </clippath>
                  </defs>
                </svg>
                <div className="right svelte-1ddvoey">
                  <div className="svelte-1ddvoey">
                    <span className="text svelte-1ddvoey">phone</span>
                    <div className="icon close svelte-1ddvoey">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 4L4 12"
                          stroke="#ffffff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M4 4L12 12"
                          stroke="#ffffff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="svelte-1ddvoey">
                    <span className="text svelte-1ddvoey">Verify</span>
                    <div className="icon svelte-1ddvoey">
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_10600_3722)">
                          <path
                            d="M1.31553 1.37817L5.25 5.5L1.31553 9.62183"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                        <defs>
                          <clippath id="clip0_10600_3722">
                            <rect width="7" height="11" fill="white"></rect>
                          </clippath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs svelte-qp4ajj">
              <div className="tab svelte-qp4ajj">
                <div className="inner svelte-qp4ajj">
                  <div className="icon svelte-qp4ajj">
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 5.99943C16 9.32968 13.3311 12 10 12C6.67007 12 4 9.32968 4 5.99943C4 2.66919 6.67007 0 10 0C13.3311 0 16 2.66919 16 5.99943ZM10 22C4.57797 22 0 21.1737 0 17.9859C0 14.797 4.60673 14 10 14C15.4233 14 20 14.8263 20 18.0141C20 21.203 15.3933 22 10 22Z"
                        fill="#9C9CAB"
                        className="colored"
                      ></path>
                    </svg>
                  </div>{" "}
                  Profile
                </div>
              </div>
              <div className="tab svelte-qp4ajj">
                <div className="inner svelte-qp4ajj">
                  <div className="icon svelte-qp4ajj">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_12041_24769)">
                        <path
                          d="M9.18166 15.5846C9.18166 16.9253 9.18166 18.2669 9.18166 19.6077C9.18166 19.9515 9.13126 19.9991 8.77027 19.9991C6.58888 19.9991 4.40749 19.9991 2.2261 19.9991C1.4846 19.9991 1.10004 19.6296 1.10004 18.9162C1.10004 16.6698 1.10004 14.4243 1.10004 12.1779C1.10004 11.4794 1.4903 11.1004 2.21472 11.1004C4.37578 11.0997 6.53684 11.1004 8.69791 11.1004C9.08003 11.1004 9.18166 11.1981 9.18248 11.5614C9.18248 12.9022 9.18248 14.2438 9.18248 15.5846H9.18166Z"
                          fill="#9C9CAB"
                          className="colored"
                        ></path>
                        <path
                          d="M10.8183 15.5853C10.8183 14.2508 10.8183 12.9163 10.8183 11.5818C10.8183 11.1903 10.911 11.1004 11.3224 11.1004C13.477 11.1004 15.6307 11.0997 17.7853 11.1004C18.5097 11.1004 18.8999 11.4786 18.8999 12.1771C18.8999 14.4297 18.8999 16.6823 18.8999 18.935C18.8999 19.6225 18.5089 19.9991 17.795 19.9991C15.5998 19.9991 13.4054 19.9991 11.2102 19.9991C10.8769 19.9991 10.8175 19.9437 10.8175 19.6272C10.8175 18.2794 10.8175 16.9324 10.8175 15.5846L10.8183 15.5853Z"
                          fill="#9C9CAB"
                          className="colored"
                        ></path>
                        <path
                          d="M4.8766 10.0514C3.63102 10.0514 2.38463 10.0514 1.13905 10.0514C0.377227 10.0514 0.000788598 9.69039 0.000788598 8.96452C0.000788598 8.07926 -2.44434e-05 7.19478 0.000788598 6.30952C0.00160164 5.64382 0.391048 5.26487 1.07969 5.26409C3.63264 5.26252 6.18478 5.2633 8.73773 5.26409C9.08408 5.26409 9.18084 5.35863 9.18165 5.6946C9.18165 7.0221 9.18165 8.3496 9.18165 9.6771C9.18165 9.96776 9.09872 10.0506 8.79708 10.0514C7.49052 10.0529 6.18315 10.0514 4.8766 10.0521V10.0514Z"
                          fill="#9C9CAB"
                          className="colored"
                        ></path>
                        <path
                          d="M15.125 5.26299C16.3779 5.26299 17.6308 5.26299 18.8837 5.26299C19.6162 5.26299 19.9992 5.631 19.9992 6.33265C19.9992 7.22416 20 8.11567 19.9992 9.00718C19.9983 9.67132 19.6089 10.0495 18.917 10.0503C16.3779 10.0526 13.8379 10.0518 11.2988 10.0503C10.8793 10.0503 10.8183 9.98854 10.8183 9.58459C10.8183 8.28287 10.8183 6.98116 10.8183 5.67944C10.8183 5.34425 10.9012 5.26221 11.2443 5.26221C12.5379 5.26221 13.8314 5.26221 15.125 5.26221V5.26299Z"
                          fill="#9C9CAB"
                          className="colored"
                        ></path>
                        <path
                          d="M5.81893 4.56865C5.05711 4.54756 4.30667 4.47177 3.61071 4.13892C2.63099 3.67089 2.19683 2.7536 2.45619 1.7277C2.6066 1.13153 2.8806 0.616629 3.45216 0.297842C3.95869 0.0157773 4.51156 -0.0334472 5.08231 0.0189026C5.98397 0.101725 6.79214 0.443171 7.56859 0.86197C8.17675 1.19013 8.76051 1.55893 9.23289 2.06289C9.34915 2.18713 9.45729 2.32542 9.53859 2.4731C9.78576 2.9208 9.69063 3.38336 9.29712 3.72559C8.94019 4.03578 8.50115 4.1858 8.04829 4.30222C7.31818 4.48974 6.57343 4.56397 5.81893 4.56865ZM7.74665 2.8497C7.75234 2.81923 7.75884 2.78876 7.76453 2.75829C7.39541 2.53326 7.03524 2.29261 6.65555 2.08633C6.07504 1.77145 5.462 1.5308 4.78393 1.50658C4.55058 1.49798 4.26846 1.46438 4.13675 1.69097C3.866 2.15743 3.8034 2.63952 4.45302 2.8583C5.16199 3.09661 5.88722 3.09114 6.61652 3.02472C6.9954 2.99034 7.37021 2.90987 7.74665 2.8497Z"
                          fill="#9C9CAB"
                          className="colored"
                        ></path>
                        <path
                          d="M14.4965 4.57632C13.2664 4.55444 12.3338 4.46146 11.4419 4.13408C11.3037 4.08329 11.1679 4.02079 11.0403 3.94812C10.2321 3.48479 10.1256 2.76126 10.759 2.09087C11.2436 1.57753 11.8338 1.19702 12.455 0.861822C13.2314 0.442242 14.0412 0.100796 14.9437 0.0179736C15.5145 -0.0343763 16.069 0.0140668 16.5739 0.300819C17.2162 0.664924 17.5024 1.25093 17.5983 1.92679C17.765 3.10349 17.1674 3.97938 15.9324 4.31848C15.3779 4.47084 14.7941 4.52475 14.4965 4.57554V4.57632ZM12.2355 2.80267C12.329 2.83315 12.3769 2.85815 12.4265 2.86362C13.016 2.93316 13.6038 3.02614 14.1957 3.05817C14.6746 3.08396 15.1551 3.01989 15.6088 2.84252C16.0194 2.68157 16.1405 2.45498 16.0364 2.04165C16.0186 1.97211 15.9982 1.90335 15.9738 1.83616C15.9088 1.65254 15.7746 1.53378 15.5746 1.52518C15.3397 1.51502 15.099 1.50955 14.8689 1.54628C14.0575 1.6752 13.3567 2.05415 12.6835 2.48701C12.5404 2.57843 12.4062 2.68235 12.2363 2.80267H12.2355Z"
                          fill="#9C9CAB"
                          className="colored"
                        ></path>
                      </g>
                      <defs>
                        <clippath id="clip0_12041_24769">
                          <rect width="20" height="20" fill="white"></rect>
                        </clippath>
                      </defs>
                    </svg>
                  </div>{" "}
                  Bonuses
                </div>
              </div>
              <div className="tab svelte-qp4ajj">
                <div className="inner svelte-qp4ajj">
                  <div className="icon svelte-qp4ajj">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.939 0C16.28 0 17.57 0.53 18.519 1.481C19.469 2.43 20 3.71 20 5.05V12.95C20 15.74 17.73 18 14.939 18H5.06C2.269 18 0 15.74 0 12.95V5.05C0 2.26 2.259 0 5.06 0H14.939ZM16.53 6.54L16.61 6.46C16.849 6.17 16.849 5.75 16.599 5.46C16.46 5.311 16.269 5.22 16.07 5.2C15.86 5.189 15.66 5.26 15.509 5.4L11 9C10.42 9.481 9.589 9.481 9 9L4.5 5.4C4.189 5.17 3.759 5.2 3.5 5.47C3.23 5.74 3.2 6.17 3.429 6.47L3.56 6.6L8.11 10.15C8.67 10.59 9.349 10.83 10.06 10.83C10.769 10.83 11.46 10.59 12.019 10.15L16.53 6.54Z"
                        fill="#9C9CAB"
                        className="colored"
                      ></path>
                    </svg>
                  </div>{" "}
                  Inbox
                </div>
              </div>
              <div className="tab svelte-qp4ajj">
                <div className="inner svelte-qp4ajj">
                  <div className="icon svelte-qp4ajj">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="0.5"
                        width="20"
                        height="19"
                        rx="4"
                        fill="#9C9CAB"
                        className="colored"
                      ></rect>
                      <path
                        d="M11.9759 15.4705C11.8268 15.5962 11.7485 15.7654 11.7485 15.9396C11.7485 16.1138 11.8266 16.285 11.9744 16.416L11.9744 16.416L11.9763 16.4176C12.1331 16.5496 12.3195 16.6 12.5127 16.6C12.7103 16.6 12.8932 16.5358 13.045 16.4208L13.0451 16.421L13.0494 16.4173L15.6247 14.2272C15.6247 14.2272 15.6248 14.2271 15.6249 14.227C15.9283 13.9715 15.9283 13.5468 15.6249 13.2913C15.6248 13.2912 15.6247 13.2912 15.6247 13.2911L13.0359 11.0897L13.0356 11.0894C12.8965 10.9723 12.6985 10.8956 12.4992 10.8956C12.3015 10.8956 12.1186 10.9598 11.9668 11.0748L11.9668 11.0747L11.9628 11.078C11.824 11.1948 11.7249 11.3686 11.7249 11.5517C11.7249 11.7294 11.8058 11.9047 11.9493 12.0255L11.9497 12.0258L13.2345 13.0974L4.86667 13.0974C4.45212 13.0974 4.09242 13.3752 4.09242 13.7535C4.09242 14.1306 4.45072 14.4096 4.85319 14.4096L13.2133 14.4096L11.9759 15.4705Z"
                        fill="#26262C"
                        stroke="#26262C"
                        strokeWidth="0.2"
                      ></path>
                      <path
                        d="M7.78766 9.91198L7.78768 9.91201L7.7896 9.91039C7.93902 9.78464 8.01737 9.61533 8.01737 9.44091C8.01737 9.26675 7.93931 9.0955 7.79152 8.96458L7.79155 8.96456L7.7896 8.96292L6.54295 7.91372L14.8992 7.91372C15.3017 7.91372 15.66 7.63473 15.66 7.25761C15.66 6.88049 15.3017 6.60149 14.8992 6.60149L6.52756 6.60149L7.7896 5.52959C7.78965 5.52955 7.7897 5.5295 7.78976 5.52946C7.93315 5.40871 8.014 5.23343 8.014 5.05586C8.014 4.87419 7.92976 4.71143 7.77612 4.58213C7.63511 4.46345 7.44989 4.39975 7.25324 4.39975C7.0592 4.39975 6.85838 4.46201 6.71476 4.59527L4.12775 6.79522C4.12768 6.79528 4.12761 6.79534 4.12755 6.79539C3.82406 7.05101 3.82412 7.4758 4.12775 7.73134L6.71687 9.91039C7.01122 10.1581 7.48242 10.156 7.78766 9.91198Z"
                        fill="#26262C"
                        stroke="#26262C"
                        strokeWidth="0.2"
                      ></path>
                    </svg>
                  </div>{" "}
                  Transactions
                </div>
              </div>
              <div className="tab svelte-qp4ajj active">
                <div className="inner svelte-qp4ajj">
                  <div className="icon svelte-qp4ajj">
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9127 3.92101C19.626 2.79185 18.9155 2.05977 17.8185 1.76197C16.5844 1.42694 15.3254 1.2036 14.0912 0.918207C12.9693 0.657631 11.8474 0.434261 10.7255 0.161277C10.5884 0.13646 10.4637 0.124068 10.3017 0.0620267C10.1645 0.0248016 10.0773 0 9.97756 0C9.87784 0 9.77811 0.0248016 9.64099 0.0620267C9.20469 0.223335 8.75592 0.272981 8.30715 0.384656C7.62154 0.558373 6.93593 0.694859 6.25031 0.843759C5.21566 1.06711 4.16854 1.27802 3.14635 1.51378C2.62279 1.63787 2.08676 1.69994 1.6006 1.97292C0.703065 2.48167 0.154573 3.22616 0.0423816 4.25606C-0.019947 4.86407 0.0174502 5.47208 0.0174502 6.08009C0.0174502 6.91145 0.0548473 7.75522 0.00498446 8.59898C-0.019947 9.19459 0.0548473 9.77776 0.0922444 10.361C0.142107 11.0434 0.304162 11.7135 0.478682 12.3711C0.914982 13.9966 1.65046 15.498 2.62279 16.8753C3.22114 17.7315 3.93169 18.4884 4.70456 19.1833C5.65196 20.0271 6.69908 20.7343 7.83346 21.2927C8.43181 21.5781 9.03017 21.8883 9.69085 21.9752C9.79058 21.9876 9.8903 22 10.0025 22C10.4014 22 10.8003 21.8883 11.1867 21.7394C12.1217 21.3424 13.0192 20.8833 13.8544 20.3125C14.4528 19.903 15.0262 19.4687 15.5373 18.9724C16.6592 17.8928 17.6315 16.6892 18.3545 15.2995C18.9404 14.1951 19.3892 13.0412 19.651 11.8252C19.813 11.1179 19.9501 10.3982 19.9501 9.66609C19.9626 9.52959 20 8.53695 20 8.48731V4.59107C20 4.59107 19.9501 4.08232 19.9127 3.92101ZM15.5871 8.26394L10.0898 13.7236C9.8529 13.9718 9.52879 14.0959 9.21715 14.0959C8.90551 14.0959 8.5814 13.9718 8.33209 13.7236L5.35278 10.7704C4.86662 10.2865 4.86662 9.49237 5.35278 9.00844C5.83894 8.52452 6.63675 8.52452 7.12291 9.00844L9.21715 11.093L13.817 6.51439C14.3032 6.03046 15.101 6.03046 15.5871 6.51439C16.0733 6.99832 16.0733 7.78001 15.5871 8.26394Z"
                        fill="#9C9CAB"
                        className="colored"
                      ></path>
                    </svg>
                  </div>{" "}
                  Security
                </div>
              </div>
              <div
                className="tab mt-auto mb-28 svelte-qp4ajj"
                style={{ height: "auto", minHeight: "initial" }}
              >
                <div className="inner svelte-qp4ajj">
                  <div className="icon svelte-qp4ajj">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.89535 11.23C9.45785 11.23 9.11192 11.57 9.11192 12C9.11192 12.42 9.45785 12.77 9.89535 12.77H16V17.55C16 20 13.9753 22 11.4724 22H6.51744C4.02471 22 2 20.01 2 17.56V6.45C2 3.99 4.03488 2 6.52762 2H11.4927C13.9753 2 16 3.99 16 6.44V11.23H9.89535ZM19.6302 8.5402L22.5502 11.4502C22.7002 11.6002 22.7802 11.7902 22.7802 12.0002C22.7802 12.2002 22.7002 12.4002 22.5502 12.5402L19.6302 15.4502C19.4802 15.6002 19.2802 15.6802 19.0902 15.6802C18.8902 15.6802 18.6902 15.6002 18.5402 15.4502C18.2402 15.1502 18.2402 14.6602 18.5402 14.3602L20.1402 12.7702H16.0002V11.2302H20.1402L18.5402 9.6402C18.2402 9.3402 18.2402 8.8502 18.5402 8.5502C18.8402 8.2402 19.3302 8.2402 19.6302 8.5402Z"
                        fill="#9C9CAB"
                      ></path>
                    </svg>
                  </div>
                  Log out
                </div>
              </div>
            </div>
          </div>

          <div className="component svelte-1u3ulqr">
            <div className="back svelte-1u3ulqr">
              <img src="./assets/back.78537209.svg" alt="back icon" />
              Back
            </div>
            <div className="title svelte-1u3ulqr">
              <div className="text svelte-1u3ulqr">Security</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1 0 0px",
                position: "relative",
              }}
            >
              <div className="profile-content position-relative center svelte-1pclm5q">
                <div className="row flex-end svelte-1pclm5q">
                  <div className="info svelte-1pclm5q">
                    <img src="./assets/info.9f9ec299.svg" alt="icon" />{" "}
                    <span>Verify your account for extra security measures</span>
                  </div>
                </div>
                <div className="row svelte-1pclm5q">
                  <div className="item svelte-1pclm5q">
                    <div className="title svelte-1pclm5q">Change password</div>
                    <div className="description svelte-1pclm5q">
                      Set unique password to protect your personal account.
                    </div>
                    <div className="btn svelte-1pclm5q">
                      <button
                        className="primary standart normal svelte-uia73q"
                        type="button"
                        style={{}}
                      >
                        {" "}
                        Change Password{" "}
                      </button>
                    </div>
                  </div>
                  <div className="item svelte-1pclm5q">
                    <div className="title svelte-1pclm5q disabled">
                      SMS 2FA Activation
                    </div>
                    <div className="description svelte-1pclm5q">
                      Make your account extra secure with SMS 2FA.
                    </div>
                    <div className="btn svelte-1pclm5q">
                      <button
                        disabled=""
                        className="primary standart normal svelte-uia73q"
                        type="button"
                        style={{}}
                      >
                        {" "}
                        Activate SMS 2FA{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row svelte-1pclm5q">
                  <div className="item svelte-1pclm5q">
                    <div className="title svelte-1pclm5q disabled">
                      App 2FA Activation
                    </div>
                    <div className="description svelte-1pclm5q">
                      Make your account extra secure with App 2FA.
                    </div>
                    <div className="btn svelte-1pclm5q">
                      <button
                        disabled=""
                        className="primary standart normal svelte-uia73q"
                        type="button"
                        style={{}}
                      >
                        {" "}
                        Activate App 2FA{" "}
                      </button>
                    </div>
                  </div>
                  <div className="item svelte-1pclm5q">
                    <div className="title svelte-1pclm5q disabled">
                      Notification Control
                    </div>
                    <div className="description svelte-1pclm5q">
                      Receive messages from FortuneJack about new promotions,
                      bonuses and etc.
                    </div>
                    <div className="btn svelte-1pclm5q">
                      <button
                        disabled=""
                        className="primary standart normal svelte-uia73q"
                        type="button"
                        style={{}}
                      >
                        {" "}
                        Control Notifications{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Security;
