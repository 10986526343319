const LatestWins = (props) => {
  return (
    <div className="latest container mx-auto svelte-1naihr7">
      <div className="top svelte-1naihr7">
        <div className="title svelte-1naihr7">Latest Wins</div>
        <div className="tabs svelte-1naihr7">
          <div className="tab svelte-1naihr7">Latest wins </div>
          <div className="tab svelte-1naihr7 active">High rollers </div>
        </div>
      </div>
      <div className="table svelte-fgu4xw" style={{ position: "relative" }}>
        <div className="head svelte-fgu4xw">
          <div className="col th  svelte-fgu4xw">game</div>
          <div className="col th hide-on-mobile svelte-fgu4xw">player</div>
          <div className="col th hide-on-mobile svelte-fgu4xw">bet amount</div>
          <div className="col th  svelte-fgu4xw">Multiplier</div>
          <div className="col th  svelte-fgu4xw">Win</div>
        </div>
        <div className="body svelte-fgu4xw">
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4145 7.7237L16.7719 13.5808V4.99036C16.7719 4.80941 16.7719 4.63798 16.7217 4.46655L18.3092 5.10465C19.3743 5.54274 19.8767 6.71417 19.4145 7.7237Z"
                    fill="#FF7F37"
                  ></path>
                  <path
                    d="M15.0636 4.99048V13.6286C15.0334 13.3143 14.9631 12.9905 14.8325 12.6762L11.4464 4.13333C11.2756 3.71429 11.0244 3.32381 10.7129 3H12.9535C13.0138 3 13.0741 3 13.1344 3.00952C13.5966 3.04762 14.0186 3.22857 14.3401 3.49524C14.7822 3.86667 15.0636 4.4 15.0636 4.99048Z"
                    fill="#FF7F37"
                  ></path>
                  <path
                    d="M12.0088 15.8189L11.1749 16.1142L10.7026 16.2856L9.53709 16.6951L8.90409 16.9237L8.39165 17.1047L6.6534 17.7237L6.23139 17.8761C5.99025 17.9618 5.73906 17.9999 5.49791 17.9999C4.64385 17.9999 3.84004 17.5142 3.52856 16.7142L0.132429 8.17133C-0.269479 7.14275 0.273097 5.99037 1.35825 5.5999L2.97593 5.02847L3.85009 4.71418L4.78452 4.38085L7.13569 3.54275C7.37683 3.45704 7.62803 3.41895 7.87922 3.41895C7.98975 3.41895 8.10027 3.42847 8.2108 3.43799C8.49213 3.48561 8.76342 3.58085 9.00456 3.72371C9.26581 3.87609 9.48685 4.09514 9.65767 4.35228C9.73805 4.45704 9.79834 4.58085 9.84857 4.70466L13.2347 13.2475C13.6466 14.2761 13.094 15.4285 12.0088 15.8189Z"
                    fill="#FF7F37"
                  ></path>
                  <path
                    d="M9.28311 10.2004V10.2385C9.28311 10.2385 9.28311 10.248 9.28311 10.2575C9.30321 10.3718 9.32331 10.4861 9.32331 10.6004C9.32331 10.8195 9.27307 11.0195 9.17259 11.2099C9.09221 11.3718 8.98168 11.5242 8.84101 11.6575C8.59987 11.8861 8.29844 12.0099 7.96686 12.0575C7.68553 12.1052 7.41424 12.0575 7.153 11.9528C7.08266 11.9242 7.03242 11.9528 7.00228 12.0099C6.97214 12.0861 6.9219 12.1528 6.88171 12.2195C6.64056 12.5337 6.32908 12.7337 5.92718 12.8195C5.65589 12.8766 5.3846 12.8575 5.11331 12.7718C5.11331 12.7718 5.10326 12.7718 5.09322 12.7718C4.9626 12.7242 4.84202 12.6671 4.7315 12.6004C4.45016 12.4195 4.25925 12.1814 4.13868 11.8861C4.10854 11.8004 4.0784 11.7052 4.06835 11.6099C4.01811 11.3147 4.06835 11.0385 4.17887 10.7718C4.30949 10.4766 4.46021 10.1814 4.60088 9.87659C4.85207 9.33374 5.11331 8.8004 5.3645 8.26707C5.39465 8.20993 5.42479 8.15278 5.44489 8.08612C5.47503 8.00993 5.48508 8.0004 5.55541 8.02897C6.51999 8.44802 7.48457 8.85755 8.44915 9.27659C8.70035 9.38136 8.89125 9.55278 9.04197 9.76231C9.08216 9.81945 9.10226 9.89564 9.16254 9.93374L9.18264 9.99088C9.18264 10.0671 9.22283 10.1433 9.26302 10.2004H9.28311Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M9.28351 10.2002C9.23327 10.1336 9.20312 10.0669 9.20312 9.99072L9.28351 10.2002Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M6.80176 14.1718C6.80176 13.9527 6.87209 13.7241 6.91228 13.5051C6.97257 13.1241 7.0429 12.7337 7.10319 12.3527C7.10319 12.3337 7.12328 12.3051 7.12328 12.286C7.12328 12.2575 7.12328 12.2384 7.16348 12.2194C7.20367 12.2003 7.21371 12.2384 7.23381 12.2479C7.54529 12.486 7.85677 12.7241 8.1783 12.9718C8.3692 13.1241 8.56011 13.267 8.75102 13.4194C8.78116 13.4384 8.8314 13.467 8.82135 13.5051C8.80125 13.5527 8.74097 13.5337 8.70078 13.5432C8.27877 13.6194 7.86682 13.7432 7.47496 13.8956C7.24386 13.9813 7.0429 14.1051 6.8319 14.2289L6.81181 14.1908L6.80176 14.1718Z"
                    fill="#27282B"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Craps</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              u******a
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">100.00400016</span>{" "}
              <img
                src={props.images["usdt.b5bcf110.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">1.63x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+162.5$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4145 7.7237L16.7719 13.5808V4.99036C16.7719 4.80941 16.7719 4.63798 16.7217 4.46655L18.3092 5.10465C19.3743 5.54274 19.8767 6.71417 19.4145 7.7237Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M15.0636 4.99048V13.6286C15.0334 13.3143 14.9631 12.9905 14.8325 12.6762L11.4464 4.13333C11.2756 3.71429 11.0244 3.32381 10.7129 3H12.9535C13.0138 3 13.0741 3 13.1344 3.00952C13.5966 3.04762 14.0186 3.22857 14.3401 3.49524C14.7822 3.86667 15.0636 4.4 15.0636 4.99048Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M12.0088 15.8189L11.1749 16.1142L10.7026 16.2856L9.53709 16.6951L8.90409 16.9237L8.39165 17.1047L6.6534 17.7237L6.23139 17.8761C5.99025 17.9618 5.73906 17.9999 5.49791 17.9999C4.64385 17.9999 3.84004 17.5142 3.52856 16.7142L0.132429 8.17133C-0.269479 7.14275 0.273097 5.99037 1.35825 5.5999L2.97593 5.02847L3.85009 4.71418L4.78452 4.38085L7.13569 3.54275C7.37683 3.45704 7.62803 3.41895 7.87922 3.41895C7.98975 3.41895 8.10027 3.42847 8.2108 3.43799C8.49213 3.48561 8.76342 3.58085 9.00456 3.72371C9.26581 3.87609 9.48685 4.09514 9.65767 4.35228C9.73805 4.45704 9.79834 4.58085 9.84857 4.70466L13.2347 13.2475C13.6466 14.2761 13.094 15.4285 12.0088 15.8189Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M9.28311 10.2004V10.2385C9.28311 10.2385 9.28311 10.248 9.28311 10.2575C9.30321 10.3718 9.32331 10.4861 9.32331 10.6004C9.32331 10.8195 9.27307 11.0195 9.17259 11.2099C9.09221 11.3718 8.98168 11.5242 8.84101 11.6575C8.59987 11.8861 8.29844 12.0099 7.96686 12.0575C7.68553 12.1052 7.41424 12.0575 7.153 11.9528C7.08266 11.9242 7.03242 11.9528 7.00228 12.0099C6.97214 12.0861 6.9219 12.1528 6.88171 12.2195C6.64056 12.5337 6.32908 12.7337 5.92718 12.8195C5.65589 12.8766 5.3846 12.8575 5.11331 12.7718C5.11331 12.7718 5.10326 12.7718 5.09322 12.7718C4.9626 12.7242 4.84202 12.6671 4.7315 12.6004C4.45016 12.4195 4.25925 12.1814 4.13868 11.8861C4.10854 11.8004 4.0784 11.7052 4.06835 11.6099C4.01811 11.3147 4.06835 11.0385 4.17887 10.7718C4.30949 10.4766 4.46021 10.1814 4.60088 9.87659C4.85207 9.33374 5.11331 8.8004 5.3645 8.26707C5.39465 8.20993 5.42479 8.15278 5.44489 8.08612C5.47503 8.00993 5.48508 8.0004 5.55541 8.02897C6.51999 8.44802 7.48457 8.85755 8.44915 9.27659C8.70035 9.38136 8.89125 9.55278 9.04197 9.76231C9.08216 9.81945 9.10226 9.89564 9.16254 9.93374L9.18264 9.99088C9.18264 10.0671 9.22283 10.1433 9.26302 10.2004H9.28311Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M9.28351 10.2002C9.23327 10.1336 9.20312 10.0669 9.20312 9.99072L9.28351 10.2002Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M6.80176 14.1718C6.80176 13.9527 6.87209 13.7241 6.91228 13.5051C6.97257 13.1241 7.0429 12.7337 7.10319 12.3527C7.10319 12.3337 7.12328 12.3051 7.12328 12.286C7.12328 12.2575 7.12328 12.2384 7.16348 12.2194C7.20367 12.2003 7.21371 12.2384 7.23381 12.2479C7.54529 12.486 7.85677 12.7241 8.1783 12.9718C8.3692 13.1241 8.56011 13.267 8.75102 13.4194C8.78116 13.4384 8.8314 13.467 8.82135 13.5051C8.80125 13.5527 8.74097 13.5337 8.70078 13.5432C8.27877 13.6194 7.86682 13.7432 7.47496 13.8956C7.24386 13.9813 7.0429 14.1051 6.8319 14.2289L6.81181 14.1908L6.80176 14.1718Z"
                    fill="#27282B"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Lightning Roulette</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              J******7
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">221.00685449</span>{" "}
              <img
                src={props.images["usdt.b5bcf110.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">3.12x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+690$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4145 7.7237L16.7719 13.5808V4.99036C16.7719 4.80941 16.7719 4.63798 16.7217 4.46655L18.3092 5.10465C19.3743 5.54274 19.8767 6.71417 19.4145 7.7237Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M15.0636 4.99048V13.6286C15.0334 13.3143 14.9631 12.9905 14.8325 12.6762L11.4464 4.13333C11.2756 3.71429 11.0244 3.32381 10.7129 3H12.9535C13.0138 3 13.0741 3 13.1344 3.00952C13.5966 3.04762 14.0186 3.22857 14.3401 3.49524C14.7822 3.86667 15.0636 4.4 15.0636 4.99048Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M12.0088 15.8189L11.1749 16.1142L10.7026 16.2856L9.53709 16.6951L8.90409 16.9237L8.39165 17.1047L6.6534 17.7237L6.23139 17.8761C5.99025 17.9618 5.73906 17.9999 5.49791 17.9999C4.64385 17.9999 3.84004 17.5142 3.52856 16.7142L0.132429 8.17133C-0.269479 7.14275 0.273097 5.99037 1.35825 5.5999L2.97593 5.02847L3.85009 4.71418L4.78452 4.38085L7.13569 3.54275C7.37683 3.45704 7.62803 3.41895 7.87922 3.41895C7.98975 3.41895 8.10027 3.42847 8.2108 3.43799C8.49213 3.48561 8.76342 3.58085 9.00456 3.72371C9.26581 3.87609 9.48685 4.09514 9.65767 4.35228C9.73805 4.45704 9.79834 4.58085 9.84857 4.70466L13.2347 13.2475C13.6466 14.2761 13.094 15.4285 12.0088 15.8189Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M9.28311 10.2004V10.2385C9.28311 10.2385 9.28311 10.248 9.28311 10.2575C9.30321 10.3718 9.32331 10.4861 9.32331 10.6004C9.32331 10.8195 9.27307 11.0195 9.17259 11.2099C9.09221 11.3718 8.98168 11.5242 8.84101 11.6575C8.59987 11.8861 8.29844 12.0099 7.96686 12.0575C7.68553 12.1052 7.41424 12.0575 7.153 11.9528C7.08266 11.9242 7.03242 11.9528 7.00228 12.0099C6.97214 12.0861 6.9219 12.1528 6.88171 12.2195C6.64056 12.5337 6.32908 12.7337 5.92718 12.8195C5.65589 12.8766 5.3846 12.8575 5.11331 12.7718C5.11331 12.7718 5.10326 12.7718 5.09322 12.7718C4.9626 12.7242 4.84202 12.6671 4.7315 12.6004C4.45016 12.4195 4.25925 12.1814 4.13868 11.8861C4.10854 11.8004 4.0784 11.7052 4.06835 11.6099C4.01811 11.3147 4.06835 11.0385 4.17887 10.7718C4.30949 10.4766 4.46021 10.1814 4.60088 9.87659C4.85207 9.33374 5.11331 8.8004 5.3645 8.26707C5.39465 8.20993 5.42479 8.15278 5.44489 8.08612C5.47503 8.00993 5.48508 8.0004 5.55541 8.02897C6.51999 8.44802 7.48457 8.85755 8.44915 9.27659C8.70035 9.38136 8.89125 9.55278 9.04197 9.76231C9.08216 9.81945 9.10226 9.89564 9.16254 9.93374L9.18264 9.99088C9.18264 10.0671 9.22283 10.1433 9.26302 10.2004H9.28311Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M9.28351 10.2002C9.23327 10.1336 9.20312 10.0669 9.20312 9.99072L9.28351 10.2002Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M6.80176 14.1718C6.80176 13.9527 6.87209 13.7241 6.91228 13.5051C6.97257 13.1241 7.0429 12.7337 7.10319 12.3527C7.10319 12.3337 7.12328 12.3051 7.12328 12.286C7.12328 12.2575 7.12328 12.2384 7.16348 12.2194C7.20367 12.2003 7.21371 12.2384 7.23381 12.2479C7.54529 12.486 7.85677 12.7241 8.1783 12.9718C8.3692 13.1241 8.56011 13.267 8.75102 13.4194C8.78116 13.4384 8.8314 13.467 8.82135 13.5051C8.80125 13.5527 8.74097 13.5337 8.70078 13.5432C8.27877 13.6194 7.86682 13.7432 7.47496 13.8956C7.24386 13.9813 7.0429 14.1051 6.8319 14.2289L6.81181 14.1908L6.80176 14.1718Z"
                    fill="#27282B"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Lightning Roulette</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              J******7
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">197.00961016</span>{" "}
              <img
                src={props.images["usdt.b5bcf110.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">3.12x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+615$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4145 7.7237L16.7719 13.5808V4.99036C16.7719 4.80941 16.7719 4.63798 16.7217 4.46655L18.3092 5.10465C19.3743 5.54274 19.8767 6.71417 19.4145 7.7237Z"
                    fill="#FDCF39"
                  ></path>
                  <path
                    d="M15.0636 4.99048V13.6286C15.0334 13.3143 14.9631 12.9905 14.8325 12.6762L11.4464 4.13333C11.2756 3.71429 11.0244 3.32381 10.7129 3H12.9535C13.0138 3 13.0741 3 13.1344 3.00952C13.5966 3.04762 14.0186 3.22857 14.3401 3.49524C14.7822 3.86667 15.0636 4.4 15.0636 4.99048Z"
                    fill="#FDCF39"
                  ></path>
                  <path
                    d="M12.0088 15.8189L11.1749 16.1142L10.7026 16.2856L9.53709 16.6951L8.90409 16.9237L8.39165 17.1047L6.6534 17.7237L6.23139 17.8761C5.99025 17.9618 5.73906 17.9999 5.49791 17.9999C4.64385 17.9999 3.84004 17.5142 3.52856 16.7142L0.132429 8.17133C-0.269479 7.14275 0.273097 5.99037 1.35825 5.5999L2.97593 5.02847L3.85009 4.71418L4.78452 4.38085L7.13569 3.54275C7.37683 3.45704 7.62803 3.41895 7.87922 3.41895C7.98975 3.41895 8.10027 3.42847 8.2108 3.43799C8.49213 3.48561 8.76342 3.58085 9.00456 3.72371C9.26581 3.87609 9.48685 4.09514 9.65767 4.35228C9.73805 4.45704 9.79834 4.58085 9.84857 4.70466L13.2347 13.2475C13.6466 14.2761 13.094 15.4285 12.0088 15.8189Z"
                    fill="#FDCF39"
                  ></path>
                  <path
                    d="M9.28311 10.2004V10.2385C9.28311 10.2385 9.28311 10.248 9.28311 10.2575C9.30321 10.3718 9.32331 10.4861 9.32331 10.6004C9.32331 10.8195 9.27307 11.0195 9.17259 11.2099C9.09221 11.3718 8.98168 11.5242 8.84101 11.6575C8.59987 11.8861 8.29844 12.0099 7.96686 12.0575C7.68553 12.1052 7.41424 12.0575 7.153 11.9528C7.08266 11.9242 7.03242 11.9528 7.00228 12.0099C6.97214 12.0861 6.9219 12.1528 6.88171 12.2195C6.64056 12.5337 6.32908 12.7337 5.92718 12.8195C5.65589 12.8766 5.3846 12.8575 5.11331 12.7718C5.11331 12.7718 5.10326 12.7718 5.09322 12.7718C4.9626 12.7242 4.84202 12.6671 4.7315 12.6004C4.45016 12.4195 4.25925 12.1814 4.13868 11.8861C4.10854 11.8004 4.0784 11.7052 4.06835 11.6099C4.01811 11.3147 4.06835 11.0385 4.17887 10.7718C4.30949 10.4766 4.46021 10.1814 4.60088 9.87659C4.85207 9.33374 5.11331 8.8004 5.3645 8.26707C5.39465 8.20993 5.42479 8.15278 5.44489 8.08612C5.47503 8.00993 5.48508 8.0004 5.55541 8.02897C6.51999 8.44802 7.48457 8.85755 8.44915 9.27659C8.70035 9.38136 8.89125 9.55278 9.04197 9.76231C9.08216 9.81945 9.10226 9.89564 9.16254 9.93374L9.18264 9.99088C9.18264 10.0671 9.22283 10.1433 9.26302 10.2004H9.28311Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M9.28351 10.2002C9.23327 10.1336 9.20312 10.0669 9.20312 9.99072L9.28351 10.2002Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M6.80176 14.1718C6.80176 13.9527 6.87209 13.7241 6.91228 13.5051C6.97257 13.1241 7.0429 12.7337 7.10319 12.3527C7.10319 12.3337 7.12328 12.3051 7.12328 12.286C7.12328 12.2575 7.12328 12.2384 7.16348 12.2194C7.20367 12.2003 7.21371 12.2384 7.23381 12.2479C7.54529 12.486 7.85677 12.7241 8.1783 12.9718C8.3692 13.1241 8.56011 13.267 8.75102 13.4194C8.78116 13.4384 8.8314 13.467 8.82135 13.5051C8.80125 13.5527 8.74097 13.5337 8.70078 13.5432C8.27877 13.6194 7.86682 13.7432 7.47496 13.8956C7.24386 13.9813 7.0429 14.1051 6.8319 14.2289L6.81181 14.1908L6.80176 14.1718Z"
                    fill="#27282B"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Craps</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              u******a
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">100.00400016</span>{" "}
              <img
                src={props.images["usdt.b5bcf110.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">1.45x</div>
            <div className="col td green weight-500 svelte-fgu4xw">
              +145.45$
            </div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9539 8.19868C18.7448 6.59682 18.3065 5.49731 17.8085 5.49731H17.1113C17.1113 5.49731 17.1312 5.53523 17.1312 5.56366C17.3802 6.29351 17.5695 7.0897 17.689 7.93328C17.8085 8.71999 17.8683 9.5541 17.8683 10.4072C17.8683 11.2602 17.8085 12.0375 17.689 12.8147C17.5695 13.6772 17.3802 14.4924 17.1312 15.2412C17.1312 15.2601 17.1213 15.2886 17.1113 15.3075H17.8085C18.3065 15.3075 18.7448 14.208 18.9539 12.6062C19.5316 12.6062 19.9997 11.6204 19.9997 10.4072C19.9997 9.19392 19.5316 8.19868 18.9539 8.19868Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M15.3191 5.4976C15.1796 5.15637 15.0302 4.83411 14.8609 4.54027C14.6717 4.20853 14.3131 4 13.9147 4H12.9386C10.8171 4 7.15185 4 5.90684 4.00948C5.63792 4.00948 5.38892 4.1327 5.24948 4.3507C5.04032 4.69193 4.85108 5.07107 4.68176 5.4976C4.6718 5.51656 4.66184 5.53551 4.65188 5.56395C4.13396 6.88145 3.8252 8.56862 3.8252 10.4074C3.8252 12.2463 4.13396 13.9145 4.66184 15.2415C4.66184 15.2604 4.6718 15.2889 4.68176 15.3078C4.8212 15.649 4.9706 15.9713 5.12996 16.2557C5.3192 16.5874 5.67776 16.7959 6.08613 16.7959C7.47057 16.8054 10.8968 16.8054 12.9386 16.8054H13.9147C14.3131 16.8054 14.6717 16.5969 14.8609 16.2651C15.0302 15.9713 15.1796 15.649 15.3191 15.3078C15.329 15.2889 15.339 15.2604 15.339 15.2415C15.8669 13.9145 16.1756 12.2368 16.1756 10.4074C16.1756 8.5781 15.8669 6.89093 15.339 5.56395C15.339 5.54499 15.339 5.51656 15.3191 5.4976ZM8.18769 9.08994C8.12793 9.08994 8.07813 9.07098 8.03829 9.03307C7.99845 8.99515 7.97853 8.94776 7.97853 8.89089V8.13261C7.97853 8.07574 7.99845 8.02835 8.03829 7.99991C8.07813 7.962 8.12793 7.94304 8.18769 7.94304H11.9128C11.9725 7.94304 12.0223 7.962 12.0622 7.99991C12.102 8.02835 12.112 8.07574 12.112 8.13261V8.80558C12.112 8.90985 12.092 9.02359 12.0323 9.14681L10.1897 13.0709C10.1299 13.1941 10.0403 13.251 9.91078 13.251H8.8849C8.8849 13.251 8.79526 13.232 8.76538 13.2036C8.72554 13.1657 8.71558 13.1278 8.71558 13.0804C8.71558 13.0614 8.71558 13.033 8.72554 13.014L10.5681 9.08994H8.18769Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M2.86849 15.2414C2.61949 14.5116 2.43025 13.7154 2.31073 12.8718C2.19121 12.0851 2.13145 11.2605 2.13145 10.4074C2.13145 9.55434 2.19121 8.75815 2.31073 7.98092C2.43025 7.11838 2.61949 6.30323 2.87845 5.56391C2.87845 5.53547 2.87845 5.51652 2.89837 5.49756H2.22109C1.71313 5.49756 1.27489 6.59706 1.07568 8.19892H1.0458C0.478082 8.19892 0 9.18468 0 10.4074C0 11.6301 0.478082 12.6064 1.0458 12.6064H1.06572C1.27489 14.2083 1.71313 15.3078 2.22109 15.3078H2.88841L2.86849 15.2414Z"
                    fill="#2EACB3"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Jumbo Joker</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              K******n
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">0.00049760</span>{" "}
              <img
                src={props.images["btc.3efa24b4.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">94x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+3008$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4145 7.7237L16.7719 13.5808V4.99036C16.7719 4.80941 16.7719 4.63798 16.7217 4.46655L18.3092 5.10465C19.3743 5.54274 19.8767 6.71417 19.4145 7.7237Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M15.0636 4.99048V13.6286C15.0334 13.3143 14.9631 12.9905 14.8325 12.6762L11.4464 4.13333C11.2756 3.71429 11.0244 3.32381 10.7129 3H12.9535C13.0138 3 13.0741 3 13.1344 3.00952C13.5966 3.04762 14.0186 3.22857 14.3401 3.49524C14.7822 3.86667 15.0636 4.4 15.0636 4.99048Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M12.0088 15.8189L11.1749 16.1142L10.7026 16.2856L9.53709 16.6951L8.90409 16.9237L8.39165 17.1047L6.6534 17.7237L6.23139 17.8761C5.99025 17.9618 5.73906 17.9999 5.49791 17.9999C4.64385 17.9999 3.84004 17.5142 3.52856 16.7142L0.132429 8.17133C-0.269479 7.14275 0.273097 5.99037 1.35825 5.5999L2.97593 5.02847L3.85009 4.71418L4.78452 4.38085L7.13569 3.54275C7.37683 3.45704 7.62803 3.41895 7.87922 3.41895C7.98975 3.41895 8.10027 3.42847 8.2108 3.43799C8.49213 3.48561 8.76342 3.58085 9.00456 3.72371C9.26581 3.87609 9.48685 4.09514 9.65767 4.35228C9.73805 4.45704 9.79834 4.58085 9.84857 4.70466L13.2347 13.2475C13.6466 14.2761 13.094 15.4285 12.0088 15.8189Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M9.28311 10.2004V10.2385C9.28311 10.2385 9.28311 10.248 9.28311 10.2575C9.30321 10.3718 9.32331 10.4861 9.32331 10.6004C9.32331 10.8195 9.27307 11.0195 9.17259 11.2099C9.09221 11.3718 8.98168 11.5242 8.84101 11.6575C8.59987 11.8861 8.29844 12.0099 7.96686 12.0575C7.68553 12.1052 7.41424 12.0575 7.153 11.9528C7.08266 11.9242 7.03242 11.9528 7.00228 12.0099C6.97214 12.0861 6.9219 12.1528 6.88171 12.2195C6.64056 12.5337 6.32908 12.7337 5.92718 12.8195C5.65589 12.8766 5.3846 12.8575 5.11331 12.7718C5.11331 12.7718 5.10326 12.7718 5.09322 12.7718C4.9626 12.7242 4.84202 12.6671 4.7315 12.6004C4.45016 12.4195 4.25925 12.1814 4.13868 11.8861C4.10854 11.8004 4.0784 11.7052 4.06835 11.6099C4.01811 11.3147 4.06835 11.0385 4.17887 10.7718C4.30949 10.4766 4.46021 10.1814 4.60088 9.87659C4.85207 9.33374 5.11331 8.8004 5.3645 8.26707C5.39465 8.20993 5.42479 8.15278 5.44489 8.08612C5.47503 8.00993 5.48508 8.0004 5.55541 8.02897C6.51999 8.44802 7.48457 8.85755 8.44915 9.27659C8.70035 9.38136 8.89125 9.55278 9.04197 9.76231C9.08216 9.81945 9.10226 9.89564 9.16254 9.93374L9.18264 9.99088C9.18264 10.0671 9.22283 10.1433 9.26302 10.2004H9.28311Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M9.28351 10.2002C9.23327 10.1336 9.20312 10.0669 9.20312 9.99072L9.28351 10.2002Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M6.80176 14.1718C6.80176 13.9527 6.87209 13.7241 6.91228 13.5051C6.97257 13.1241 7.0429 12.7337 7.10319 12.3527C7.10319 12.3337 7.12328 12.3051 7.12328 12.286C7.12328 12.2575 7.12328 12.2384 7.16348 12.2194C7.20367 12.2003 7.21371 12.2384 7.23381 12.2479C7.54529 12.486 7.85677 12.7241 8.1783 12.9718C8.3692 13.1241 8.56011 13.267 8.75102 13.4194C8.78116 13.4384 8.8314 13.467 8.82135 13.5051C8.80125 13.5527 8.74097 13.5337 8.70078 13.5432C8.27877 13.6194 7.86682 13.7432 7.47496 13.8956C7.24386 13.9813 7.0429 14.1051 6.8319 14.2289L6.81181 14.1908L6.80176 14.1718Z"
                    fill="#27282B"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Lightning Roulette</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              J******7
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">145.00688778</span>{" "}
              <img
                src={props.images["usdt.b5bcf110.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">4.14x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+600$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4145 7.7237L16.7719 13.5808V4.99036C16.7719 4.80941 16.7719 4.63798 16.7217 4.46655L18.3092 5.10465C19.3743 5.54274 19.8767 6.71417 19.4145 7.7237Z"
                    fill="#E74E5D"
                  ></path>
                  <path
                    d="M15.0636 4.99048V13.6286C15.0334 13.3143 14.9631 12.9905 14.8325 12.6762L11.4464 4.13333C11.2756 3.71429 11.0244 3.32381 10.7129 3H12.9535C13.0138 3 13.0741 3 13.1344 3.00952C13.5966 3.04762 14.0186 3.22857 14.3401 3.49524C14.7822 3.86667 15.0636 4.4 15.0636 4.99048Z"
                    fill="#E74E5D"
                  ></path>
                  <path
                    d="M12.0088 15.8189L11.1749 16.1142L10.7026 16.2856L9.53709 16.6951L8.90409 16.9237L8.39165 17.1047L6.6534 17.7237L6.23139 17.8761C5.99025 17.9618 5.73906 17.9999 5.49791 17.9999C4.64385 17.9999 3.84004 17.5142 3.52856 16.7142L0.132429 8.17133C-0.269479 7.14275 0.273097 5.99037 1.35825 5.5999L2.97593 5.02847L3.85009 4.71418L4.78452 4.38085L7.13569 3.54275C7.37683 3.45704 7.62803 3.41895 7.87922 3.41895C7.98975 3.41895 8.10027 3.42847 8.2108 3.43799C8.49213 3.48561 8.76342 3.58085 9.00456 3.72371C9.26581 3.87609 9.48685 4.09514 9.65767 4.35228C9.73805 4.45704 9.79834 4.58085 9.84857 4.70466L13.2347 13.2475C13.6466 14.2761 13.094 15.4285 12.0088 15.8189Z"
                    fill="#E74E5D"
                  ></path>
                  <path
                    d="M9.28311 10.2004V10.2385C9.28311 10.2385 9.28311 10.248 9.28311 10.2575C9.30321 10.3718 9.32331 10.4861 9.32331 10.6004C9.32331 10.8195 9.27307 11.0195 9.17259 11.2099C9.09221 11.3718 8.98168 11.5242 8.84101 11.6575C8.59987 11.8861 8.29844 12.0099 7.96686 12.0575C7.68553 12.1052 7.41424 12.0575 7.153 11.9528C7.08266 11.9242 7.03242 11.9528 7.00228 12.0099C6.97214 12.0861 6.9219 12.1528 6.88171 12.2195C6.64056 12.5337 6.32908 12.7337 5.92718 12.8195C5.65589 12.8766 5.3846 12.8575 5.11331 12.7718C5.11331 12.7718 5.10326 12.7718 5.09322 12.7718C4.9626 12.7242 4.84202 12.6671 4.7315 12.6004C4.45016 12.4195 4.25925 12.1814 4.13868 11.8861C4.10854 11.8004 4.0784 11.7052 4.06835 11.6099C4.01811 11.3147 4.06835 11.0385 4.17887 10.7718C4.30949 10.4766 4.46021 10.1814 4.60088 9.87659C4.85207 9.33374 5.11331 8.8004 5.3645 8.26707C5.39465 8.20993 5.42479 8.15278 5.44489 8.08612C5.47503 8.00993 5.48508 8.0004 5.55541 8.02897C6.51999 8.44802 7.48457 8.85755 8.44915 9.27659C8.70035 9.38136 8.89125 9.55278 9.04197 9.76231C9.08216 9.81945 9.10226 9.89564 9.16254 9.93374L9.18264 9.99088C9.18264 10.0671 9.22283 10.1433 9.26302 10.2004H9.28311Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M9.28351 10.2002C9.23327 10.1336 9.20312 10.0669 9.20312 9.99072L9.28351 10.2002Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M6.80176 14.1718C6.80176 13.9527 6.87209 13.7241 6.91228 13.5051C6.97257 13.1241 7.0429 12.7337 7.10319 12.3527C7.10319 12.3337 7.12328 12.3051 7.12328 12.286C7.12328 12.2575 7.12328 12.2384 7.16348 12.2194C7.20367 12.2003 7.21371 12.2384 7.23381 12.2479C7.54529 12.486 7.85677 12.7241 8.1783 12.9718C8.3692 13.1241 8.56011 13.267 8.75102 13.4194C8.78116 13.4384 8.8314 13.467 8.82135 13.5051C8.80125 13.5527 8.74097 13.5337 8.70078 13.5432C8.27877 13.6194 7.86682 13.7432 7.47496 13.8956C7.24386 13.9813 7.0429 14.1051 6.8319 14.2289L6.81181 14.1908L6.80176 14.1718Z"
                    fill="#27282B"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Lightning Roulette</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              J******7
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">190.00608026</span>{" "}
              <img
                src={props.images["usdt.b5bcf110.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">3.95x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+750$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9539 8.19868C18.7448 6.59682 18.3065 5.49731 17.8085 5.49731H17.1113C17.1113 5.49731 17.1312 5.53523 17.1312 5.56366C17.3802 6.29351 17.5695 7.0897 17.689 7.93328C17.8085 8.71999 17.8683 9.5541 17.8683 10.4072C17.8683 11.2602 17.8085 12.0375 17.689 12.8147C17.5695 13.6772 17.3802 14.4924 17.1312 15.2412C17.1312 15.2601 17.1213 15.2886 17.1113 15.3075H17.8085C18.3065 15.3075 18.7448 14.208 18.9539 12.6062C19.5316 12.6062 19.9997 11.6204 19.9997 10.4072C19.9997 9.19392 19.5316 8.19868 18.9539 8.19868Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M15.3191 5.4976C15.1796 5.15637 15.0302 4.83411 14.8609 4.54027C14.6717 4.20853 14.3131 4 13.9147 4H12.9386C10.8171 4 7.15185 4 5.90684 4.00948C5.63792 4.00948 5.38892 4.1327 5.24948 4.3507C5.04032 4.69193 4.85108 5.07107 4.68176 5.4976C4.6718 5.51656 4.66184 5.53551 4.65188 5.56395C4.13396 6.88145 3.8252 8.56862 3.8252 10.4074C3.8252 12.2463 4.13396 13.9145 4.66184 15.2415C4.66184 15.2604 4.6718 15.2889 4.68176 15.3078C4.8212 15.649 4.9706 15.9713 5.12996 16.2557C5.3192 16.5874 5.67776 16.7959 6.08613 16.7959C7.47057 16.8054 10.8968 16.8054 12.9386 16.8054H13.9147C14.3131 16.8054 14.6717 16.5969 14.8609 16.2651C15.0302 15.9713 15.1796 15.649 15.3191 15.3078C15.329 15.2889 15.339 15.2604 15.339 15.2415C15.8669 13.9145 16.1756 12.2368 16.1756 10.4074C16.1756 8.5781 15.8669 6.89093 15.339 5.56395C15.339 5.54499 15.339 5.51656 15.3191 5.4976ZM8.18769 9.08994C8.12793 9.08994 8.07813 9.07098 8.03829 9.03307C7.99845 8.99515 7.97853 8.94776 7.97853 8.89089V8.13261C7.97853 8.07574 7.99845 8.02835 8.03829 7.99991C8.07813 7.962 8.12793 7.94304 8.18769 7.94304H11.9128C11.9725 7.94304 12.0223 7.962 12.0622 7.99991C12.102 8.02835 12.112 8.07574 12.112 8.13261V8.80558C12.112 8.90985 12.092 9.02359 12.0323 9.14681L10.1897 13.0709C10.1299 13.1941 10.0403 13.251 9.91078 13.251H8.8849C8.8849 13.251 8.79526 13.232 8.76538 13.2036C8.72554 13.1657 8.71558 13.1278 8.71558 13.0804C8.71558 13.0614 8.71558 13.033 8.72554 13.014L10.5681 9.08994H8.18769Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M2.86849 15.2414C2.61949 14.5116 2.43025 13.7154 2.31073 12.8718C2.19121 12.0851 2.13145 11.2605 2.13145 10.4074C2.13145 9.55434 2.19121 8.75815 2.31073 7.98092C2.43025 7.11838 2.61949 6.30323 2.87845 5.56391C2.87845 5.53547 2.87845 5.51652 2.89837 5.49756H2.22109C1.71313 5.49756 1.27489 6.59706 1.07568 8.19892H1.0458C0.478082 8.19892 0 9.18468 0 10.4074C0 11.6301 0.478082 12.6064 1.0458 12.6064H1.06572C1.27489 14.2083 1.71313 15.3078 2.22109 15.3078H2.88841L2.86849 15.2414Z"
                    fill="#2EACB3"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Jumbo Joker</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              K******n
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">0.00049760</span>{" "}
              <img
                src={props.images["btc.3efa24b4.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">24x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+768$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4145 7.7237L16.7719 13.5808V4.99036C16.7719 4.80941 16.7719 4.63798 16.7217 4.46655L18.3092 5.10465C19.3743 5.54274 19.8767 6.71417 19.4145 7.7237Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M15.0636 4.99048V13.6286C15.0334 13.3143 14.9631 12.9905 14.8325 12.6762L11.4464 4.13333C11.2756 3.71429 11.0244 3.32381 10.7129 3H12.9535C13.0138 3 13.0741 3 13.1344 3.00952C13.5966 3.04762 14.0186 3.22857 14.3401 3.49524C14.7822 3.86667 15.0636 4.4 15.0636 4.99048Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M12.0088 15.8189L11.1749 16.1142L10.7026 16.2856L9.53709 16.6951L8.90409 16.9237L8.39165 17.1047L6.6534 17.7237L6.23139 17.8761C5.99025 17.9618 5.73906 17.9999 5.49791 17.9999C4.64385 17.9999 3.84004 17.5142 3.52856 16.7142L0.132429 8.17133C-0.269479 7.14275 0.273097 5.99037 1.35825 5.5999L2.97593 5.02847L3.85009 4.71418L4.78452 4.38085L7.13569 3.54275C7.37683 3.45704 7.62803 3.41895 7.87922 3.41895C7.98975 3.41895 8.10027 3.42847 8.2108 3.43799C8.49213 3.48561 8.76342 3.58085 9.00456 3.72371C9.26581 3.87609 9.48685 4.09514 9.65767 4.35228C9.73805 4.45704 9.79834 4.58085 9.84857 4.70466L13.2347 13.2475C13.6466 14.2761 13.094 15.4285 12.0088 15.8189Z"
                    fill="#2EACB3"
                  ></path>
                  <path
                    d="M9.28311 10.2004V10.2385C9.28311 10.2385 9.28311 10.248 9.28311 10.2575C9.30321 10.3718 9.32331 10.4861 9.32331 10.6004C9.32331 10.8195 9.27307 11.0195 9.17259 11.2099C9.09221 11.3718 8.98168 11.5242 8.84101 11.6575C8.59987 11.8861 8.29844 12.0099 7.96686 12.0575C7.68553 12.1052 7.41424 12.0575 7.153 11.9528C7.08266 11.9242 7.03242 11.9528 7.00228 12.0099C6.97214 12.0861 6.9219 12.1528 6.88171 12.2195C6.64056 12.5337 6.32908 12.7337 5.92718 12.8195C5.65589 12.8766 5.3846 12.8575 5.11331 12.7718C5.11331 12.7718 5.10326 12.7718 5.09322 12.7718C4.9626 12.7242 4.84202 12.6671 4.7315 12.6004C4.45016 12.4195 4.25925 12.1814 4.13868 11.8861C4.10854 11.8004 4.0784 11.7052 4.06835 11.6099C4.01811 11.3147 4.06835 11.0385 4.17887 10.7718C4.30949 10.4766 4.46021 10.1814 4.60088 9.87659C4.85207 9.33374 5.11331 8.8004 5.3645 8.26707C5.39465 8.20993 5.42479 8.15278 5.44489 8.08612C5.47503 8.00993 5.48508 8.0004 5.55541 8.02897C6.51999 8.44802 7.48457 8.85755 8.44915 9.27659C8.70035 9.38136 8.89125 9.55278 9.04197 9.76231C9.08216 9.81945 9.10226 9.89564 9.16254 9.93374L9.18264 9.99088C9.18264 10.0671 9.22283 10.1433 9.26302 10.2004H9.28311Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M9.28351 10.2002C9.23327 10.1336 9.20312 10.0669 9.20312 9.99072L9.28351 10.2002Z"
                    fill="#27282B"
                  ></path>
                  <path
                    d="M6.80176 14.1718C6.80176 13.9527 6.87209 13.7241 6.91228 13.5051C6.97257 13.1241 7.0429 12.7337 7.10319 12.3527C7.10319 12.3337 7.12328 12.3051 7.12328 12.286C7.12328 12.2575 7.12328 12.2384 7.16348 12.2194C7.20367 12.2003 7.21371 12.2384 7.23381 12.2479C7.54529 12.486 7.85677 12.7241 8.1783 12.9718C8.3692 13.1241 8.56011 13.267 8.75102 13.4194C8.78116 13.4384 8.8314 13.467 8.82135 13.5051C8.80125 13.5527 8.74097 13.5337 8.70078 13.5432C8.27877 13.6194 7.86682 13.7432 7.47496 13.8956C7.24386 13.9813 7.0429 14.1051 6.8319 14.2289L6.81181 14.1908L6.80176 14.1718Z"
                    fill="#27282B"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Lightning Roulette</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              J******7
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">150.00600024</span>{" "}
              <img
                src={props.images["usdt.b5bcf110.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">4x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+600$</div>
          </div>
          <div className="tr svelte-fgu4xw">
            <div className="col td weight-600 items-center svelte-fgu4xw">
              <div className="game-icon svelte-fgu4xw">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9539 8.19868C18.7448 6.59682 18.3065 5.49731 17.8085 5.49731H17.1113C17.1113 5.49731 17.1312 5.53523 17.1312 5.56366C17.3802 6.29351 17.5695 7.0897 17.689 7.93328C17.8085 8.71999 17.8683 9.5541 17.8683 10.4072C17.8683 11.2602 17.8085 12.0375 17.689 12.8147C17.5695 13.6772 17.3802 14.4924 17.1312 15.2412C17.1312 15.2601 17.1213 15.2886 17.1113 15.3075H17.8085C18.3065 15.3075 18.7448 14.208 18.9539 12.6062C19.5316 12.6062 19.9997 11.6204 19.9997 10.4072C19.9997 9.19392 19.5316 8.19868 18.9539 8.19868Z"
                    fill="#FF7F37"
                  ></path>
                  <path
                    d="M15.3191 5.4976C15.1796 5.15637 15.0302 4.83411 14.8609 4.54027C14.6717 4.20853 14.3131 4 13.9147 4H12.9386C10.8171 4 7.15185 4 5.90684 4.00948C5.63792 4.00948 5.38892 4.1327 5.24948 4.3507C5.04032 4.69193 4.85108 5.07107 4.68176 5.4976C4.6718 5.51656 4.66184 5.53551 4.65188 5.56395C4.13396 6.88145 3.8252 8.56862 3.8252 10.4074C3.8252 12.2463 4.13396 13.9145 4.66184 15.2415C4.66184 15.2604 4.6718 15.2889 4.68176 15.3078C4.8212 15.649 4.9706 15.9713 5.12996 16.2557C5.3192 16.5874 5.67776 16.7959 6.08613 16.7959C7.47057 16.8054 10.8968 16.8054 12.9386 16.8054H13.9147C14.3131 16.8054 14.6717 16.5969 14.8609 16.2651C15.0302 15.9713 15.1796 15.649 15.3191 15.3078C15.329 15.2889 15.339 15.2604 15.339 15.2415C15.8669 13.9145 16.1756 12.2368 16.1756 10.4074C16.1756 8.5781 15.8669 6.89093 15.339 5.56395C15.339 5.54499 15.339 5.51656 15.3191 5.4976ZM8.18769 9.08994C8.12793 9.08994 8.07813 9.07098 8.03829 9.03307C7.99845 8.99515 7.97853 8.94776 7.97853 8.89089V8.13261C7.97853 8.07574 7.99845 8.02835 8.03829 7.99991C8.07813 7.962 8.12793 7.94304 8.18769 7.94304H11.9128C11.9725 7.94304 12.0223 7.962 12.0622 7.99991C12.102 8.02835 12.112 8.07574 12.112 8.13261V8.80558C12.112 8.90985 12.092 9.02359 12.0323 9.14681L10.1897 13.0709C10.1299 13.1941 10.0403 13.251 9.91078 13.251H8.8849C8.8849 13.251 8.79526 13.232 8.76538 13.2036C8.72554 13.1657 8.71558 13.1278 8.71558 13.0804C8.71558 13.0614 8.71558 13.033 8.72554 13.014L10.5681 9.08994H8.18769Z"
                    fill="#FF7F37"
                  ></path>
                  <path
                    d="M2.86849 15.2414C2.61949 14.5116 2.43025 13.7154 2.31073 12.8718C2.19121 12.0851 2.13145 11.2605 2.13145 10.4074C2.13145 9.55434 2.19121 8.75815 2.31073 7.98092C2.43025 7.11838 2.61949 6.30323 2.87845 5.56391C2.87845 5.53547 2.87845 5.51652 2.89837 5.49756H2.22109C1.71313 5.49756 1.27489 6.59706 1.07568 8.19892H1.0458C0.478082 8.19892 0 9.18468 0 10.4074C0 11.6301 0.478082 12.6064 1.0458 12.6064H1.06572C1.27489 14.2083 1.71313 15.3078 2.22109 15.3078H2.88841L2.86849 15.2414Z"
                    fill="#FF7F37"
                  ></path>
                </svg>
              </div>{" "}
              <span className="ms-14">Dwarf Mine</span>
            </div>
            <div className="col td weight-500 hide-on-mobile svelte-fgu4xw">
              b******r
            </div>
            <div className="col td weight-500 items-center hide-on-mobile svelte-fgu4xw">
              <span className="me-8">423.01542314</span>{" "}
              <img
                src={props.images["trx.52baef75.svg"]}
                alt="crypto icon"
                className="svelte-4l2hs7"
              />
            </div>
            <div className="col td weight-500 svelte-fgu4xw">4.8x</div>
            <div className="col td green weight-500 svelte-fgu4xw">+240$</div>
          </div>
        </div>
        <iframe
          title="Latest Wins"
          style={{
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            border: 0,
            opacity: 0,
            pointerEvents: "none",
            zIndex: -1,
          }}
          aria-hidden="true"
          tabIndex="-1"
          src=""
        ></iframe>
      </div>
    </div>
  );
};
export default LatestWins;
