const Jackpot = (props) => {
  return (
    <div className="jackpot container mx-auto svelte-kuy59c">
      <div className="image svelte-kuy59c">
        <div className="sm-up-show-image">
          <div
            className="wrapper svelte-l3byt6"
            style={{ "--borderRadius": "12px", width: "100%", height: "100%" }}
          >
            <img
              src={props.images["d5e35725fe2b47eab60a8100c81ae0a9.webp"]}
              alt="jackpot banner"
              className="image svelte-l3byt6"
              style={{}}
              title="jackpot banner"
              loading="lazy"
            />
          </div>
        </div>
        <div className="sm-down-show-image">
          <div
            className="wrapper svelte-l3byt6"
            style={{ "--borderRadius": "12px", width: "100%", height: "100%" }}
          >
            <img
              src={props.images["79c09ac5a80e41fdafd9134f594cd68f.webp"]}
              alt="jackpot banner"
              className="image svelte-l3byt6"
              style={{}}
              title="jackpot banner"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="info svelte-kuy59c">
        <p className="title svelte-kuy59c">Total Jackpot Amount</p>
        <div className="jackpot-count svelte-kuy59c">$964,693.42</div>
        <p className="description svelte-kuy59c">
          Enter the winners' circle
        </p>{" "}
        <button
          className="primary standart normal svelte-1tio7sv"
          type="button"
          style={{}}
        >
          {" "}
          Go to Casino{" "}
        </button>
      </div>
    </div>
  );
};
export default Jackpot;
