const BuyCrypto = (props) => {
  return (
    <div className="container ms-auto me-auto">
      <div
        className="banner svelte-15uk6op"
        style={{ background: "linear-gradient(90deg, #24243C 0%, rgba(22, 22, 23, 0.00) 70%)" }}
      >
        <div className="middle svelte-15uk6op">
          <div className="title svelte-15uk6op">Buy Crypto</div>
          <div className="description svelte-15uk6op">with Your Card</div>
        </div>
        <div className="img-wrap">
          <div
            className="wrapper svelte-l3byt6"
            style={{ "--borderRadius": "12px", width: "100%", height: "100%" }}
          >
            <img
              src={props.images['687a873d8a8e40649fbd469aa292ab29.svg']}
              alt="img"
              className="image svelte-l3byt6"
              style={{ width: "250px" }}
              title="img"
              loading="lazy"
            />
          </div>
        </div>
        <div className="btn">
          <button
            className="primary standart small svelte-1tio7sv"
            type="button"
            style={{}}
          >
            {" "}
            Sign Up Now
          </button>
        </div>
      </div>
    </div>
  );
};
export default BuyCrypto;
