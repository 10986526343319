const Banner = (props) => {
  return (
    <div className="banner container mx-auto large svelte-jk6xaw">
      <div className="sm-up-show-image">
        <div
          className="wrapper svelte-l3byt6"
          style={{ "--borderRadius": "12px", width: "100%", height: "100%" }}
        >
          <img
            src={props.images["c41ae51f1c7b46acb03d5f526f57402d.webp"]}
            alt="reward banner"
            className="image svelte-l3byt6"
            style={{ objectPosition: "left" }}
            title="reward banner"
            loading="lazy"
          />
        </div>
      </div>

      <div className="sm-down-show-image">
        <div
          className="wrapper svelte-l3byt6"
          style={{ "--borderRadius": "12px", width: "100%", height: "100%" }}
        >
          <img
            src={props.images["77f3bf367c1e4b8880262149faae5a19.webp"]}
            alt="reward banner"
            className="image svelte-l3byt6"
            style={{}}
            title="reward banner"
            loading="lazy"
          />
        </div>
      </div>
      <h1 className="title svelte-jk6xaw">
        <div>Get 1.5 BTC + 250 Free Spins</div>
      </h1>
      <h2 className="subtitle svelte-jk6xaw">Join now &amp; Get rewards</h2>
      <div className="btn svelte-jk6xaw">
        <button
          className="primary standart normal svelte-1tio7sv"
          type="button"
          style={{}}
        >
          {" "}
          Sign Up Now{" "}
        </button>
      </div>
    </div>
  );
};
export default Banner;
