const LiveCasinoBlock = (props) => {
  return (
    <div className="games-block container ms-auto me-auto swiper-wrapper-auto">
      <div className="s-wrapper position-relative swiper-wrapper-auto false svelte-12mba6b">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode">
          <div
            className="container-start svelte-12mba6b"
            slot="container-start"
          >
            <div className="title svelte-12mba6b">
              Live Casino{" "}
              <img
                src={props.images['right-arrow.ba864f8e.svg']}
                alt="arrow"
                className="svelte-12mba6b"
              />
            </div>
            <div className="action-buttons d-flex items-center svelte-12mba6b">
              <button
                className="preveabca8b5-5e58-4b33-8e81-bdd3be7f6a34 btn svelte-12mba6b swiper-button-disabled"
                disabled=""
              >
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(90deg)",
                  }}
                >
                  <title>Arrow left</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>{" "}
              <button className="nexteabca8b5-5e58-4b33-8e81-bdd3be7f6a34 btn svelte-12mba6b">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(270deg)",
                  }}
                >
                  <title>Arrow right</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            className="swiper-wrapper"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <div className="swiper-slide swiper-slide-active">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['520c03843d674893995b587c3c052735.webp']}
                      alt="Lightning Roulette"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Lightning Roulette"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide swiper-slide-next">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['520c03843d674893995b587c3c052735.webp']}
                      alt="Crazy Time"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Crazy Time"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['520c03843d674893995b587c3c052735.webp']}
                      alt="Mega Wheel"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Mega Wheel"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['520c03843d674893995b587c3c052735.webp']}
                      alt="Blackjack Lobby"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Blackjack Lobby"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['520c03843d674893995b587c3c052735.webp']}
                      alt="Blackjack Lobby"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Blackjack Lobby"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['520c03843d674893995b587c3c052735.webp']}
                      alt="Baccarat Lobby"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Baccarat Lobby"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['520c03843d674893995b587c3c052735.webp']}
                      alt="PowerUp Roulette"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="PowerUp Roulette"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(85)"
                      alt="Roulette Lobby "
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Roulette Lobby "
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(86)"
                      alt="Sweet Bonanza CandyLand"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Sweet Bonanza CandyLand"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(87)"
                      alt="Baccarat Lobby "
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Baccarat Lobby "
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(88)"
                      alt="MONOPOLY Live"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="MONOPOLY Live"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(89)"
                      alt="Live Wheel of Fortune"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live Wheel of Fortune"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(90)"
                      alt="Live Game Shows Lobby"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live Game Shows Lobby"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(91)"
                      alt="BOOM CITY"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="BOOM CITY"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(92)"
                      alt="Asian Games Lobby"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Asian Games Lobby"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(93)"
                      alt="Craps"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Craps"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(94)"
                      alt="Mega Roulette"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Mega Roulette"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(95)"
                      alt="Live First Person BlackJack"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live First Person BlackJack"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(96)"
                      alt="Live - Speed Roulette"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live - Speed Roulette"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(97)"
                      alt="Crazy Pachinko"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Crazy Pachinko"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(98)"
                      alt="Cash or Crash"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Cash or Crash"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(99)"
                      alt="Dice Duel"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Dice Duel"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(100)"
                      alt="Red Door Roulette"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Red Door Roulette"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(101)"
                      alt="Football studio"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Football studio"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(102)"
                      alt="Lightning Dice"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Lightning Dice"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(103)"
                      alt="Deal or No Deal"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Deal or No Deal"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(104)"
                      alt="Auto Roulette "
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Auto Roulette "
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(105)"
                      alt="Mega Sic Bo"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Mega Sic Bo"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(106)"
                      alt="ONE Blackjack"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="ONE Blackjack"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(107)"
                      alt="Mega Ball"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Mega Ball"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(108)"
                      alt="Live Bet On Poker"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live Bet On Poker"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(109)"
                      alt="Football Studio Roulette"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Football Studio Roulette"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(110)"
                      alt="Live War of Bets"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live War of Bets"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(111)"
                      alt="Dead or Alive: Saloon"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Dead or Alive: Saloon"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(112)"
                      alt="XXXtreme Lightning Roulette"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="XXXtreme Lightning Roulette"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(113)"
                      alt="Dragon Tiger"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Dragon Tiger"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(114)"
                      alt="VIP Roulette - The Club"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="VIP Roulette - The Club"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(115)"
                      alt="Instant Lucky 7"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Instant Lucky 7"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(116)"
                      alt="Live Baccarat"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live Baccarat"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(117)"
                      alt="Lightning Baccarat"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Lightning Baccarat"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(118)"
                      alt="Peek Baccarat"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Peek Baccarat"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(119)"
                      alt="Poker Lobby"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Poker Lobby"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(120)"
                      alt="Live Lucky 7"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live Lucky 7"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(121)"
                      alt="Speedy 7"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Speedy 7"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(122)"
                      alt="Live Lucky 5"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live Lucky 5"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(123)"
                      alt="Fan Tan"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Fan Tan"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(124)"
                      alt="Live Lucky 6"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Live Lucky 6"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(125)"
                      alt="First Person Lightning Lotto"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="First Person Lightning Lotto"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(126)"
                      alt="Sic Bo"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Sic Bo"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(127)"
                      alt="Vegas Ball Bonanza"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Vegas Ball Bonanza"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LiveCasinoBlock;
