import Iframe from "../Iframe";

const Plinko = (props) => {
  return (
    <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">
      <iframe
        title="Plinko"
        src="https://www.eyemotion.me/plinko/"
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
        frameBorder="0"
        className="svelte-1q06x9j-"
        width="100%"
        height="768"
        style={{ border: 0 }}
        allowFullScreen="true"
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </main>
  );
};
export default Plinko;
