import { Link } from "react-router-dom";
import Currencies from "./Currencies";
import Partners from "./Partners";
import Social from "./Social";
import CopyRight from "./CopyRight";

const Footer = (props) => {
  return (
    <div className="footer svelte-111tpvb">
      <div className="container mx-auto christmas svelte-111tpvb">
        <div className="footer-navigation svelte-111tpvb">
          <div className="Footer-nav">
            <div className="desktop svelte-1e69htt">
              <ul>
                <li className="title svelte-1e69htt">Platform</li>
                <li className="link svelte-1e69htt">Casino</li>
                <li className="link svelte-1e69htt">live casino</li>
                {/*<li className="link svelte-1e69htt">Sports</li>*/}
                <li className="link svelte-1e69htt">Provably Fair</li>
                <li className="link svelte-1e69htt">Promotions</li>
                <li className="link svelte-1e69htt">Loyalty</li>
                <Link
                  to=""
                  rel="noreferrer"
                  target="_blank"
                  className="link svelte-1e69htt"
                >
                  Affiliate
                </Link>
              </ul>
              <ul>
                <li className="title svelte-1e69htt">Support</li>
                <li className="link svelte-1e69htt">Live Support</li>
              </ul>
              <ul>
                <li className="title svelte-1e69htt">Terms</li>
                <li className="link svelte-1e69htt">Terms &amp; Conditions</li>
                <li className="link svelte-1e69htt">Privacy Policy</li>
                <li className="link svelte-1e69htt">Sport T&amp;C</li>
                <li className="link svelte-1e69htt">Prohibited Territories</li>
                <li className="link svelte-1e69htt">Bonus T&amp;C</li>
                <li className="link svelte-1e69htt">Games Excluded From Bonus</li>
              </ul>
              <ul>
                <li className="title svelte-1e69htt">About Us</li>
                <li className="link svelte-1e69htt">Payment Options</li>
                <li className="link svelte-1e69htt">Blog</li>
                <li className="link svelte-1e69htt">FAQ</li>
                <li className="link svelte-1e69htt">About Us</li>
              </ul>
            </div>
            <div className="mobile svelte-1e69htt">
              <ul>
                <li className=" svelte-1e69htt"> Terms and Conditions</li>
              </ul>
              <ul>
                <li className=" svelte-1e69htt">Privacy Policy</li>
              </ul>
              <ul>
                <li className=" svelte-1e69htt">Payment Options</li>
              </ul>
              <Link
                to="https://cnews/pt"
                rel="noreferrer"
                target="_blank"
                className="svelte-1e69htt"
              >
                Affiliate{" "}
              </Link>
              <ul>
                <li className=" svelte-1e69htt">About us</li>
              </ul>
              <ul>
                <li className="yellow svelte-1e69htt">More</li>
              </ul>
            </div>
          </div>

          {/* Social Icons */}
          <Social images={props.images} />
        </div>

        {/* Currencies Icons */}
        <Currencies images={props.images} />

        {/* Partners List */}
        <Partners images={props.images} />

        {/* CopyRight */}
        <CopyRight images={props.images} />

        {/* Social Mobile */}
        <div className="social mobile svelte-111tpvb">
          <div className="social-icons svelte-111tpvb">

            {/* Twitter */}
            <li className="social-icon svelte-111tpvb">
              <Link
                to="https://twitter.com/up-10xcom"
                target="_blank"
                rel="noreferrer"
                className="svelte-111tpvb"
              >
                <img
                  src={props.images['twitter.5d5f36ce.svg']}
                  alt="twitter"
                  className="svelte-111tpvb"
                />
              </Link>{" "}
            </li>

            {/* FB 
            <li className="social-icon svelte-111tpvb">
              <Link
                to="https://www.facebook.com/people/Up-10xcom/100094244176659/"
                target="_blank"
                rel="noreferrer"
                className="svelte-111tpvb"
              >
                <img
                  src={props.images['twitter.5d5f36ce.svg']}
                  alt="twitter"
                  className="svelte-111tpvb"
                />
              </Link>{" "}
            </li>*/}

             {/* LinkedIn 
             <li className="social-icon svelte-111tpvb">
              <Link
                to="https://www.linkedin.com/company/up-10x-com/"
                target="_blank"
                rel="noreferrer"
                className="svelte-111tpvb"
              >
                <img
                  src={props.images['twitter.5d5f36ce.svg']}
                  alt="twitter"
                  className="svelte-111tpvb"
                />
              </Link>{" "}
            </li>*/}
            
            {/* Discord */}
            <li className="social-icon svelte-111tpvb">
              <Link
                to="https://discord.com/invite/up-10xcom"
                target="_blank"
                rel="noreferrer"
                className="svelte-111tpvb"
              >
                <img
                  src={props.images['discord.9a150ea4.svg']}
                  alt="discord"
                  className="svelte-111tpvb"
                />
              </Link>{" "}
            </li>

            <li className="social-icon svelte-111tpvb">
              <Link
                to="https://bitcointalk.org/"
                target="_blank"
                rel="noreferrer"
                className="svelte-111tpvb"
              >
                <img
                  src={props.images['btc.ac6140a6.svg']}
                  alt="btc talk"
                  className="svelte-111tpvb"
                />
              </Link>{" "}
            </li>

            <li className="social-icon svelte-111tpvb">
              <Link
                to="https://t.me/up-10xcom"
                target="_blank"
                rel="noreferrer"
                className="svelte-111tpvb"
              >
                <img
                  src={props.images['telegram.287f68d1.svg']}
                  alt="Telegram"
                  className="svelte-111tpvb"
                />
              </Link>{" "}
            </li>

            <li className="social-icon svelte-111tpvb">
              <Link
                to="https://www.reddit.com/r/up-10xcom/"
                target="_blank"
                rel="noreferrer"
                className="svelte-111tpvb"
              >
                <img
                  src={props.images['reddit.b6d4dd0c.svg']}
                  alt="Reddit"
                  className="svelte-111tpvb"
                />
              </Link>{" "}
            </li>

          </div>
        </div>

      </div>
    </div>
  );
};
export default Footer;
