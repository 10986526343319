import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { domain } from "../../config";
import Slider from "./Slider";

const FastGames = (props) => {
  const [games, setGames] = useState(null);

  useEffect(() => {
    fetch(
      `${domain()}/aggregator/games?provider=fiablegames&gameMode=demo&partnerId=1000&language=en&token=`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      //.then(data => console.log(data.data.result) )
      .then((data) => setGames(data.data.result.game_list))
      .catch((error) => console.log(error));
  }, []);

  return (
    <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">

      <Slider images={props.images} />

      <div className="games-filters container d-flex svelte-1ba933x">
        <div className="d-flex gap-8">
          <div className="filter-dropdown svelte-8o69b0">
            <div className="handle svelte-8o69b0">
              <div className="icons">
                <img
                  slot="icon"
                  src={props.images["provider-filter.1190ad14.svg"]}
                  alt="providers"
                  className="svelte-1ba933x"
                />
              </div>
              <div className="weight-600">Providers</div>{" "}
              <svg
                className="down svelte-1m58f2p"
                viewBox="0 0 12 12"
                width="20"
                height="20"
                fill="none"
              >
                <title>Arrow down</title>
                <path
                  d="M9.5 4.5L6 8L2.5 4.5"
                  stroke="#E0E0E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ fill: "none!importan" }}
                ></path>
              </svg>
            </div>
          </div>

          <div className="filter-dropdown svelte-8o69b0">
            <div className="handle svelte-8o69b0">
              <div className="icons">
                <img
                  slot="icon"
                  src={props.images["type-filter.5e54ab4f.svg"]}
                  alt="providers"
                  className="svelte-1ba933x"
                />
              </div>
              <div className="weight-600">Game types</div>{" "}
              <svg
                className="down svelte-1m58f2p"
                viewBox="0 0 12 12"
                width="20"
                height="20"
                fill="none"
              >
                <title>Arrow down</title>
                <path
                  d="M9.5 4.5L6 8L2.5 4.5"
                  stroke="#E0E0E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ fill: "none!importan" }}
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="input-wrapper undefined svelte-m2q48g">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="#fff"
          >
            <path
              d="M1557.558,48.559a1.5,1.5,0,0,1-2.125,0l-2.651-2.652a7.048,7.048,0,1,1,2.125-2.125l2.652,2.652A1.5,1.5,0,0,1,1557.558,48.559ZM1549.011,35a5.008,5.008,0,1,0,5.008,5.008A5.008,5.008,0,0,0,1549.011,35Z"
              transform="translate(-1541.999 -33)"
            ></path>
          </svg>{" "}
          <input placeholder="Search" className="svelte-m2q48g" />
        </div>
      </div>

      <div className="container ms-auto me-auto title svelte-ptg4pu" id="games">
        All Crypto Games
      </div>

      <div className="games-filtered container ms-auto me-auto svelte-ptg4pu">
        {
            games && games.map( (item, index) => {
                return (
                    <div className="game-item-large svelte-1tenouv" key={index}>
          <div className="overlay svelte-1tenouv">
            <div className="play-button svelte-1tenouv">
              <button
                className="primary standart small svelte-uia73q"
                type="button"
                style={{}}
              >
                {" "}
                Play Now{" "}
              </button>
            </div>
            <div className="play-demo svelte-1tenouv">Demo</div>
          </div>
          <div className="img-wrap svelte-1tenouv">
            <div
              className="wrapper svelte-l3byt6"
              style={{ borderRadius: "10px", width: "100%", height: "100%" }}
            >
              <img
                src={`https://static.fiable.games/images/fiable/${item.image_name}`}
                alt={item.name}
                className="image svelte-l3byt6"
                style={{}}
                title={item.description}
                loading="lazy"
              />
            </div>
          </div>
        </div>
                )
            })
        }
      </div>
    </main>
  );
};
export default FastGames;
