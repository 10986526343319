const WorthTryingBlock = (props) => {
  return (
    <div className="games-block container ms-auto me-auto swiper-wrapper-auto">
      <div className="s-wrapper position-relative swiper-wrapper-auto false svelte-12mba6b">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode">
          <div
            className="container-start svelte-12mba6b"
            slot="container-start"
          >
            <div className="title svelte-12mba6b">
              Worth Trying{" "}
              <img
                src={props.images['right-arrow.ba864f8e.svg']}
                alt="arrow"
                className="svelte-12mba6b"
              />
            </div>
            <div className="action-buttons d-flex items-center svelte-12mba6b">
              <button
                className="prevd49daae3-2b90-4a40-99a7-b8a81bffcb8e btn svelte-12mba6b swiper-button-disabled"
                disabled=""
              >
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(90deg)",
                  }}
                >
                  <title>Arrow left</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>{" "}
              <button className="nextd49daae3-2b90-4a40-99a7-b8a81bffcb8e btn svelte-12mba6b">
                <svg
                  className="icon icon-arrowLeft"
                  viewBox="0 0 12 12"
                  width="17"
                  height="17"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(270deg)",
                  }}
                >
                  <title>Arrow right</title>
                  <path
                    d="M9.5 4.5L6 8L2.5 4.5"
                    stroke="#E0E0E0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: "none!important" }}
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            className="swiper-wrapper"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <div className="swiper-slide swiper-slide-active">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['original.7fd4529ced38a8ff87cb.avif']}
                      alt="Golden Dragon Inferno"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Golden Dragon Inferno"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide swiper-slide-next">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={props.images['original.7fd4529ced38a8ff87cb.avif']}
                      alt="Sugar Rush 1000"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Sugar Rush 1000"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(143)"
                      alt="All Ways Luck"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="All Ways Luck"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(144)"
                      alt="The Candy Slot Deluxe"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="The Candy Slot Deluxe"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(7)"
                      alt="Rainbow Ray"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Rainbow Ray"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(145)"
                      alt="Joker Ra Sunrise"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Joker Ra Sunrise"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(146)"
                      alt="Clover Blitz Hold and Win"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Clover Blitz Hold and Win"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(30)"
                      alt="Vegas Blast"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Vegas Blast"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(147)"
                      alt="Yukon Fever"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Yukon Fever"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(148)"
                      alt="Elephant&#39;s Gold: Bonus Combo"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Elephant&#39;s Gold: Bonus Combo"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(149)"
                      alt="Arctic Coins: RUNNING WINS"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Arctic Coins: RUNNING WINS"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(18)"
                      alt="The Dog House Dog or Alive"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="The Dog House Dog or Alive"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="restriction-overlay svelte-12iieh"></div>
                <div className="overlay svelte-12iieh">
                  <div className="restricted svelte-12iieh">
                    Restricted from your location
                  </div>
                </div>
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(150)"
                      alt="30 Coins"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="30 Coins"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(151)"
                      alt="Heimdall&#39;s Gate Cash Quest"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Heimdall&#39;s Gate Cash Quest"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(152)"
                      alt="Heist: Bank Rush - Hold &amp; Win"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Heist: Bank Rush - Hold &amp; Win"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(15)"
                      alt="Fire Portals"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Fire Portals"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(153)"
                      alt="Clover Charm: Hit the Bonus"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Clover Charm: Hit the Bonus"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(8)"
                      alt="Aztec Powernudge"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Aztec Powernudge"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(154)"
                      alt="Coins Of Ra - Hold &amp; Win"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Coins Of Ra - Hold &amp; Win"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(155)"
                      alt="Miami Bonus Wheel"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Miami Bonus Wheel"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(156)"
                      alt="Devil&#39;s Diamond Rush"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Devil&#39;s Diamond Rush"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(157)"
                      alt="Spirits of the Prairies"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Spirits of the Prairies"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(158)"
                      alt="Book Of 4 Horsemen"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Book Of 4 Horsemen"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(159)"
                      alt="Sunny Fruits 2: Hold and Win"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Sunny Fruits 2: Hold and Win"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(160)"
                      alt="Jolly Queen"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Jolly Queen"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(43)"
                      alt="Big Bass - Secrets of the Golden Lake"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Big Bass - Secrets of the Golden Lake"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(161)"
                      alt="Cash Busters"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Cash Busters"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(19)"
                      alt="Ripe Rewards"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Ripe Rewards"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(162)"
                      alt="Multihand Blackjack"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Multihand Blackjack"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(163)"
                      alt="Gates of Babylon"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Gates of Babylon"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(164)"
                      alt="Ice Lobster"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Ice Lobster"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(165)"
                      alt="Samurai&#39;s Katana"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Samurai&#39;s Katana"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(35)"
                      alt="Sugar Bomb MultiBoost"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Sugar Bomb MultiBoost"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(166)"
                      alt="Book of Doom"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Book of Doom"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(167)"
                      alt="Moon Tiger"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Moon Tiger"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(168)"
                      alt="Diamond of Jungle"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Diamond of Jungle"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(169)"
                      alt="Boogie Boom"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Boogie Boom"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(170)"
                      alt="Gorilla: Rapid Link"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Gorilla: Rapid Link"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(171)"
                      alt="7 Gold Fruits"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="7 Gold Fruits"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(172)"
                      alt="Trout&#39;s Treasure - Countryside Quest"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Trout&#39;s Treasure - Countryside Quest"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(173)"
                      alt="Big Burger Load it up with Xtra Cheese"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Big Burger Load it up with Xtra Cheese"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(174)"
                      alt="Single Deck Blackjack"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Single Deck Blackjack"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(175)"
                      alt="Diamond Bounty Xmas Hold and Win"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Diamond Bounty Xmas Hold and Win"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(176)"
                      alt="Book Of Clovers - Extreme"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Book Of Clovers - Extreme"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(177)"
                      alt="Lucky Oasis"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Lucky Oasis"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(178)"
                      alt="Tiger&#39;s Luck - Hold &amp; Win"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Tiger&#39;s Luck - Hold &amp; Win"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(179)"
                      alt="Gnomes and Giants"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Gnomes and Giants"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(180)"
                      alt="Blessed Dragons Hold and Win"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Blessed Dragons Hold and Win"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(181)"
                      alt="Tiger Claws"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Tiger Claws"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="game-item-large svelte-12iieh">
                <div className="img-wrap svelte-12iieh">
                  <div
                    className="wrapper svelte-l3byt6"
                    style={{
                      "--borderRadius": "5px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src="./assets/original(182)"
                      alt="Ninjaz vs Narcos"
                      className="image svelte-l3byt6"
                      style={{}}
                      title="Ninjaz vs Narcos"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WorthTryingBlock;
