import { Link } from "react-router-dom";

const ResetPassword = (props) => {
  return (
    <>
      <div className="backdrop svelte-1vfp6m0"></div>
      <div role="dialog" className="dialog">
        <div className="modal-blank svelte-vu0w7c">
          <form
            action="#"
            className="wrap svelte-wz3rp4"
          >
            <div className="top svelte-wz3rp4">
              <h2 className="title svelte-wz3rp4">Password Reset</h2>
              <p className="description svelte-wz3rp4">
                Enter your registered username or email. We'll send you a link
                for a password reset.
              </p>
              <div className="close svelte-wz3rp4">
                <Link to="/">
                    <button className="small primary nav-button svelte-1ld08ko">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M12 4L4 12"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        ></path>
                        <path
                        d="M4 4L12 12"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        ></path>
                    </svg>
                    </button>
                </Link>
              </div>
            </div>
            <div className="input-row svelte-wz3rp4">
              <div className="full false false false svelte-12junve">
                <div className="input-wrapper default svelte-12junve">
                  <input
                    placeholder=""
                    type="text"
                    inputMode="text"
                    autoComplete="on"
                    id=""
                    name=""
                    className="svelte-12junve"
                  />{" "}
                  <span className="placeholder svelte-12junve">
                    Email/Username
                  </span>
                  <div className="left-icon svelte-12junve"></div>
                </div>
              </div>
            </div>{" "}
            <button
              disabled=""
              className="primary standart normal svelte-uia73q"
              type="submit"
              style={{}}
            >
              Send{" "}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
