const VIP = (props) => {
  return (
    <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">
      <div className="background svelte-1abtdoe">
        <div className="container mx-auto vip svelte-1abtdoe">
          <div
            className="banner svelte-1w1s2s5"
            style={{
              backgroundImage: `url(${props.images["banner-bg.ad7d9dae.svg"]}`,
            }}
          >
            <div className="banner-content svelte-1w1s2s5">
              <div className="banner-content__img svelte-1w1s2s5">
                <img
                  src={props.images["crown-blue.b87d1ce0.svg"]}
                  alt="crown"
                />
              </div>
              <div className="banner-content__title svelte-1w1s2s5">
                Jack’s VIP Club
              </div>
              <div className="banner-content__subtitle svelte-1w1s2s5">
                The Ultimate Playground for Winners
              </div>
              <div className="banner-content__text svelte-1w1s2s5">
                Welcome back<span className="svelte-1w1s2s5">vardanplay</span>
              </div>
            </div>
          </div>
          <div className="premium-benefits svelte-1y4th2p">
            <div className="title svelte-1y4th2p">Premium VIP Benefits</div>
            <div className="block svelte-1y4th2p">
              <div className="benefits svelte-191e33u">
                <div className="icon svelte-191e33u">
                  <img
                    src={props.images["cashback.06f2fa66.svg"]}
                    alt=""
                    className="svelte-191e33u"
                  />
                </div>
                <div className="benefit svelte-191e33u">15% Cashback</div>
                <div className="time svelte-191e33u">Monthly</div>
              </div>
              <div className="benefits-mobile svelte-191e33u">
                <div className="icon svelte-191e33u">
                  <img
                    src={props.images["cashback-mob.7d14b46a.svg"]}
                    alt=""
                    className="svelte-191e33u"
                  />
                </div>
                <div className="benefit svelte-191e33u">15% Cashback</div>
                <div className="time svelte-191e33u">Monthly</div>
              </div>
              <div className="benefits svelte-191e33u">
                <div className="icon svelte-191e33u">
                  <img
                    src={props.images["bonus.b5f236de.svg"]}
                    alt=""
                    className="svelte-191e33u"
                  />
                </div>
                <div className="benefit svelte-191e33u">50% reload bonus</div>
                <div className="time svelte-191e33u">Weekly</div>
              </div>
              <div className="benefits-mobile svelte-191e33u">
                <div className="icon svelte-191e33u">
                  <img
                    src={props.images["bonus-mob.e7846e17.svg"]}
                    alt=""
                    className="svelte-191e33u"
                  />
                </div>
                <div className="benefit svelte-191e33u">50% reload bonus</div>
                <div className="time svelte-191e33u">Weekly</div>
              </div>
              <div className="benefits svelte-191e33u">
                <div className="icon svelte-191e33u">
                  <img
                    src={props.images["banckback.d9f83342.svg"]}
                    alt=""
                    className="svelte-191e33u"
                  />
                </div>
                <div className="benefit svelte-191e33u">VIP BankBack</div>
                <div className="time svelte-191e33u">Daily</div>
              </div>
              <div className="benefits-mobile svelte-191e33u">
                <div className="icon svelte-191e33u">
                  <img
                    src={props.images["banckback-mob.19e823a0.svg"]}
                    alt=""
                    className="svelte-191e33u"
                  />
                </div>
                <div className="benefit svelte-191e33u">VIP BankBack</div>
                <div className="time svelte-191e33u">Daily</div>
              </div>
            </div>
          </div>
          <div className="wheel svelte-snr2xc">
            <div className="wheel__title svelte-snr2xc">Spin the wheel</div>
            <div className="wheel__subtitle svelte-snr2xc">
              Fabulous rewards that get better with each level up.
            </div>
            <div className="wheel__text svelte-snr2xc">Upcoming</div>
            <div className="wheel__img svelte-snr2xc">
              <img
                src={props.images["wheel.webp"]}
                alt="wheel"
                className="svelte-snr2xc"
              />
            </div>
          </div>
          <div className="extra-benefits svelte-9zjnlt">
            <div className="title svelte-9zjnlt">Extra benefits</div>
            <div className="block svelte-9zjnlt">
              <div
                className="benefits svelte-zec6mx"
                style={{
                  backgroundImage: `url(${props.images["benefit-bg.d4543cc3.svg"]})`,
                }}
              >
                <div className="icon svelte-zec6mx">
                  <img
                    src={props.images["support.89a1caa4.svg"]}
                    alt=""
                    className="svelte-zec6mx"
                  />
                </div>
                <div className="benefit svelte-zec6mx">
                  Instant deposit cashback
                </div>
                <div className="text svelte-zec6mx">
                  Immediate Cashback on your deposits
                </div>
              </div>
              <div
                className="benefits svelte-zec6mx"
                style={{
                  backgroundImage: `url(${props.images["benefit-bg.d4543cc3.svg"]})`,
                }}
              >
                <div className="icon svelte-zec6mx">
                  <img
                    src={props.images["crown-pink.174fb04c.svg"]}
                    alt=""
                    className="svelte-zec6mx"
                  />
                </div>
                <div className="benefit svelte-zec6mx">
                  Personal VIP Manager
                </div>
                <div className="text svelte-zec6mx">
                  Anytime assistance, exclusively for you{" "}
                </div>
              </div>
              <div
                className="benefits svelte-zec6mx"
                style={{
                  backgroundImage: `url(${props.images["benefit-bg.d4543cc3.svg"]})`,
                }}
              >
                <div className="icon svelte-zec6mx">
                  <img
                    src={props.images["manager.a70af678.svg"]}
                    alt=""
                    className="svelte-zec6mx"
                  />
                </div>
                <div className="benefit svelte-zec6mx">
                  24/7 Customer support
                </div>
                <div className="text svelte-zec6mx">
                  Dedicated support for all your needs
                </div>
              </div>
            </div>
          </div>
          <div className="banner svelte-1gnbjno">
            <div className="banner-content svelte-1gnbjno">
              <div className="banner-content__title svelte-1gnbjno">
                Join the elite
              </div>
              <div className="banner-content__subtitle svelte-1gnbjno">
                Dive into UP-10X’s exclusive club and experience gaming
                like never before 24/7
              </div>
            </div>
            <div className="banner-img svelte-1gnbjno">
              <img
                src={props.images["elite7.0e61b3f1.svg"]}
                alt=""
                className="svelte-1gnbjno"
              />{" "}
              <img
                src={props.images["elite1.eb2e6d50.svg"]}
                alt=""
                className="svelte-1gnbjno"
              />
            </div>
          </div>
          <div className="title svelte-1oe6cmf">FAQ</div>
          <section className="faq svelte-1oe6cmf">
            <li className="svelte-i7yj8k hasBottomBorder">
              <div className="qa w-100">
                <div style={{}} className="question svelte-i7yj8k">
                  How do I become a VIP?{" "}
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svelte-i7yj8k"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      d="M1.33366 6L6.00032 1.33333L10.667 6"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div className="answer svelte-i7yj8k">
                  Regular gameplay and deposits elevate your status. Entry to
                  the VIP Club is by invitation only.
                </div>
              </div>
            </li>
            <li className="svelte-i7yj8k hasBottomBorder">
              <div className="qa w-100">
                <div style={{}} className="question svelte-i7yj8k">
                  What is VIP BankBack?{" "}
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svelte-i7yj8k"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      d="M1.33366 6L6.00032 1.33333L10.667 6"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div className="answer svelte-i7yj8k">
                  5% Cashback, which you can activate anytime you want.
                </div>
              </div>
            </li>
            <li className="svelte-i7yj8k hasBottomBorder">
              <div className="qa w-100">
                <div style={{}} className="question svelte-i7yj8k">
                  How do I qualify for VIP Bankback?{" "}
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svelte-i7yj8k"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      d="M1.33366 6L6.00032 1.33333L10.667 6"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div className="answer svelte-i7yj8k">
                  In order to activate cashback, your main balance should be
                  equal to 0, and the deposit should be greater than 0. The
                  amount of the each following cashback, is calculated after the
                  last activation. Cashback is credited directly to your wallet
                  and has 2X wagering requirements.
                </div>
              </div>
            </li>
            <li className="svelte-i7yj8k hasBottomBorder">
              <div className="qa w-100">
                <div style={{}} className="question svelte-i7yj8k">
                  What is Instant Deposit Cashback?{" "}
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svelte-i7yj8k"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      d="M1.33366 6L6.00032 1.33333L10.667 6"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div className="answer svelte-i7yj8k">
                  Loosing is the part of the game. If you lose 80% of your
                  deposit, you will receive part of your lost amount on your
                  main balance.
                </div>
              </div>
            </li>
            <li className="svelte-i7yj8k">
              <div className="qa w-100">
                <div style={{}} className="question svelte-i7yj8k">
                  How do I qualify for Instant Deposit Cashback?{" "}
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svelte-i7yj8k"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      d="M1.33366 6L6.00032 1.33333L10.667 6"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div className="answer svelte-i7yj8k">
                  You should not be winning in total the current month and past
                  three months.
                </div>
              </div>
            </li>
          </section>
        </div>
      </div>
    </main>
  );
};
export default VIP;
