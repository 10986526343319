
const Tournaments = (props) => {
    return (
        <main style={{ paddingTop: "86px" }} className="svelte-ulvgrp">
        <div className="container mx-auto">
          <div className="banner svelte-1kyskj5">
            <div className="sm-up-show-image img-wrap svelte-1kyskj5">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "12px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['18d3b6fded5042e0b3c5ba6077f6f467.webp']}
                  alt="tournaments banner"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="tournaments banner"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="sm-down-show-image img-wrap svelte-1kyskj5">
              <div
                className="wrapper svelte-l3byt6"
                style={{
                  "--borderRadius": "12px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={props.images['479d411e64bc480289a4e0be83802e27.webp']}
                  alt="tournaments mobile banner"
                  className="image svelte-l3byt6"
                  style={{}}
                  title="tournaments mobile banner"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="info svelte-1kyskj5">
              <h1 className="svelte-1kyskj5"> </h1>
              <p className="svelte-1kyskj5"> </p>
            </div>
          </div>
        </div>
        <div className="tournaments container mx-auto svelte-8oywtu">
          <div className="t-it item active svelte-1x9ld8x">
            <div className="image svelte-1x9ld8x">
              <div className="tbdg svelte-1x9ld8x">
                <div className="badge active svelte-h8zqmb">Ongoing</div>
              </div>{" "}
              <img
                src={props.images['c26dcfdf5ff541ad8e3b970c3ff0009c.webp']}
                alt="poster"
                className="svelte-1x9ld8x"
              />
            </div>
            <div className="name svelte-1x9ld8x">Pragmatic Hunt</div>
            <div className="prizepool svelte-1x9ld8x">
              <div className="label svelte-1x9ld8x">Prize Pool</div>
              <div className="value svelte-1x9ld8x">$10,000</div>
            </div>
            <div className="bottom svelte-1x9ld8x">
              <div className="actions svelte-1x9ld8x">
                <button
                  className="primary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Sign up{" "}
                </button>{" "}
                <button
                  className="secondary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  See More{" "}
                </button>
              </div>
              <div className="countdown svelte-1x9ld8x">
                <div className="svelte-1kekpch">
                  <div className="title colored svelte-1kekpch">Ends in</div>
                  <div className="countdown svelte-1kekpch">
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">5</span>{" "}
                      <span className="label">Day</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">1</span>{" "}
                      <span className="label">Hour</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">40</span>{" "}
                      <span className="label">Min</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">54</span>{" "}
                      <span className="label">Sec</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="t-it item finished svelte-1x9ld8x">
            <div className="image svelte-1x9ld8x">
              <div className="tbdg svelte-1x9ld8x">
                <div className="badge finished svelte-h8zqmb">Finished</div>
              </div>{" "}
              <img
                src={props.images['968a8da8b70d46418c21a876098ac262.webp']}
                alt="poster"
                className="svelte-1x9ld8x"
              />
            </div>
            <div className="name svelte-1x9ld8x">Betsoft Collector</div>
            <div className="prizepool svelte-1x9ld8x">
              <div className="label svelte-1x9ld8x">Prize Pool</div>
              <div className="value svelte-1x9ld8x">$5,000/10,000FS</div>
            </div>
            <div className="bottom svelte-1x9ld8x">
              <div className="actions svelte-1x9ld8x">
                <button
                  className="primary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Sign up{" "}
                </button>{" "}
                <button
                  className="secondary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  See More{" "}
                </button>
              </div>
              <div className="countdown svelte-1x9ld8x">
                <div className="svelte-1kekpch">
                  <div className="title colored svelte-1kekpch"></div>
                  <div className="countdown svelte-1kekpch">
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Day</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Hour</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Min</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Sec</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="t-it item finished svelte-1x9ld8x">
            <div className="image svelte-1x9ld8x">
              <div className="tbdg svelte-1x9ld8x">
                <div className="badge finished svelte-h8zqmb">Finished</div>
              </div>{" "}
              <img
                src={props.images['66255a84ba614822836d934d42a720aa.webp']}
                alt="poster"
                className="svelte-1x9ld8x"
              />
            </div>
            <div className="name svelte-1x9ld8x">Crash Collector</div>
            <div className="prizepool svelte-1x9ld8x">
              <div className="label svelte-1x9ld8x">Prize Pool</div>
              <div className="value svelte-1x9ld8x">$6,000</div>
            </div>
            <div className="bottom svelte-1x9ld8x">
              <div className="actions svelte-1x9ld8x">
                <button
                  className="primary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Sign up{" "}
                </button>{" "}
                <button
                  className="secondary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  See More{" "}
                </button>
              </div>
              <div className="countdown svelte-1x9ld8x">
                <div className="svelte-1kekpch">
                  <div className="title colored svelte-1kekpch"></div>
                  <div className="countdown svelte-1kekpch">
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Day</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Hour</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Min</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Sec</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="t-it item finished svelte-1x9ld8x">
            <div className="image svelte-1x9ld8x">
              <div className="tbdg svelte-1x9ld8x">
                <div className="badge finished svelte-h8zqmb">Finished</div>
              </div>{" "}
              <img
                src={props.images['c5d252c4123d4dd18ced82af7e87d359.webp']}
                alt="poster"
                className="svelte-1x9ld8x"
              />
            </div>
            <div className="name svelte-1x9ld8x">Mascot Race</div>
            <div className="prizepool svelte-1x9ld8x">
              <div className="label svelte-1x9ld8x">Prize Pool</div>
              <div className="value svelte-1x9ld8x">$7,000</div>
            </div>
            <div className="bottom svelte-1x9ld8x">
              <div className="actions svelte-1x9ld8x">
                <button
                  className="primary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  Sign up{" "}
                </button>{" "}
                <button
                  className="secondary standart normal svelte-uia73q"
                  type="button"
                  style={{}}
                >
                  {" "}
                  See More{" "}
                </button>
              </div>
              <div className="countdown svelte-1x9ld8x">
                <div className="svelte-1kekpch">
                  <div className="title colored svelte-1kekpch"></div>
                  <div className="countdown svelte-1kekpch">
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Day</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Hour</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Min</span>
                    </div>
                    <div className="item svelte-1kekpch">
                      <span className="value colored svelte-1kekpch">0</span>{" "}
                      <span className="label">Sec</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto">
          <button
            className="secondary standart normal svelte-uia73q"
            type="button"
            style={{}}
          >
            {" "}
            Load more
          </button>
        </div>
      </main>
    )
}
export default Tournaments;