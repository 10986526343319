import { Link } from "react-router-dom";
const CopyRight = (props) => {
  return (
    <div className="copyright svelte-111tpvb">
      <p className="title-desk svelte-111tpvb">
        © UP-10X.COM - All Rights Reserved.
      </p>
      <div className="title-container svelte-111tpvb">
        <p className="title-mobile svelte-111tpvb">
          © UP-10X.COM - All Rights Reserved.
        </p>
        <p className="age age-mobile svelte-111tpvb">+18</p>
      </div>
      <p className="description svelte-111tpvb">
        Copyright © 2014 UP-10X.COM is operated by Up-10X Group Enterprises
        N.V., a company registered and established under the laws of Curaçao.
        Up-10X Group Enterprises N.V. is licensed and regulated by AntillePhone
        N.V. Up-10X Group Enterprises N.V.'s registration number is 178512 and
        its registered address is Dr. M.J. Hugenholzweg 62, Curacao, Unit 14-C.
      </p>
      <div className="curacao svelte-111tpvb">
        <div
          id="apg-seal-container"
          data-apg-seal-id="9cfc9553-cf1b-4b51-8c4f-a23d60bcfd5a"
          data-apg-image-size="64"
          data-apg-image-type="basic-small"
          className="svelte-111tpvb"
        >
          <div
            style={{
              display: "block",
              position: "relative",
              overflow: "hidden",
              maxWidth: "64px",
              minWidth: "32px",
              backgroundImage: `url(https://9cfc9553-cf1b-4b51-8c4f-a23d60bcfd5a.snippet.antillephone.com/54f396e0-b046-49b1-9cb3-0c69281d7ea9-beacon.png)`,
            }}
          >
            <Link
              target="_blank"
              rel="nonoopener"
              id="apg-seal-link"
              to=""
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src={props.images['license.png']}
                alt="licesne"
              />
              <img
                style={{
                  minHeight: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  maxWidth: "none",
                  maxHeight: "100%",
                }}
                src={props.images['license.png']}
                alt="license"
              />
            </Link>
          </div>
        </div>
        <p className="age age-desk svelte-111tpvb">+18</p>
      </div>
    </div>
  );
};
export default CopyRight;
