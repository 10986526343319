import { Link } from "react-router-dom";

const Loyalty = (props) => {
  return (
    <>
      <div className="header-promotions svelte-5qf0yi">
        <div className="promo-overlay svelte-5qf0yi"></div>
        <div className="container ms-auto me-auto">
          <div className="wrapper svelte-5qf0yi">
            <div id="promotions-header"></div>{" "}
            <Link
              to="/promotions"
              className="all-promotions-btn d-flex items-center all-promos-btn"
            >
              <img
                src={props.images["promotion.f41c69be.svg"]}
                alt="all promotions"
                style={{ marginRight: "7px" }}
              />
              All promotions
            </Link>
          </div>
        </div>
      </div>

      <div
        className="loyalty svelte-3pp8l3"
        style={{
          backgroundImage: `url(${props.images["59ece2daa87b4b449f3cb3b336e23c6a.webp"]})`,
        }}
      >
        <div className="container svelte-3pp8l3">
          <div className="top svelte-3pp8l3">
            <div className="title svelte-3pp8l3">Welcome Back, vardanplay</div>
            <div
              className="block svelte-3pp8l3"
              style={{
                backgroundImage: `url(${props.images["pattern.73d05a87.png"]})`,
              }}
            >
              <div className="item svelte-3pp8l3">
                <img
                  src={props.images["cashback.c8f2d81e.svg"]}
                  alt="Up to 10% Cashback"
                  className="img svelte-3pp8l3"
                />
                <h3 className="name svelte-3pp8l3">Up to 10% Cashback</h3>
              </div>
              <div className="item svelte-3pp8l3">
                <img
                  src={props.images["btc.957f577f.svg"]}
                  alt="Up to 2 BTC Reload Bonus"
                  className="img svelte-3pp8l3"
                />
                <h3 className="name svelte-3pp8l3">Up to 2 BTC Reload Bonus</h3>
              </div>
            </div>
          </div>
          <div className="garage svelte-1aosh85">
            <div className="header svelte-1aosh85">Your Garage</div>
            <div className="body svelte-1aosh85">
              <div className="item svelte-1aosh85">
                <div className="shape svelte-1aosh85">
                  <svg
                    viewBox="0 0 220 388"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="220"
                    height="388"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.68629 14.6863C0.21185 19.1607 0.00957691 26.239 0.000432936 40C5.52308
    40.0002 10 44.4773 10 50C10 55.5228 5.52285 60 0 60V278C0 328.854 0 354.281
    15.307 370.472C16.0265 371.233 16.7672 371.974 17.5282 372.693C33.7189 388
    59.146 388 110 388C160.854 388 186.281 388 202.472 372.693C203.233 371.974
    203.974 371.233 204.693 370.472C220 354.281 220 328.854 220 278V59.8C215.436
    58.8734 212 54.8379 212 50C212 45.1622 215.435 41.1268 220 40.2001C219.992
    26.3075 219.81 19.1824 215.314 14.6863C210.627 10 203.085 10 188
    10H32C16.9151 10 9.37258 10 4.68629 14.6863Z"
                      fill="#fff"
                    ></path>
                    <rect
                      x="104"
                      width="12"
                      height="20"
                      rx="6"
                      fill="#FFA53B"
                    ></rect>
                  </svg>
                </div>
                <div className="content svelte-1aosh85">
                  <h4 className="name   svelte-1aosh85">Scooter</h4>
                  <div className="hint svelte-1aosh85">
                    <div className="points">
                      Status points:
                      <span className="svelte-1aosh85">0-50</span>
                    </div>
                    <div className="cashback svelte-1aosh85">
                      Cashback:
                      <span className="svelte-1aosh85">3%</span>
                    </div>
                  </div>
                  <div className="img  svelte-1aosh85">
                    <img src={props.images["scooter.webp"]} alt="Scooter" />
                  </div>
                  <div className="bonus svelte-1aosh85">
                    Reload Bonus:
                    <br />{" "}
                    <span className="svelte-1aosh85">25% up to 0.25 BTC</span>
                  </div>
                </div>
              </div>
              <div className="item svelte-1aosh85">
                <div className="shape svelte-1aosh85">
                  <svg
                    viewBox="0 0 220 388"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="220"
                    height="388"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.68629 14.6863C0.21185 19.1607 0.00957691 26.239 0.000432936 40C5.52308
    40.0002 10 44.4773 10 50C10 55.5228 5.52285 60 0 60V278C0 328.854 0 354.281
    15.307 370.472C16.0265 371.233 16.7672 371.974 17.5282 372.693C33.7189 388
    59.146 388 110 388C160.854 388 186.281 388 202.472 372.693C203.233 371.974
    203.974 371.233 204.693 370.472C220 354.281 220 328.854 220 278V59.8C215.436
    58.8734 212 54.8379 212 50C212 45.1622 215.435 41.1268 220 40.2001C219.992
    26.3075 219.81 19.1824 215.314 14.6863C210.627 10 203.085 10 188
    10H32C16.9151 10 9.37258 10 4.68629 14.6863Z"
                      fill="#fff"
                    ></path>
                    <rect
                      x="104"
                      width="12"
                      height="20"
                      rx="6"
                      fill="#FFA53B"
                    ></rect>
                  </svg>
                </div>
                <div className="content svelte-1aosh85">
                  <h4 className="name   svelte-1aosh85">Tesla</h4>
                  <div className="hint svelte-1aosh85">
                    <div className="points">
                      Status points:
                      <span className="svelte-1aosh85">51-300</span>
                    </div>
                    <div className="cashback svelte-1aosh85">
                      Cashback:
                      <span className="svelte-1aosh85">5%</span>
                    </div>
                  </div>
                  <div className="img  svelte-1aosh85">
                    <img src={props.images["tesla.webp"]} alt="Tesla" />
                  </div>
                  <div className="bonus svelte-1aosh85">
                    Reload Bonus:
                    <br />{" "}
                    <span className="svelte-1aosh85">30% up to 0.5 BTC</span>
                  </div>
                </div>
              </div>
              <div className="item svelte-1aosh85">
                <div className="shape svelte-1aosh85">
                  <svg
                    viewBox="0 0 220 388"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="220"
                    height="388"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.68629 14.6863C0.21185 19.1607 0.00957691 26.239 0.000432936 40C5.52308
    40.0002 10 44.4773 10 50C10 55.5228 5.52285 60 0 60V278C0 328.854 0 354.281
    15.307 370.472C16.0265 371.233 16.7672 371.974 17.5282 372.693C33.7189 388
    59.146 388 110 388C160.854 388 186.281 388 202.472 372.693C203.233 371.974
    203.974 371.233 204.693 370.472C220 354.281 220 328.854 220 278V59.8C215.436
    58.8734 212 54.8379 212 50C212 45.1622 215.435 41.1268 220 40.2001C219.992
    26.3075 219.81 19.1824 215.314 14.6863C210.627 10 203.085 10 188
    10H32C16.9151 10 9.37258 10 4.68629 14.6863Z"
                      fill="#fff"
                    ></path>
                    <rect
                      x="104"
                      width="12"
                      height="20"
                      rx="6"
                      fill="#FFA53B"
                    ></rect>
                  </svg>
                </div>
                <div className="content svelte-1aosh85">
                  <h4 className="name   svelte-1aosh85">Lambo</h4>
                  <div className="hint svelte-1aosh85">
                    <div className="points">
                      Status points:
                      <span className="svelte-1aosh85">301-1500</span>
                    </div>
                    <div className="cashback svelte-1aosh85">
                      Cashback:
                      <span className="svelte-1aosh85">7%</span>
                    </div>
                  </div>
                  <div className="img  svelte-1aosh85">
                    <img src={props.images["lambo.webp"]} alt="Lambo" />
                  </div>
                  <div className="bonus svelte-1aosh85">
                    Reload Bonus:
                    <br />{" "}
                    <span className="svelte-1aosh85">35% up to 1 BTC</span>
                  </div>
                </div>
              </div>
              <div className="item svelte-1aosh85">
                <div className="shape svelte-1aosh85">
                  <svg
                    viewBox="0 0 220 388"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="220"
                    height="388"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.68629 14.6863C0.21185 19.1607 0.00957691 26.239 0.000432936 40C5.52308
    40.0002 10 44.4773 10 50C10 55.5228 5.52285 60 0 60V278C0 328.854 0 354.281
    15.307 370.472C16.0265 371.233 16.7672 371.974 17.5282 372.693C33.7189 388
    59.146 388 110 388C160.854 388 186.281 388 202.472 372.693C203.233 371.974
    203.974 371.233 204.693 370.472C220 354.281 220 328.854 220 278V59.8C215.436
    58.8734 212 54.8379 212 50C212 45.1622 215.435 41.1268 220 40.2001C219.992
    26.3075 219.81 19.1824 215.314 14.6863C210.627 10 203.085 10 188
    10H32C16.9151 10 9.37258 10 4.68629 14.6863Z"
                      fill="#fff"
                    ></path>
                    <rect
                      x="104"
                      width="12"
                      height="20"
                      rx="6"
                      fill="#FFA53B"
                    ></rect>
                  </svg>
                </div>
                <div className="content svelte-1aosh85">
                  <h4 className="name   svelte-1aosh85">Yacht</h4>
                  <div className="hint svelte-1aosh85">
                    <div className="points">
                      Status points:
                      <span className="svelte-1aosh85">1501+</span>
                    </div>
                    <div className="cashback svelte-1aosh85">
                      Cashback:
                      <span className="svelte-1aosh85">10%</span>
                    </div>
                  </div>
                  <div className="img  svelte-1aosh85">
                    <img src={props.images["yacht.webp"]} alt="Yacht" />
                  </div>
                  <div className="bonus svelte-1aosh85">
                    Reload Bonus:
                    <br />{" "}
                    <span className="svelte-1aosh85">40% up to 2 BTC</span>
                  </div>
                </div>
              </div>
              <div className="item svelte-1aosh85">
                <div className="shape svelte-1aosh85">
                  <svg
                    viewBox="0 0 220 388"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="220"
                    height="388"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.68629 14.6863C0.21185 19.1607 0.00957691 26.239 0.000432936 40C5.52308
    40.0002 10 44.4773 10 50C10 55.5228 5.52285 60 0 60V278C0 328.854 0 354.281
    15.307 370.472C16.0265 371.233 16.7672 371.974 17.5282 372.693C33.7189 388
    59.146 388 110 388C160.854 388 186.281 388 202.472 372.693C203.233 371.974
    203.974 371.233 204.693 370.472C220 354.281 220 328.854 220 278V59.8C215.436
    58.8734 212 54.8379 212 50C212 45.1622 215.435 41.1268 220 40.2001C219.992
    26.3075 219.81 19.1824 215.314 14.6863C210.627 10 203.085 10 188
    10H32C16.9151 10 9.37258 10 4.68629 14.6863Z"
                      fill="#fff"
                    ></path>
                    <rect
                      x="104"
                      width="12"
                      height="20"
                      rx="6"
                      fill="#49EEC4"
                    ></rect>
                  </svg>
                </div>
                <div className="content svelte-1aosh85">
                  <h4 className="name jet  svelte-1aosh85">VIP</h4>
                  <div className="hint svelte-1aosh85">
                    <div className="points">
                      Status points:
                      <span className="svelte-1aosh85">Invite Only</span>
                    </div>
                    <div className="cashback svelte-1aosh85">
                      Cashback:
                      <span className="svelte-1aosh85">Individual</span>
                    </div>
                  </div>
                  <div className="img  svelte-1aosh85">
                    <img src={props.images["jet.0fe2435c.svg"]} alt="Jet" />
                  </div>
                  <div className="bonus svelte-1aosh85">
                    <span className="svelte-1aosh85">Additional benefits</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="status-block svelte-18uepyl">
            <div className="header svelte-18uepyl">Status point history</div>
            <div className="body svelte-18uepyl">
              <div className="status-frame svelte-18uepyl">
                <div className="no-activity svelte-18uepyl">
                  You have no activity yet
                </div>
              </div>
            </div>
          </div>
          <div className="how svelte-1ess1ti">
            <div className="header svelte-1ess1ti">How Loyalty works</div>
            <div className="body svelte-1ess1ti">
              <div className="item svelte-1ess1ti">
                <img
                  src={props.images["star.e833ba6b.svg"]}
                  alt="Status Points"
                  className="img svelte-1ess1ti"
                />
                <h4 className="name svelte-1ess1ti">Status Points</h4>
                <p className="description svelte-1ess1ti">
                  Earn Status Points by making real-money bets.
                </p>
              </div>
              <div className="item svelte-1ess1ti">
                <img
                  src={props.images["coin.5af44d5e.svg"]}
                  alt="Game Contributions"
                  className="img svelte-1ess1ti"
                />
                <h4 className="name svelte-1ess1ti">Game Contributions</h4>
                <p className="description svelte-1ess1ti">
                  1 Status Point = 100USD Bet (or equivalent in any currency)
                </p>
              </div>
              <div className="item svelte-1ess1ti">
                <img
                  src={props.images["gift.bf44a48b.svg"]}
                  alt="Rewards"
                  className="img svelte-1ess1ti"
                />
                <h4 className="name svelte-1ess1ti">Rewards</h4>
                <p className="description svelte-1ess1ti">
                  Monthly Cashback up to 10%; Weekly Reload up to 2 BTC
                </p>
              </div>
              <div className="item svelte-1ess1ti">
                <img
                  src={props.images["validity.9550c9db.svg"]}
                  alt="Status Point Validity"
                  className="img svelte-1ess1ti"
                />
                <h4 className="name svelte-1ess1ti">Status Point Validity</h4>
                <p className="description svelte-1ess1ti">
                  Your status are valid for 1 month
                </p>
              </div>
            </div>
          </div>
          <div className="faq svelte-bku5gp">
            <div className="title svelte-bku5gp">Terms &amp; Conditions</div>
            <div className="faq__question--active faq__question svelte-bku5gp">
              <div className="faq__title svelte-bku5gp">
                What Is Loyalty Garage And How To Participate?{" "}
                <div className="faq__arrow svelte-bku5gp">
                  <img
                    src={props.images["arrow-down.2c8144be.svg"]}
                    alt="arrow"
                  />
                </div>
              </div>
              <div className="faq__content svelte-bku5gp">
                <div className="scrollable svelte-bku5gp">
                  <p>
                    ● Loyalty Garage is a Reward Program where points are
                    accumulated through making bets.
                  </p>
                  <p>
                    ● The moment you open and verify account, you automatically
                    get keys for your Loyalty Garage. Email address and Phone
                    number verification is required to start earning Status
                    points. The more bets you place, the more Status Points you
                    earn, the luxurious your garage gets, and the bigger your
                    rewards become!
                  </p>
                  <p>
                    ● As a beginner you are entitled to own a Scooter.
                    Collecting 50 Status Points get you to drive Tesla. After
                    finally accumulating 300 Status Points you ride Lambo. But
                    The world is never big enough for you, so you aim for a
                    fancy Yacht, to get the Yacht you will need to collect 1500
                    Status Points
                  </p>
                </div>
              </div>
            </div>
            <div className="faq__question svelte-bku5gp">
              <div className="faq__title svelte-bku5gp">
                How To Accumulate Status Points?{" "}
                <div className="faq__arrow svelte-bku5gp">
                  <img
                    src={props.images["arrow-down.2c8144be.svg"]}
                    alt="arrow"
                  />
                </div>
              </div>
              <div className="faq__content svelte-bku5gp">
                <div className="scrollable svelte-bku5gp">
                  <p>
                    ● Status Points are earned through making Real Money Bets.
                    The higher the amount of the bet, the more status points you
                    get.
                  </p>
                  <p>
                    <strong>
                      100 USD Bet = 1 Status Points X Game Contribution%
                    </strong>
                  </p>
                  <p>
                    ● Different games have different contributions towards
                    earning the Status Points. As shown in the game contribution
                    list below:
                  </p>
                  <p>
                    <strong>
                      Slots – 100%. Sportsbook 10%. Live Casino 5%. Dice 5%.
                      Provably Fair Games 5%.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="faq__question svelte-bku5gp">
              <div className="faq__title svelte-bku5gp">
                What Is The Validity Period Of Status Points?{" "}
                <div className="faq__arrow svelte-bku5gp">
                  <img
                    src={props.images["arrow-down.2c8144be.svg"]}
                    alt="arrow"
                  />
                </div>
              </div>
              <div className="faq__content svelte-bku5gp">
                <div className="scrollable svelte-bku5gp">
                  <p>
                    ● In order to keep the flow of your Reward Tier in
                    accordance to your activity level, the points that you've
                    earned exactly one month ago will be automatically expired.
                    As stated by the given formula of Status Point calculation:
                  </p>
                  <p>
                    Your status points will be calculated starting from the
                    current day of your play, minus the amount of days there are
                    in current month, minus 1 day. To simplify, here is the
                    formula: Current (today) Day – (Number of days in current
                    month – 1)
                  </p>
                  <p>
                    Example 1: Today is 16th of May. There are 31 days in May.
                    16th May – (31 days -1) = 16th May -30 Days=16th April. This
                    means that in order to count Status points achieved by 16th
                    of May, we must consider period between 16th April – 16th
                    May.
                  </p>
                  <p>
                    Example 2: If today is 7th September the Formula will be:
                    7th September – (30 Days of September -1 Day) = 7th
                    September – 29 Days = 9th of August.
                  </p>
                  <p>
                    *There is an exception in this Formula when Count back
                    spreads on not just previous month, but a month before that
                    too. Example: March 2 – (31 Days – 1) = 31st January.
                    According to this exception the period of play will be
                    starting on 1st of February. The rule is to not span the
                    period of play across more than 2 months.
                  </p>
                  <p>
                    ● The Status Points that you’ll accumulate within the
                    validity period will count towards the improvement of Your
                    Loyalty Garage Ride.
                  </p>
                </div>
              </div>
            </div>
            <div className="faq__question svelte-bku5gp">
              <div className="faq__title svelte-bku5gp">
                What Are The Loyalty Garage Rewards?{" "}
                <div className="faq__arrow svelte-bku5gp">
                  <img
                    src={props.images["arrow-down.2c8144be.svg"]}
                    alt="arrow"
                  />
                </div>
              </div>
              <div className="faq__content svelte-bku5gp">
                <div className="scrollable svelte-bku5gp">
                  <h5>
                    <strong>MONTHLY CASHBACK</strong>
                  </h5>
                  <br />
                  <p>
                    ● Monthly Cashback is automatically issued to your wallet at
                    the beginning of every month from lost deposits accrued the
                    month prior. That’s a dependable, instant addition to your
                    bankroll, every month! Best of all, no wagering requirements
                    apply, and the more luxurious your garage is, the bigger the
                    cashback kickback!
                  </p>
                  <p>
                    ● The exact Cashback percentage is dependent on Loyalty
                    Garage Status (as shown in the list below).
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scooter – 3% Cashback
                  </p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tesla – 5% Cashback</p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lambo - 7% Cashback</p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yacht – 10% Cashback
                  </p>
                  <p>
                    ● Your Deposit over the course of the month should be more
                    than 0 to qualify for the Monthly Cashback.
                  </p>
                  <p>
                    ● 80% of the qualifying deposit must be lost in order to
                    receive the Cashback Bonus.
                  </p>
                  <p>
                    ● Cashback is automatically issued to player's account in
                    the beginning of every Month.
                  </p>
                  <p>
                    ● Cashback is valid for a period of 10 days. Any unused or
                    unredeemed Cashback will be removed after 10 days.{" "}
                  </p>
                  <p>
                    ● Cashback is credited directly to your wallet and DO NOT
                    have wagering requirements.
                  </p>
                  <p>
                    ● You must have no pending withdrawals in order to claim the
                    Cashback Bonus.
                  </p>
                  <p>
                    ● Any accounts that are identified to be linked to an
                    account already claiming this promotion will not be
                    eligible, and Cashback credited to both accounts will be
                    retracted.
                  </p>
                  <p>
                    ● UP-10X may, at its sole discretion, change the terms and
                    conditions, the promotion itself, or limit the eligibility
                    of players to participate in this promotion, for any reason
                    whatsoever without prior notice.
                  </p>
                  <h5>
                    <strong>WEEKLY RELOAD BONUS</strong>
                  </h5>
                  <br />
                  <p>
                    ● Once a week, we’ll match you Deposit with a percentage up
                    to a certain amount depending on what Ride you own in the
                    Loyalty Garage. Whether it’s the Scooter, Tesla, Lambo or
                    the insane 40% up to 2 BTC exclusive to Yacht owners, you’ll
                    be locked and reloaded for some serious betting
                    entertainment.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scooter – 25% UP TO 0.25
                    BTC
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tesla – 30% UP TO 0.5
                    BTC
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lambo - 35% UP TO 1 BTC
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yacht – 40% UP TO 2 BTC
                  </p>
                  <p>
                    ● Be sure to opt in for the Reload Bonus BEFORE you deposit.
                    Go to ACCOUNT, click ACTIVATE, make a deposit, and
                    experience the fortune!
                  </p>
                  <p>
                    ● The minimum deposit needed in order to qualify for the
                    promotion is 0.002 BTC.
                  </p>
                  <p>● Reload bonus can be claimed one time per week.</p>
                  <p>
                    ● The bonuses awarded will be credited to the player's bonus
                    amount balance and is subject to our standard terms and
                    conditions, bonus terms, and wagering requirements.
                  </p>
                  <p>
                    ● When wagering, funds on the main account will be used
                    prior to the use of bonus money.
                  </p>
                  <p>
                    ● Before the bonus money can be converted into money you can
                    withdraw, the bonus money must be turned over 40 times in
                    the Casino (minimum wagering requirements).
                  </p>
                  <p>
                    ● Maximum bet when a bonus is pending, or actively being
                    turned over, is 0.0014 BTC per bet. Failure to follow this
                    condition may result in forfeiting any winnings.
                  </p>
                  <p>
                    ● Only bets made using bonus money will count towards the
                    total wagering requirement. Bonus money begins when your
                    original cash deposit is depleted.
                  </p>
                  <p>
                    ● The Reload Bonus will expire after 7 days. Unless wagering
                    is complete by that time, all winnings that apply to that
                    bonus as well as the bonus itself will expire.
                  </p>
                  <p>
                    ● Any accounts that are identified to be linked to an
                    account already claiming this promotion will not be
                    eligible, and any bonuses credited to both accounts will be
                    retracted.
                  </p>
                  <p>
                    ● UP-10X may, at its sole discretion, change the terms and
                    conditions, the promotion itself, or limit the eligibility
                    of players to participate in this promotion, for any reason
                    whatsoever without prior notice to the end users.
                  </p>
                  <p>
                    ● General bonus{" "}
                    <Link
                      to="/faq/terms_and_conditions"
                      target="_blank"
                      style={{ color: "#FDCF39" }}
                    >
                      terms and conditions
                    </Link>{" "}
                    apply.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Loyalty;
